import { DefaultButton } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { languages } from '../../../common/const/resources';
import { routeUrls } from '../../../common/const/routes';
import { DialogComponent } from '../../../common/dialog/dialogComponent';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { PositionType, PositionTypeCollection, Language, OrderBy, User } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { ActivePositionTypeOrderFilter, PositionTypeFilter } from '../../../model/filters';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';

export interface IProps {
	positionTypeCollection: PositionTypeCollection;
	user: User;
	filter: PositionTypeFilter;
	language: Language;
	page: number;
	onchangeFilter: any;
	handleItemsPerPageChange: (itemsPage: number) => void;
	getPositionTypeCollection: (filter: PositionTypeFilter, pageNumber: number) => Promise<PositionTypeCollection>;
	setPositionTypeFilter: (filter: PositionTypeFilter) => PositionTypeFilter;
	deletePositionType: (filter: PositionTypeFilter, language: Language, id: number) => Promise<boolean>;
	exportToExcel: (filter: PositionTypeFilter) => Promise<any>;
}
export interface IState {
	showModal: boolean;
	id: number;
	actualFilter: PositionTypeFilter;
	orderByDescriptionSpanish: string;
	orderByDescriptionEnglish: string;
	orderByTradeId: string;
}
class PositionTypesDataGridComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			id: 0,
			orderByDescriptionSpanish: 'ms-Icon--ChevronUpSmall',
			orderByDescriptionEnglish: 'ms-Icon--ChevronUpSmall',
			orderByTradeId: 'ms-Icon--ChevronUpSmall',
			actualFilter: this.props.filter,
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			this.setState({ actualFilter: this.props.filter });
		}
	}

	public render() {
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		return (
			<>
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.positionTypeCollection.totalNumberOfRows > 1 ? (
								<span>
									{this.props.positionTypeCollection.totalNumberOfRows} {language['generic']['list']['table']['results']}
								</span>
							) : this.props.positionTypeCollection.totalNumberOfRows === 1 ? (
								<span>
									{this.props.positionTypeCollection.totalNumberOfRows} {language['generic']['list']['table']['result']}
								</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={
								this.props.positionTypeCollection.totalNumberOfRows > 0 ? 'button-download' : 'button-download isDisabled'
							}
							onClick={this.handleExportClick}
						>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th className=''>
									<div className='table-header'>
										<span>{language['position-types']['table']['columns']['descriptionSpanish']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActivePositionTypeOrderFilter.PositionTypeNameSpanish ===
														this.state.actualFilter.CurrentFilter &&
														this.state.actualFilter.PositionTypeNameSpanishOrder === OrderBy.Asc
														? 'selected'
														: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionSpanish}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActivePositionTypeOrderFilter.PositionTypeNameSpanish ===
														this.state.actualFilter.CurrentFilter &&
														this.state.actualFilter.PositionTypeNameSpanishOrder === OrderBy.Desc
														? 'selected'
														: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionSpanish}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['position-types']['table']['columns']['descriptionEnglish']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActivePositionTypeOrderFilter.PositionTypeNameEnglish ===
														this.state.actualFilter.CurrentFilter &&
														this.state.actualFilter.PositionTypeNameEnglishOrder === OrderBy.Asc
														? 'selected'
														: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionEnglish}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActivePositionTypeOrderFilter.PositionTypeNameEnglish ===
														this.state.actualFilter.CurrentFilter &&
														this.state.actualFilter.PositionTypeNameEnglishOrder === OrderBy.Desc
														? 'selected'
														: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionEnglish}
											></i>
										</div>
									</div>
								</th>
								<th className=''></th>
							</tr>
						</thead>
						<tbody>
							{this.props.positionTypeCollection.list?.map((item: PositionType) => {
								return (
									<tr key={item.id.toString()}>
										<td className='mainColumn'>{item.descriptionSpanish}</td>
										<td className=''>{item.descriptionEnglish}</td>
										<td className='deleteItem'>
											<div>
												<DefaultButton
													text='. . .'
													disabled={!isAdmin}
													menuProps={{
														shouldFocusOnMount: true,
														items: [
															{
																key: 'Edit',
																onClick: () => {
																	this.props.history.push('/positionTypes/edit/' + item.id);
																},
																iconProps: {
																	iconName: 'Edit',
																	className: 'context-menu__icon',
																},
																name: language['position-types']['table']['options']['edit'],
															},
															{
																key: 'delete',
																iconProps: {
																	iconName: 'Delete',
																	className: 'context-menu__icon',
																},
																onClick: () => {
																	this.ShowModal(item.id);
																},
																name: language['position-types']['table']['options']['delete'],
															},
														],
													}}
													className='tdNoBorder'
												/>
												<DialogComponent
													showModal={this.state.showModal}
													TitleDialog={language['generic']['dialog']['title']}
													QuestionDialog={language['generic']['dialog']['question']}
													onSuccess={() => {
														this.Delete();
													}}
													onFail={() => {
														this.Fail();
													}}
													CancelTitle={language['generic']['dialog']['cancel']}
													SuccessTitle={language['generic']['dialog']['confirm']}
												/>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				{
					<AdvancedPagination
						currentPage={this.props.page}
						items={this.props.positionTypeCollection.totalNumberOfRows}
						link={routeUrls.positionTypesPagination}
						updateMaxPageItems={this.handleItemsPerPageChange}
						actualPageItems={this.state.actualFilter.ItemsPage}
						language={this.props.language}
					/>
				}
			</>
		);
	}
	public handleExportClick = () => {
		let filter: PositionTypeFilter = this.props.filter;
		this.props.exportToExcel(filter);
	};

	public readonly handleOrderByDescriptionSpanish = () => {
		let filter: PositionTypeFilter = this.props.filter;
		filter.CurrentFilter = ActivePositionTypeOrderFilter.PositionTypeNameSpanish;
		let orderBy: string = '';

		if (filter.PositionTypeNameSpanishOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.PositionTypeNameSpanishOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.PositionTypeNameSpanishOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByDescriptionSpanish: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPositionTypeCollection(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDescriptionEnglish = () => {
		let filter: PositionTypeFilter = this.props.filter;
		filter.CurrentFilter = ActivePositionTypeOrderFilter.PositionTypeNameEnglish;
		let orderBy: string = '';

		if (filter.PositionTypeNameEnglishOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.PositionTypeNameEnglishOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.PositionTypeNameEnglishOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByDescriptionEnglish: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getPositionTypeCollection(filter, 1, filter.ItemsPage);
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		let filter: PositionTypeFilter = { ...this.state.actualFilter };
		filter.ItemsPage = itemsPage
		this.setState({ actualFilter: filter });
		this.props.getPositionTypeCollection(filter, 1, itemsPage);
	};
	
	private readonly Delete = () => {
		this.props.deletePositionType(this.state.actualFilter, this.props.language, this.state.id);
		this.Fail();
	};
	private Fail() {
		this.setState({ showModal: false, id: 0 });
	}
	private ShowModal(id: number) {
		this.setState({ showModal: true, id: id });
	}
}
export const PositionTypesDataGrid = withRouter(PositionTypesDataGridComponent);
