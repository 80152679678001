import { connect } from 'react-redux';
import { StateReducer } from '../../../reducers';
import { getBodyTypeAction, saveBodyTypeAction } from './actions/index';
import { BodyTypesForm } from './page';
import { BodyTypes, Language } from '../../../model';
import { getBusinessNamesAction } from './actions/getBusinessNames';

const mapStateToProps = (state: StateReducer) => ({
	user: state.user,
	language: state.language,
	bodyType: state.bodyType,
	business: state.businessNames,
	loading: state.http?.inProgress
});

const mapDispatchToProps = (dispatch: any) => ({
	getBodyType: (id: number) => dispatch(getBodyTypeAction(id)),
	saveBodyType: (deed: BodyTypes, language: Language) => dispatch(saveBodyTypeAction(deed, language)),
	getBusiness: () => dispatch(getBusinessNamesAction()),
});

export const BodyTypesFormContainer = connect(mapStateToProps, mapDispatchToProps)(BodyTypesForm);
