import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { languages } from '../../common/const/resources';
import { onlyAuthorizationModule } from '../../common/const/rolesAssignments';
import { routeUrls } from '../../common/const/routes';
import { NoResultsComponent } from '../../common/noResults/noResults';
import { SpinnerContainer } from '../../common/spinner';
import { UnauthorizedView } from '../../common/unauthorizedView/unauthorizedView';
import { BaseName, BaseType, CompanyBase, Person, User, UserState } from '../../model';
import { AuthorizationAccountCollection } from '../../model/AuthorizationAccount';
import { ActiveLanguage } from '../../model/enums/activeLanguage';
import { AuthorizationFilter, createEmptyAuthorizationFilter } from '../../model/filters/AuthorizationFilter';
import { Language } from '../../model/Language';
import * as utils from '../../utils/extensions';
import { AuthorizationsFilterComponent } from './crud/authorizationsFilterComponent';
import { AuthorizationsAccountDataGrid } from './crud/authorizationsGridComponet';

export interface IProps {
	authorizationsCollection: AuthorizationAccountCollection;
	companies: CompanyBase[];
	business: BaseType[];
	authorizationStatusesNames: BaseType[];
	user: User;
	language: Language;
	authorizationsUsers: Person[];
	currentUser: UserState;
	getCompanies: () => Promise<BaseName[]>;
	getBusiness: () => Promise<BaseName[]>;
	getAuthorizationStatusesNames: () => Promise<BaseType[]>;
	getAuthorizations: (filter: AuthorizationFilter, pageNumber: number, itemsPage: number) => Promise<AuthorizationAccountCollection>;
	getAuthorizationsUsers: (filter: string) => Promise<Person[]>;
	exportToExcel: (filter: AuthorizationFilter) => Promise<any>;
}

export interface IState {
	isAuthorized: boolean;
	isLoading: boolean;
	collapsed: boolean;
	actualFilter: AuthorizationFilter;
	itemsPage: number;
}

export class AuthorizationsAccountPage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);

		this.state = {
			isAuthorized: false,
			isLoading: true,
			collapsed: false,
			actualFilter: createEmptyAuthorizationFilter(),
			itemsPage: 10,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlyAuthorizationModule);
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});
		this.props.getAuthorizations(this.state.actualFilter, this.props.match.params['page'], this.state.itemsPage);
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentUser !== prevProps.currentUser) {
			let isAuth = utils.isInRoles(onlyAuthorizationModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}
		if (this.props.match.params['page'] !== prevProps.match.params['page']) {
			this.props.getAuthorizations(this.state.actualFilter, this.props.match.params['page'], this.state.itemsPage);
		}
	}

	public render() {
		let page: number = Number(this.props.match.params['page']);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>{language['authorizations']['list']['title']}</h1>
						</div>
						<div className='ms-Grid'>
							<AuthorizationsFilterComponent
								filter={this.state.actualFilter}
								getAuthorizations={this.props.getAuthorizations}
								companies={this.props.companies}
								getTradeCompanies={this.props.getTradeCompanies}
								tradeCompanies={this.props.tradeCompanies}
								business={this.props.business}
								authorizationStatusesNames={this.props.authorizationStatusesNames}
								onchangeFilter={this.handleFilterChange}
								language={this.props.language}
								getAuthorizationsUsers={this.props.getAuthorizationsUsers}
								authorizationsUsers={this.props.authorizationsUsers}
								collapsed={this.state.collapsed}
								user={this.props.currentUser}
								getAuthorizationStatusesNames={this.props.getAuthorizationStatusesNames}
								getBusiness={this.props.getBusiness}
								getCompanies={this.props.getCompanies}
								itemsPage={this.state.itemsPage}
							/>
							{this.props.authorizationsCollection.list?.length > 0 ? (
								<>
									<AuthorizationsAccountDataGrid
										authorizationsCollection={this.props.authorizationsCollection}
										user={this.props.user}
										language={this.props.language}
										setAuthorizationFilter={this.props.setDeedFilter}
										filter={this.state.actualFilter}
										onchangeFilter={this.handleFilterChange}
										page={page}
										exportToExcel={this.props.exportToExcel}
										getAuthorizations={this.props.getAuthorizations}
										handleItemsPerPageChange={this.handleItemsPerPageChange}
									/>
								</>
							) : (
								<NoResultsComponent language={this.props.language} list={language['authorizations']['list']['title']} />
							)}
						</div>
					</>
				)}
			</>
		);
	}

	public readonly handleFilterChange = (newfilter: AuthorizationFilter): void => {
		this.setState({ actualFilter: newfilter });
		this.props.history.push(routeUrls.authorizationsPagination + 1);
	};

	public handleItemsPerPageChange = (itemsPage: number): void => {
		this.setState({ itemsPage: itemsPage });
	};
}
