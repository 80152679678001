import { AuthorizationApi } from '../../../api/authorizations';
import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { AuthorizationAccountCollection } from '../../../model';
import { AuthorizationFilter } from '../../../model/filters';

export const getAuthorizationsAction = (filter: AuthorizationFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
	dispatch(httpCallStartAction());

	AuthorizationApi.FilterAsync(filter, pageNumber, itemsPage).then((content) => {
		dispatch(getAuthorizationsCompleted(content));
	});
};
const getAuthorizationsCompleted = (authorizationAccountCollection: AuthorizationAccountCollection) => ({
	type: actionTypes.GET_AUTHORIZATIONS,
	payload: authorizationAccountCollection,
	meta: {
		httpEnd: true,
	},
});
