import { connect } from 'react-redux';
import { StateReducer } from '../../../reducers';
import { RepresentativeDetailPage } from './page';
import { exportToPdfAction } from './actions/exportToPdf';
import { RepresentativeDetailFilter } from '../../../model/filters/RepresentativeFilter';
import { getRepresentativePageAction } from '../detail/actions/getRepresentativePaginated';
import { getCompaniesByPersonIdAction } from './actions/getCompaniesByPersonId';

const mapStateToProps = (state: StateReducer) => ({
	representatives: state.representatives,
	representative: state.representative,
	companiesByPersonId: state.companiesByPersonId,
	representativeDetailFilter: state.representativeDetailFilter,
	representativeFilter: state.representativeFilter,
	language: state.language,
	pdfDownloadLink: state.csvDownloadLink,
});

const mapDispatchToProps = (dispatch: any) => ({
	getRepresentativesInfo: (filter: RepresentativeDetailFilter, pageNumber: number, itemsPage: number) =>
		dispatch(getRepresentativePageAction(filter, pageNumber, itemsPage)),
	GetCompaniesByPersonId: (filter: RepresentativeDetailFilter, pageNumber: number, itemsPage: number) =>
		dispatch(getCompaniesByPersonIdAction(filter, pageNumber, itemsPage)),
	exportToPdf: (filter: RepresentativeDetailFilter, totalNumberRows: number) => dispatch(exportToPdfAction(filter, totalNumberRows)),
});

export const RepresentativeDetailContainer = connect(mapStateToProps, mapDispatchToProps)(RepresentativeDetailPage);
