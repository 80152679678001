import { actionTypes } from '../../../../common/const/actionTypes';
import { PowerRevocationFilter, PowerSubject } from '../../../../model';
import { RepresentativeApi } from '../../../../api/representatives';

export const getPowerSubjectsAction = (filter: PowerRevocationFilter) => (dispatch) => {
	RepresentativeApi.GetPowersForRevocation(filter).then((result) => {
		dispatch(getPowerSubjectsCompleted(result));
	});
};

const getPowerSubjectsCompleted = (result: PowerSubject[]) => ({
	type: actionTypes.GET_POWER_SUBJECTS,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
