import * as React from 'react';
import { Link } from 'react-router-dom';
import { SpinButton } from 'office-ui-fabric-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { RecordCompanyDetailFilter } from '../../../../model/filters';
import { RecordCompanyCustom } from '../../../../model';

export interface IProps {
	totalRows: number;
	filter: RecordCompanyDetailFilter;
	getRecordCompany: (filter: RecordCompanyDetailFilter) => Promise<RecordCompanyCustom>;
}

export interface IState {
	filter: RecordCompanyDetailFilter;
}

class StockholdersPaginationComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			filter: {
				CompaniesId: this.props.filter.CompaniesId,
				PositionsPage: this.props.filter.PositionsPage,
				PositionsSizePage: this.props.filter.PositionsSizePage,
				StockholdersPage: this.props.filter.StockholdersPage,
				StockholdersSizePage: this.props.filter.StockholdersSizePage,
				HistoricalStockholdersPage: this.props.filter.HistoricalStockholdersPage,
				HistoricalStockholdersSizePage: this.props.filter.HistoricalStockholdersSizePage,
			},
		};
	}
	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			this.setState({ filter: this.props.filter });
		}
	}

	public render() {
		let pages = Math.ceil(this.props.totalRows / this.state.filter.PositionsSizePage);
		return (
			<div className='ms-Grid-row'>
				<div className='ms-Grid-col ms-sm12 ms-md8'></div>
				<div className='ms-Grid-col ms-sm12 ms-4 spin_pagination'>
					<div className='main-container__content_edit_block ms-spinButton-input'>
						<SpinButton
							defaultValue={this.state.filter.PositionsSizePage.toString()}
							label={'Elementos por página:'}
							min={10}
							max={100}
							step={1}
							value={this.state.filter.PositionsSizePage.toString()}
							onChange={this.handleMaxItemsChange}
							onIncrement={this.handleIncrementPageItems}
							onDecrement={this.handleDecrementPageItems}
							incrementButtonAriaLabel={'Increase value by 10'}
							decrementButtonAriaLabel={'Decrease value by 10'}
						/>
					</div>
					<div className='main-container__content_edit_block ms-spinButton-input second'>
						<SpinButton
							defaultValue={this.state.filter.PositionsPage.toString()}
							label={' Página:'}
							min={1}
							max={pages}
							step={1}
							value={this.state.filter.PositionsPage.toString()}
							onChange={this.handlePageChange}
							onIncrement={this.handleNextPage}
							onDecrement={this.handlePreviousPage}
							incrementButtonAriaLabel={'Increase value by 1'}
							decrementButtonAriaLabel={'Decrease value by 1'}
						/>
					</div>
					<span className='pagination_text'> de {pages}</span>
					<div className='main-container__content_pagination pagination_arrow'>
						<Link
							to={this.props.link + (this.state.filter.PositionsPage - 1)}
							className={`arrow ${this.state.filter.PositionsPage > 1 ? '' : 'isDisabled'}`}
							onClick={this.arrowPreviousPage}
						>
							<i className='ms-Icon ms-Icon--ChevronLeft' aria-hidden='true'></i>
						</Link>
					</div>
					<div className='main-container__content_pagination pagination_arrow'>
						<Link
							className={`arrow ${this.state.filter.PositionsPage < pages ? '' : 'isDisabled'}`}
							onClick={this.arrowNextPage}
						>
							<i className='ms-Icon ms-Icon--ChevronRight' aria-hidden='true'></i>
						</Link>
					</div>
				</div>
			</div>
		);
	}

	private handleMaxItemsChange = (event: any) => {
		let value = parseInt(event.target.value);
		if (value >= 10 && value <= 100) {
			let filter = this.state.filter;
			filter.PositionsSizePage = value;
			filter.PositionsPage = 1;
			this.setState({
				filter: filter,
			});

			this.props.getRecordCompany(filter);
		}
	};

	private handleIncrementPageItems = (value: any) => {
		let valueUpdate = 0;

		if (parseInt(value) >= 50) {
			valueUpdate = 100;
		} else if (parseInt(value) >= 25) {
			valueUpdate = 50;
		} else if (parseInt(value) >= 10) {
			valueUpdate = 25;
		}

		let filter = this.state.filter;
		filter.PositionsSizePage = valueUpdate;
		filter.PositionsPage = 1;
		this.setState({
			filter: filter,
		});

		this.props.getRecordCompany(filter);
	};

	private handleDecrementPageItems = (value: any) => {
		let valueUpdate = 0;

		if (parseInt(value) <= 25) {
			valueUpdate = 10;
		} else if (parseInt(value) <= 50) {
			valueUpdate = 25;
		} else if (parseInt(value) <= 100) {
			valueUpdate = 50;
		}

		let filter = this.state.filter;
		filter.PositionsSizePage = valueUpdate;
		filter.PositionsPage = 1;
		this.setState({
			filter: filter,
		});

		this.props.getRecordCompany(filter);
	};

	private handleNextPage = (value: any) => {
		let maxPages = Math.ceil(this.props.totalRows / this.state.filter.PositionsSizePage);
		let valueUpdate = parseInt(value) + 1;
		if (valueUpdate <= maxPages) {
			let filter = this.state.filter;
			filter.PositionsPage++;
			this.setState({
				filter: filter,
			});

			this.props.getRecordCompany(filter);
		}
	};

	private arrowPreviousPage = () => {
		let page = this.state.filter.PositionsPage;
		this.handlePreviousPage(page);
	};

	private arrowNextPage = () => {
		let page = this.state.filter.PositionsPage;
		this.handleNextPage(page);
	};

	private handlePreviousPage = (value: any) => {
		let valueUpdate = parseInt(value) - 1;
		if (valueUpdate >= 1) {
			let filter = this.state.filter;
			filter.PositionsPage--;
			this.setState({
				filter: filter,
			});

			this.props.getRecordCompany(filter);
		}
	};

	private handlePageChange = (event: any) => {
		let maxPages = Math.ceil(this.props.totalRows / this.state.filter.PositionsSizePage);
		let value = parseInt(event.target.value);
		if (value >= 1 && value <= maxPages) {
			let filter = this.state.filter;
			filter.PositionsPage = value;
			this.setState({
				filter: filter,
			});

			this.props.getRecordCompany(filter);
		}
	};
}

export const StockholdersPagination = withRouter(StockholdersPaginationComponent);
