import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { Department } from '../../../model';
import { GenericApi } from '../../../api/generic';
import { ApiUrls } from '../../../common/const/api';

export const getDepartmentAction = (id: number) => (dispatch) => {
    dispatch(httpCallStartAction());
    GenericApi.GetByIdAsync<Department>(ApiUrls.Department, id).then((result) => {
        dispatch(getDepartmentCompleted(result));
    });
};

const getDepartmentCompleted = (result: Department) => ({
    type: actionTypes.GET_DEPARTMENT,
    payload: result,
    meta: {
        httpEnd: true,
    }
});