import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { ITooltipProps, TooltipHost } from 'office-ui-fabric-react/lib/components/Tooltip';
import PropTypes from 'prop-types';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Constants } from '../../../common/const/constants';
import { languages } from '../../../common/const/resources';
import { onlyFinanceRolesType } from '../../../common/const/rolesAssignments';
import { routeUrls } from '../../../common/const/routes';
import { DialogComponent } from '../../../common/dialog/dialogComponent';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { BaseType, Language, Representative, RepresentativeCollection } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { ActiveRepresentativeOrderFilter, OrderBy } from '../../../model/filters';
import { RepresentativeDetailFilter, RepresentativeFilter } from '../../../model/filters/RepresentativeFilter';
import { UserState } from '../../../reducers/users';
import * as utils from '../../../utils/extensions';
import { userRoles } from '../../../utils/enums/userRolesEnum';

export interface IProps {
	representatives: RepresentativeCollection;
	language: Language;
	filter: RepresentativeFilter;
	detailFilter: RepresentativeDetailFilter;
	page: number;
	powerTypes: BaseType[];
	currentUser: UserState;
	getPowerTypes: () => Promise<BaseType[]>;
	getRepresentative: (personId: number) => RepresentativeDetailFilter;
	getRepresentatives: (filter: RepresentativeFilter, pageNumbers: number, itemsPage: number) => Promise<RepresentativeCollection>;
	setRepresentativeDetailFilter: (filter: RepresentativeDetailFilter) => RepresentativeDetailFilter;
	deleteDeed(id: number);
	exportToExcel: (filter: RepresentativeFilter) => Promise<any>;
	deleteRepresentative: (filter: RepresentativeFilter, language: Language, id: number) => Promise<boolean>;
	onchangeFilter: any;
}
export interface IState {
	collapsed: boolean;
	filter: RepresentativeFilter;
	id: number;
	showModal: boolean;
	orderByName: string;
	orderByDni: string;
	orderByCompany: string;
	orderByProtocol: string;
	orderByInscription: string;
	orderByState: string;
	orderBytype: string;
	orderByPowertype: string;
	orderBySubject: string;
	orderByDate: string;
}
class RepresentativesDataGrid extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			id: 0,
			showModal: false,
			orderByName: 'ms-Icon--SortUp',
			orderByDni: 'ms-Icon--SortUp',
			orderByCompany: 'ms-Icon--SortUp',
			orderByProtocol: 'ms-Icon--SortUp',
			orderByInscription: 'ms-Icon--SortUp',
			orderByState: 'ms-Icon--SortUp',
			orderBytype: 'ms-Icon--SortUp',
			orderByPowertype: 'ms-Icon--SortUp',
			orderBySubject: 'ms-Icon--SortUp',
			orderByDate: 'ms-Icon--SortUp',
			filter: this.props.filter,
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			this.setState({ filter: this.props.filter });
		}
	}

	public disableButtons(tradeId:number, userRoles2: number[]): boolean{

		console.log(userRoles2);

		let userAdminMaestro = this.props.currentUser.currentUser?.roles.includes(userRoles.adminMaster);
		let userAdminGlobal = this.props.currentUser.currentUser?.roles.includes(userRoles.admin);
		let userEnergia = this.props.currentUser.currentUser?.roles.includes(userRoles.ajFeim || userRoles.ajFeimH);

		if(!userAdminGlobal){
			if(userRoles2.length > 1){
				//let idBusinessUserisSameAsTrade:boolean = (userRoles2.filter((x:number)=>{x == tradeId}).length == 1);
				if(userEnergia && tradeId == 7){
					return false;
				}else if(userRoles2.indexOf(tradeId) !== -1){
					return false;
				}else{
					return true;
				}
			}else{
				if(userAdminMaestro){
					return true;
				}else if(userRoles2[0] !== tradeId){
					return true;
				}else if(userRoles2[0] == tradeId){
					return false;
				}
			}
		}else{
			return false;
		}

	}

	public render() {
		let isReadOnly = utils.isInRoles(onlyFinanceRolesType);

		const { filter } = this.state;

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let userRoleArray = this.props.currentUser.currentUser?.roles[0];

		let userBusinessId = utils.getBusinessNewPowerByRole(userRoleArray);

		let rolesUsuario: number[] = [];
		this.props.currentUser.currentUser?.roles.forEach((rol)=>{
			let rolId = utils.getBusinessNewPowerByRole(rol);
				rolesUsuario.push(rolId);
		})



		return (
			<>
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.representatives.totalNumberOfRows > 1 ? (
								<span>
									{this.props.representatives.totalNumberOfRows} {language['generic']['list']['table']['results']}
								</span>
							) : this.props.representatives.totalNumberOfRows === 1 ? (
								<span>
									{this.props.representatives.totalNumberOfRows} {language['generic']['list']['table']['result']}
								</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					{/* <div className='ms-Grid-col ms-xl6'>{notFoundMessages}</div> */}
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={this.props.representatives.totalNumberOfRows > 0 ? 'button-download' : 'button-download isDisabled'}
							onClick={this.handleExportClick}
						>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr className='first_row_page'>
								<th className=''>
									<div className='table-header'>
										<span>{language['representatives']['list']['table']['columns']['representative']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveRepresentativeOrderFilter.Name === filter.CurrentFilter &&
													filter.NameOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByName}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveRepresentativeOrderFilter.Name === filter.CurrentFilter &&
													filter.NameOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByName}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['representatives']['list']['table']['columns']['society']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveRepresentativeOrderFilter.CompanyId === filter.CurrentFilter &&
													filter.CompanyOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByCompany}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveRepresentativeOrderFilter.CompanyId === filter.CurrentFilter &&
													filter.CompanyOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByCompany}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['representatives']['list']['table']['columns']['powertypes']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveRepresentativeOrderFilter.PowerType === filter.CurrentFilter &&
													filter.PowerTypeOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByPowerType}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveRepresentativeOrderFilter.PowerType === filter.CurrentFilter &&
													filter.PowerTypeOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByPowerType}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['representatives']['list']['table']['columns']['subject']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveRepresentativeOrderFilter.Subject === filter.CurrentFilter &&
													filter.Subject === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderBySubject}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveRepresentativeOrderFilter.Subject === filter.CurrentFilter &&
													filter.Subject === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderBySubject}
											></i>
										</div>
									</div>
								</th>
								<th className='customRightAligned'>
									<div className='table-header'>
										<span>{language['representatives']['list']['table']['columns']['protocol']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveRepresentativeOrderFilter.Protocol === filter.CurrentFilter &&
													filter.ProtocoloOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByProtocol}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveRepresentativeOrderFilter.Protocol === filter.CurrentFilter &&
													filter.ProtocoloOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByProtocol}
											></i>
										</div>
									</div>
								</th>
								<th className='customRightAligned'>
									<div className='table-header'>
										<span>{language['representatives']['list']['table']['columns']['inscription']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveRepresentativeOrderFilter.Inscription === filter.CurrentFilter &&
													filter.InscriptionOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByInscription}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveRepresentativeOrderFilter.Inscription === filter.CurrentFilter &&
													filter.InscriptionOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByInscription}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['representatives']['list']['table']['columns']['actionType']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveRepresentativeOrderFilter.Type === filter.CurrentFilter &&
													filter.TypeOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByType}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveRepresentativeOrderFilter.Type === filter.CurrentFilter &&
													filter.TypeOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByType}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['representatives']['list']['table']['columns']['state']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveRepresentativeOrderFilter.State === filter.CurrentFilter &&
													filter.StateOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByState}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveRepresentativeOrderFilter.State === filter.CurrentFilter &&
													filter.StateOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByState}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
									<span>{language['representatives']['list']['table']['columns']['document-date']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveRepresentativeOrderFilter.Date === filter.CurrentFilter &&
													filter.DocumentDateOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDate}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveRepresentativeOrderFilter.Date === filter.CurrentFilter &&
													filter.DocumentDateOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDate}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'></div>
								</th>
								<th className=''>
									<div className='table-header'></div>
								</th>
							</tr>
						</thead>
						<tbody>
							{this.props.representatives?.list.map((item: Representative) => {

								let powerType=item.powerTypeNameSpanish;
								if (this.props.language.name.includes(ActiveLanguage.English)) {
									powerType = item.powerTypeNameEnglish;
								}

								let actionText = item.type
								if (this.props.language.name.includes(ActiveLanguage.English)) {
									actionText = (item.type === 'Revocación') ? 'Revoke': 'Grant';
								}								

								let stateText =  item.state;
								let stateColor = '';
								if (item.state && item.state === 'Vigente') {
									stateColor = 'status-green';
									stateText = (this.props.language.name.includes(ActiveLanguage.English))? language['representatives']['list']['filter']['options']['status']['current']: item.state;
								}

								if (item.state && item.state === 'Revocado') {
									stateColor = 'status-red';
									stateText = (this.props.language.name.includes(ActiveLanguage.English))? language['representatives']['list']['filter']['options']['status']['notCurrent']: item.state;
								}

								let splitTooltip = item.documentUrl?.split('/');
								let tooltipProps: ITooltipProps = {
									onRenderContent: () => (
										<>
											<i className='ms-Icon ms-Icon--QuickNote tooltipIcon' aria-hidden='true'></i>
											<span className='tooltipText'>
												{splitTooltip?.length > 0 ? splitTooltip[splitTooltip?.length - 1] : ""}
											</span>
										</>
									)
								};
								let tooltipSubjectProps: ITooltipProps = {
									onRenderContent: () => (
										<>
											{item.subject}
										</>
									),
								};
								let representativeName = item.representativeName !== null ? item.representativeName: item.legalCompanyName; 

								//let d = new Date( item.documentDate);
								//let documentDate = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear();
								//let d = moment(item.documentDate, 'YYYY-MM-DD HH:mm:ss').startOf('day').toDate();
								let documentDate = utils.formatDate(item.documentDate,this.props.language);
								
								return (
									<tr>
										<td
											className='mainColumn clickableCell'
											onClick={() => {
												this.handleRowClick(item.peopleId);
											}}
										>
											{representativeName}
										</td>
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.peopleId);
											}}
										>
											{item.companyName}
										</td>
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.peopleId);
											}}
										>
											{/* {item.powerType} */}
											{powerType}
										</td>
										{/* <td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.peopleId);
											}}
										>
											{!utils.isNullOrEmpty(item.subject) && item.subject.length >= 40 ? item.subject.substring(0,40) + '...' : item.subject}
										</td> */}
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.peopleId);
											}}
										>
											<TooltipHost tooltipProps={tooltipSubjectProps}>
												{!utils.isNullOrEmpty(item.subject) && item.subject.length >= 40 ? item.subject.substring(0, 40) + '...' : item.subject}
											</TooltipHost>
										</td>

										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.peopleId);
											}}
										>
											{utils.isNullOrEmpty(item.protocolRevokeAll) ? item.protocol : item.protocolRevokeAll}
										</td>
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.peopleId);
											}}
										>
											{item.inscription}
										</td>
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.peopleId);
											}}
										>
											{actionText}
										</td>
										<td
											className='clickableCell status-cell'
											onClick={() => {
												this.handleRowClick(item.peopleId);
											}}
										>
											{(actionText!=='Revocación' && actionText!=='Revoke') &&
												<>
													<i className={'ms-Icon ms-Icon--StatusCircleInner ' + stateColor} aria-hidden='true'></i>
													<span>{stateText}</span>
												</>
											}
										</td>
										<td
											className='clickableCell'
											onClick={() => {
												this.handleRowClick(item.peopleId);
											}}
										>
											{documentDate}
										</td>
										<td className='clickableCell removeTSpace'>
											{!utils.isNullOrEmpty(item.documentUrl) &&
												<a href={Constants.UrlSharePoint + item.documentUrl}>
													<TooltipHost tooltipProps={tooltipProps}>
														<i className='ms-Icon ms-Icon--NoteForward' aria-hidden='true'></i>
													</TooltipHost>
												</a>
											}
										</td>
										<td className='deleteItem'>
											<div>
												<DefaultButton
													text='. . .'
													// disabled={isReadOnly || (userBusinessId > 0 && userBusinessId !== item.tradeId)}
													menuProps={{
														shouldFocusOnMount: true,

														items: [
															{
																key: 'View',
																onClick: () => {
																	this.handleViewClick(item.powerId);
																},
																iconProps: {
																	iconName: 'View',
																	className: 'context-menu__icon',
																},
																name: language['deeds']['list']['table']['options']['view'],
															},
															{
																key: 'Edit',
																onClick: () => {
																	this.handleEditClick(item.powerId);
																},
																iconProps: {
																	iconName: 'Edit',
																	className: 'context-menu__icon',
																},
																name: language['deeds']['list']['table']['options']['edit'],
																disabled:
																	isReadOnly || this.disableButtons(item.tradeId,rolesUsuario),
																	//isReadOnly || (userBusinessId > 0 && userBusinessId !== item.tradeId)
															},
															{
																key: 'delete',
																iconProps: {
																	iconName: 'Delete',
																	className: 'context-menu__icon',
																},
																onClick: () => {
																	this.ShowModal(item.representativeId);
																},
																name: language['deeds']['list']['table']['options']['delete'],
																disabled:
																isReadOnly || this.disableButtons(item.tradeId,rolesUsuario),
																//isReadOnly || (userBusinessId > 0 && userBusinessId !== item.tradeId)
															},
														],
													}}
													className='tdNoBorder'
												/>
											</div>
											<DialogComponent
												showModal={this.state.showModal}
												TitleDialog={language['generic']['dialog']['title']}
												QuestionDialog={language['generic']['dialog']['question']}
												onSuccess={() => {
													this.Delete();
												}}
												onFail={() => {
													this.Fail();
												}}
												CancelTitle={language['generic']['dialog']['cancel']}
												SuccessTitle={language['generic']['dialog']['confirm']}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div>
					{
						<AdvancedPagination
							currentPage={this.props.page}
							items={this.props.representatives.totalNumberOfRows}
							link={routeUrls.representativesPagination}
							updateMaxPageItems={this.handleItemsPerPageChange}
							actualPageItems={this.state.filter.ItemsPage}
							language={this.props.language}
						/>
					}
				</div>
			</>
		);
	}

	public handleRowClick(representativeId: number) {
		let actualpage = this.props.history.location.pathname;

		this.props.setRepresentativeDetailFilter({ PersonId: representativeId });
		this.props.history.push(`/representatives/detail/` + representativeId + `/1?previousPage=${actualpage}`);
	}

	public handleEditClick(powerId: number) {
		this.props.history.push('/representatives/edit/' + powerId);
	}

	public handleViewClick(powerId: number) {
		this.props.history.push('/representatives/view/' + powerId);
	}

	private readonly Delete = () => {
		this.props.deleteRepresentative(this.state.filter, this.props.language, this.state.id);
		this.Fail();
	};
	private Fail() {
		this.setState({ showModal: false, id: 0 });
	}
	private ShowModal(id: number) {
		this.setState({ showModal: true, id: id });
	}
	public handleItemsPerPageChange = (itemsPage: number) => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		filter.ItemsPage = itemsPage;
		this.setState({ filter });
		this.props.getRepresentatives(filter, 1, itemsPage);
	};

	public handleExportClick = () => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		this.props.exportToExcel(filter);
	};

	public handleOrderByName = () => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		filter.CurrentFilter = ActiveRepresentativeOrderFilter.Name;
		let orderBy: string = '';

		if (filter.NameOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--SortUp';
			filter.NameOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.NameOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByName: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getRepresentatives(filter, 1, filter.ItemsPage);
	};

	public handleOrderByDni = () => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		filter.CurrentFilter = ActiveRepresentativeOrderFilter.Dni;
		let orderBy: string = '';

		if (filter.DniOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--SortUp';
			filter.DniOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.DniOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByDni: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getRepresentatives(filter, 1, filter.ItemsPage);
	};

	public handleOrderByCompany = () => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		filter.CurrentFilter = ActiveRepresentativeOrderFilter.CompanyId;
		let orderBy: string = '';

		if (filter.CompanyOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--SortUp';
			filter.CompanyOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.CompanyOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByCompany: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getRepresentatives(filter, 1, filter.ItemsPage);
	};

	public handleOrderByPowerType = (event) => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		filter.CurrentFilter = ActiveRepresentativeOrderFilter.PowerType;
		let orderBy: string = '';

		if (filter.PowerTypeOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--SortUp';
			filter.PowerTypeOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.PowerTypeOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByPowertype: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getRepresentatives(filter, 1, filter.ItemsPage);
	};

	public handleOrderBySubject = () => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		filter.CurrentFilter = ActiveRepresentativeOrderFilter.Subject;
		let orderBy: string = '';

		if (filter.Subject === OrderBy.Desc) {
			orderBy = 'ms-Icon--SortUp';
			filter.Subject = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.Subject = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderBySubject: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getRepresentatives(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByProtocol = () => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		filter.CurrentFilter = ActiveRepresentativeOrderFilter.Protocol;
		let orderBy: string = '';

		if (filter.ProtocoloOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--SortUp';
			filter.ProtocoloOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.ProtocoloOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByProtocol: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getRepresentatives(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByInscription = () => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		filter.CurrentFilter = ActiveRepresentativeOrderFilter.Inscription;
		let orderBy: string = '';

		if (filter.InscriptionOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--SortUp';
			filter.InscriptionOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.InscriptionOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByInscription: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getRepresentatives(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByState = () => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		filter.CurrentFilter = ActiveRepresentativeOrderFilter.State;
		let orderBy: string = '';

		if (filter.StateOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--SortUp';
			filter.StateOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.StateOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByState: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getRepresentatives(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByType = () => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		filter.CurrentFilter = ActiveRepresentativeOrderFilter.Type;
		let orderBy: string = '';

		if (filter.TypeOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--SortUp';
			filter.TypeOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.TypeOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByState: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getRepresentatives(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDate = () => {
		let filter: RepresentativeFilter = { ...this.state.filter };
		filter.CurrentFilter = ActiveRepresentativeOrderFilter.Date;
		let orderBy: string = '';

		if (filter.DocumentDateOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--SortUp';
			filter.DocumentDateOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.DocumentDateOrder = OrderBy.Desc;
		}

		this.setState({ filter: filter, orderByDate: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getRepresentatives(filter, 1, filter.ItemsPage);
	};

}

export default withRouter(RepresentativesDataGrid);
