import { IComboBoxOption } from 'office-ui-fabric-react/lib/ComboBox';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react/lib/index';
import * as React from 'react';
import { languages } from '../../../common/const/resources';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';
import { SearchPeople } from '../../../common/search/searchPeople';
import { BaseType, Language, NotaryPeople, NotaryPeopleCollection, SearchSelectModel, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { NotaryPeopleFilter, createEmptyNotaryPeopleFilter } from '../../../model/filters';

export interface IProps {
	getNotaryPeople: (filter: NotaryPeopleFilter, pageNumbers: number, itemsPage: number) => Promise<NotaryPeopleCollection>;
	filter: NotaryPeopleFilter;
	onchangeFilter: any;
	language: Language;
	business: BaseType[];
	user: UserState;
	notaryPeopleNames: NotaryPeople[];
	itemsPage: number;
	page: number;
	//getNotaryPeopleNames: (filter: string) => Promise<NotaryPeople[]>;
	getNotaryPeopleNames: (filter: string) => void;
}

export interface IState {
	filter: NotaryPeopleFilter;
	selectedNotaryPeople: any;
	collapsed: boolean;
}

export class NotaryPeopleSearchComponent extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			filter: createEmptyNotaryPeopleFilter(),
			selectedNotaryPeople: null,
		};
	}

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let users: SearchSelectModel[] = [];
		if (this.props.notaryPeopleNames && this.props.notaryPeopleNames.length > 0) {
			this.props.notaryPeopleNames.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		let business: IComboBoxOption[] = [];
		if (this.props.business) {
			business.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
			business.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			this.props.business.forEach((item) => {
				business.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
		}

		return (
			<>
				<div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
					<div className={this.state.collapsed ? 'ms-Grid-row filter_header-collapse' : 'ms-Grid-row filter_header'}>
						<div className='ms-Grid-col ms-xl6'>
							{this.state.collapsed
								? language['generic']['list']['filter']['collapsedTitle']
								: language['generic']['list']['filter']['btnTitle']}
						</div>
						<div className='ms-Grid-col ms-xl6'>
							<CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? 'none' : '' }}>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 content_edit_block_filter'>
								<SearchPeople
									title={language['notaryPeople']['list']['filter']['options']['name']['title']}
									placeholder={language['notaryPeople']['list']['filter']['options']['name']['placeholder']}
									language={this.props.language}
									selectedOption={this.state.selectedNotaryPeople}
									loadOptions={this.loadOptions}
									handleNameChange={this.handleNotaryPeopleNameChange}
									getUsers={this.props.getNotaryPeopleNames}
									onClearValue={this.onClearSearchPeople}
								/>
							</div>
						</div>
						<hr />
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
								<button type='button' className='filter_button-clean' onClick={this.cleanFilterNotaryPeople}>
									{language['generic']['list']['filter']['btnClean']}
								</button>
								<button type='button' className='filter_button-apply' onClick={this.filterNotaryPeople}>
									{language['generic']['list']['filter']['btnApply']}
								</button>
							</div>
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? '' : 'none' }}>
						<div className='filter_collapse'>
							<p style={{ display: this.state.filter.NotaryPeopleId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['notaryPeople']['list']['filter']['options']['name']['title']}</span>
								<span className='value'>{this.state.filter.Name}</span>
							</p>
							<p style={{ display: this.state.filter.Email !== '' ? '' : 'none' }}>
								<span className='key'>{language['notaryPeople']['list']['filter']['options']['email']['title']}</span>
								<span className='value'>{this.state.filter.Email}</span>
							</p>
						</div>
					</div>
				</div>
			</>
		);
	}

	componentDidMount() {
		let selectedNotaryPeople = null;
		let filter = {...this.props.filter}
		filter.NotaryPeopleId = 0;
		filter.Name = "";
		this.setState({ filter: filter, selectedNotaryPeople: selectedNotaryPeople });
	}

	private readonly handleFiltersCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	private readonly onClearSearchPeople = (): void => {
		let filter = { ...this.state.filter };
		let selectedNotaryPeople = { ...this.state.selectedNotaryPeople };
		filter.NotaryPeopleId = null;
		filter.Name = null;
		selectedNotaryPeople.value=null;
		selectedNotaryPeople.label=null;
		this.setState({ filter: filter, selectedNotaryPeople: selectedNotaryPeople });
	};

	private readonly handleNotaryPeopleNameChange = (selectedNotaryPeople) => {
		let filter = { ...this.state.filter };
		filter.NotaryPeopleId = parseInt(selectedNotaryPeople?.value);
		filter.Name = selectedNotaryPeople?.label;
		this.setState({ filter: filter, selectedNotaryPeople: selectedNotaryPeople });	
	};

	private readonly filterNotaryPeople = () => {
		let filter = { ...this.state.filter };
		this.setState({ collapsed: true });
		this.props.onchangeFilter(filter);
		this.props.getNotaryPeople(filter, 1, this.props.itemsPage);
	};

	private readonly cleanFilterNotaryPeople = () => {
		let resetFilter = createEmptyNotaryPeopleFilter();
		this.setState({ filter: resetFilter, selectedNotaryPeople: null });
		this.props.onchangeFilter(resetFilter);
		this.props.getNotaryPeople(resetFilter, 1, this.props.itemsPage);
	};

	private readonly loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filterColors(inputValue));
		}, 1000);
	};

	private readonly filterColors = (inputValue: string) => {
		let users: SearchSelectModel[] = [];
		if (inputValue.length >= 4 && this.props.notaryPeopleNames && this.props.notaryPeopleNames.length > 0) {
			this.props.notaryPeopleNames.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		return users;
	};
}