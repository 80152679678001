import { TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { SearchPeople } from '../../../../common/search/searchPeople';
import { Language, NotaryData, NotaryPeople, SearchSelectModel } from '../../../../model';
import * as utils from '../../../../utils/extensions';
import { getResourcesFile } from '../../../../utils/extensions';

interface IProps {
	notaryPeople: NotaryPeople[];
	notaryData: NotaryData;
	updateNotaryData: (notaryData: NotaryData) => void;
	getNotaryPeopleNames: (filter: string) => void;
	language: Language;
	isReadOnly: boolean;
}

export class NotaryDataTab extends React.Component<IProps, {}> {

	public render() {

		//let selectedNotary = { value: this.props.notaryData?.notaryPeople?.id, label: this.props.notaryData?.notaryPeople?.name };
		let selectedNotary = { value: this.props.notaryData?.notaryPeopleId, label: this.props.notaryData?.notaryPeopleName };

		let language = getResourcesFile(this.props.language);
		return (
			<div className='notaryForm'>
				<div className='ms-Grid-row notaryForm_inputs'>
					{/* <div className='ms-Grid-col main-container__content_edit_block'>
						<p>{language['representatives']['form']['sections']['notarial-data']['fields']['notary']['title']}</p>
						<TextField
							type='text'
							className=' content_edit_block_filter-inner'
							value={this.props.notaryData?.notary}
							onChange={this.handleNotaryChange}
							onBlur={this.handleNotaryBlur}
							disabled={this.props.isReadOnly}
							maxLength={250}
						/>
					</div> */}
					<div className='ms-Grid-col main-container__content_edit_block peoplePickerContainer'>
						<SearchPeople
							title={language['representatives']['form']['fields']['notary']['title']}
							placeholder={language['representatives']['newform']['notary']['placeholder']}
							language={this.props.language}
							selectedOption={utils.isNullOrEmpty(selectedNotary.label) ? null : selectedNotary}
							loadOptions={this.loadOptions}
							handleNameChange={this.handleNotaryPeopleChange}
							getUsers={this.props.getNotaryPeopleNames}
							disabled={this.props.isReadOnly}
							onClearValue={this.onClearSearchPeople}
							showDeleteButton={true}
						/>
					</div>

					<div className='ms-Grid-col main-container__content_edit_block'>
						<p>{language['representatives']['form']['fields']['minutes']['title']}</p>
						<TextField
							type='text'
							className=' content_edit_block_filter-inner'
							value = {utils.isNullOrEmpty(this.props.notaryData?.minutes) ? '' : this.props.notaryData?.minutes}
							onChange={this.handleActChange}
							onBlur={this.handleActBlur}
							disabled={this.props.isReadOnly}
							maxLength={255}
						/>
					</div>
					<div className='ms-Grid-col main-container__content_edit_block'>
						<p>{language['representatives']['form']['fields']['country']['title']}</p>
						<TextField
							type='text'
							className=' content_edit_block_filter-inner'
							value = {utils.isNullOrEmpty(this.props.notaryData?.city) ? '' : this.props.notaryData?.city}
							onChange={this.handleCountryChange}
							onBlur={this.handleCountryBlur}
							disabled={this.props.isReadOnly}
							maxLength={100}
						/>
					</div>
				</div>
			</div>
		);
	}

	private readonly loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filter(inputValue));
		}, 1000);
	};

	private readonly filter = (inputValue: string) => {
		let users: SearchSelectModel[] = [];
		if (inputValue.length >= 4 && this.props.notaryPeople && this.props.notaryPeople.length > 0) {
			this.props.notaryPeople.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		return users;
	};


	private readonly onClearSearchPeople = (): void => {
		if(!this.props.isReadOnly){
			let notaryData = { ...this.props.notaryData };	
			notaryData.notaryPeopleId = null;
			notaryData.notaryPeopleName = null;
			this.props.updateNotaryData(notaryData);
		}		
	};	

	private readonly handleNotaryPeopleChange = (selected): void => {
		let notaryData = { ...this.props.notaryData };	
		notaryData.notaryPeopleId = parseInt(selected?.value);
		notaryData.notaryPeopleName = selected?.label;
		this.props.updateNotaryData(notaryData);
	};
	
	private readonly handleNotaryChange = (event) => {
		let notaryData = { ...this.props.notaryData };
		notaryData.notary = event.target.value;
		this.props.updateNotaryData(notaryData);
	};
	private readonly handleNotaryBlur = (event) => {
		let notaryData = { ...this.props.notaryData };
		notaryData.notary = event.target.value.toUpperCase();
		this.props.updateNotaryData(notaryData);
	};

	private readonly handleActChange = (event) => {
		let notaryData = { ...this.props.notaryData };
		notaryData.minutes = event.target.value;
		this.props.updateNotaryData(notaryData);
	};
	private readonly handleActBlur = (event) => {
		let notaryData = { ...this.props.notaryData };
		notaryData.minutes = event.target.value.toUpperCase();
		this.props.updateNotaryData(notaryData);
	};

	private readonly handleCountryChange = (event) => {
		let notaryData = { ...this.props.notaryData };
		notaryData.city = event.target.value;
		this.props.updateNotaryData(notaryData);
	};
	private readonly handleCountryBlur = (event) => {
		let notaryData = { ...this.props.notaryData };
		notaryData.city = event.target.value.toUpperCase();
		this.props.updateNotaryData(notaryData);
	};
}
