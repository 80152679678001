import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { GenericApi } from '../../../../api/generic';
import { BaseCode } from '../../../../model/Base';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';

export const getPositionTypeNamesAction = () => (dispatch) => {
	dispatch(httpCallStartAction());
	GenericApi.GetAsync<BaseCode>(ApiUrls.PositionTypes).then((content) => {
		dispatch(getPositionTypeNamesCompleted(content));
	});
};

const getPositionTypeNamesCompleted = (positionTypes: BaseCode[]) => ({
	type: actionTypes.GET_POSITION_TYPE_NAMES,
	payload: positionTypes,
	meta: {
		httpEnd: true,
	},
});
