import { GenericApi } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import useHistory from '../../../../history';
import { BaseType, Language } from '../../../../model';
import { getResourcesFile, showError, showSuccess } from '../../../../utils/extensions';
const browserHistory = useHistory;

export const saveBodyTypeAction = (bodyType: BaseType, currentLanguage: Language) => (dispatch) => {
	let language = getResourcesFile(currentLanguage);
	dispatch(httpCallStartAction());
	if (bodyType.id > 0) {
		handleUpdateBodyType(bodyType, language, dispatch);
	} else {
		handleCreateBodyType(bodyType, language, dispatch);
	}
};

const handleUpdateBodyType = (bodyType: BaseType, language: any, dispatch) => {
	GenericApi.UpdateAsync<BaseType>(ApiUrls.BodyTypes, bodyType).then((result: any) => {
		if (!result.error) {
			showSuccess(language['body-types']['form']['messages']['success-update']);
			browserHistory.goBack();
			dispatch(saveBodyTypeCompleted(result));
		} else {
			showError(language['body-types']['form']['messages']['error-update'] + ' ' + language['generic']['error'][result.error]);
			dispatch(saveBodyTypeCompleted(result));
		}
	});
};

const handleCreateBodyType = (bodyType: BaseType, language: any, dispatch) => {
	GenericApi.AddAsync<BaseType>(ApiUrls.BodyTypes, bodyType).then((result: any) => {
		if (result > 0) {
			showSuccess(language['body-types']['form']['messages']['success']);
			browserHistory.goBack();
			dispatch(saveBodyTypeCompleted(result));
		} else {
			showError(language['body-types']['form']['messages']['error'] + ' ' + language['generic']['error'][result.error]);
			dispatch(saveBodyTypeCompleted(result));
		}
	});
};

const saveBodyTypeCompleted = (result: number) => ({
	type: actionTypes.SAVE_BODY_TYPE_COMPLETED,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
