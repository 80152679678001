import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { PositionTypeFilter } from '../../../model/filters';
import { PositionTypesApi } from '../../../api/positionTypes/positionTypesApi';
import { PositionTypeCollection } from '../../../model';

export const getPositionTypesCollectionAction = (filter: PositionTypeFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	dispatch(setPositionTypeFilterCompleted(filter));
	PositionTypesApi.FilterAsync(filter, pageNumber, itemsPage).then((content) => {
		dispatch(getPositionTypesCollectionCompleted(content));
	});
};
const getPositionTypesCollectionCompleted = (positionTypeCollection: PositionTypeCollection) => ({
	type: actionTypes.GET_POSITION_TYPES_COLLECTION,
	payload: positionTypeCollection,
	meta: {
		httpEnd: true,
	},
});

const setPositionTypeFilterCompleted = (filter: PositionTypeFilter) => ({
    type: actionTypes.SET_POSITION_TYPES_FILTER,
    payload: filter,
    meta: {
        httpEnd: true,
    },
});