import { IconButton } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import { languages } from '../../../../../common/const/resources';
import { DialogComponent } from '../../../../../common/dialog/dialogComponent';
import {
	BaseType,
	createEmptyPowerRepresentativeDetail,
	Language,
	PowerRepresentativeDetail,
	RepresentativeState,
} from '../../../../../model';
import { ActiveLanguage } from '../../../../../model/enums/activeLanguage';
import '../../../../../styles/pages/representatives.scss';
import { formatDate, getParsedDate } from '../../../../../utils/extensions';

export interface IProps {
	representatives: PowerRepresentativeDetail[];
	powerTypes: BaseType[];
	language: Language;
	powerExerciseNames: BaseType[];
	isReadOnly: boolean;
	departments: BaseType[];
	index: number;
	editRepresentative: (representative: PowerRepresentativeDetail, index: number, isEdit: boolean) => void;
	deleteRepresentative: (representative: PowerRepresentativeDetail) => void;
	cancelEditRepresentative: (isEdit: boolean) => void;
	setFinishedEdit: (finishedEdit: boolean) => void;
}

interface IState {
	representative: PowerRepresentativeDetail;
	showModal: boolean;
}
export class RepresentativesGrid extends React.Component<IProps, IState> {
	static contextTypes = {
		router: PropTypes.object,
	};
	constructor(props) {
		super(props);
		this.state = {
			representative: createEmptyPowerRepresentativeDetail(),
			showModal: false,
		};
	}

	public render() {
		let representatives = this.props.representatives ? this.props.representatives : [];
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		return (
			<tbody>
				{representatives.map((representative, index) => {
					let exercise = this.props.powerExerciseNames.find((item) => item.id === representative.powerExcerciseId);

					let exerciseValue = exercise?.descriptionSpanish;
					if (this.props.language.name.includes(ActiveLanguage.English)) {
						exerciseValue = exercise?.descriptionEnglish;
					}
					let department = this.props.departments.find((item) => item.id === representative.departmentId);

					let departmentValue = department?.descriptionSpanish;
					if (this.props.language.name.includes(ActiveLanguage.English)) {
						departmentValue = department?.descriptionEnglish;
					}
					let revokeDate = representative.revokeOn
						? formatDate(getParsedDate(representative.revokeOn), this.props.language)
						: '-';
						let representativeName =representative.personName !==''? representative.personName : representative.legalCompanyName;
					return (
						<tr>
							<td className='nameCell'>{representativeName}</td>
							<td className=''>{exerciseValue}</td>
							<td className=''>{RepresentativeState[representative.state]}</td>
							<td className='customRightAligned'> {revokeDate}</td>
							<td className='endDateCell customCenterAligned'>{departmentValue}</td>
							<td style={{ visibility: this.props.isReadOnly ? 'hidden' : 'visible' }} className='deleteCell'>
								<div style={{ display: this.props.index > -1 ? 'none' : '' }}>
									<IconButton iconProps={{ iconName: 'EditContact' }} onClick={() => this.Edit(representative, index)} />
									<IconButton iconProps={{ iconName: 'UserRemove' }} onClick={() => this.ShowModal(representative)} />
								</div>
								<div style={{ display: this.props.index !== index ? 'none' : '' }}>
									<IconButton
										iconProps={{ iconName: 'FollowUser' }}
										onClick={() => this.props.cancelEditRepresentative(false)}
									/>
								</div>
								<DialogComponent
									showModal={this.state.showModal}
									TitleDialog={language['generic']['dialog']['title']}
									QuestionDialog={language['generic']['dialog']['question']}
									onSuccess={() => {
										this.Delete();
									}}
									onFail={() => {
										this.Fail();
									}}
									CancelTitle={language['generic']['dialog']['cancel']}
									SuccessTitle={language['generic']['dialog']['confirm']}
								/>
							</td>
						</tr>
					);
				})}
			</tbody>
		);
	}

	private readonly Edit = (representative: PowerRepresentativeDetail, index: number) => {
		this.props.editRepresentative(representative, index, true);
		this.props.setFinishedEdit(false);
	};

	private readonly Delete = () => {
		this.props.deleteRepresentative(this.state.representative);
		this.Fail();
	};

	private Fail() {
		this.setState({ showModal: false, representative: createEmptyPowerRepresentativeDetail() });
	}

	private ShowModal(representative: PowerRepresentativeDetail) {
		this.setState({ showModal: true, representative: representative });
	}
}
