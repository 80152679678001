import { DefaultButton } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { languages } from '../../../common/const/resources';
import { routeUrls } from '../../../common/const/routes';
import { DialogComponent } from '../../../common/dialog/dialogComponent';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { Department, DepartmentCollection, Language, OrderBy, User } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { ActiveDepartmentOrderFilter, DepartmentFilter } from '../../../model/filters';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';

export interface IProps {
	departmentCollection: DepartmentCollection;
	user: User;
	filter: DepartmentFilter;
	language: Language;
	page: number;
	onchangeFilter: any;
	getDepartmentCollection: (filter: DepartmentFilter, pageNumber: number) => Promise<DepartmentCollection>;
	deleteDepartment: (filter: DepartmentFilter, language: Language, id: number) => Promise<boolean>;
	exportToExcel: (filter: DepartmentFilter) => Promise<any>;
}
export interface IState {
	showModal: boolean;
	id: number;
	actualFilter: DepartmentFilter;
	orderByDescriptionSpanish: string;
	orderByDescriptionEnglish: string;
	orderByTradeId: string;
}
class DepartamentsDataGridComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false, id: 0,
			orderByDescriptionSpanish: 'ms-Icon--ChevronUpSmall',
			orderByDescriptionEnglish: 'ms-Icon--ChevronUpSmall',
			orderByTradeId: 'ms-Icon--ChevronUpSmall',
			actualFilter: this.props.filter
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			this.setState({ actualFilter: this.props.filter });
		}
	}
	public render() {
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.departmentCollection.totalNumberOfRows > 1 ? (
								<span>
									{this.props.departmentCollection.totalNumberOfRows}{' '}
									{language['generic']['list']['table']['results']}
								</span>
							) : this.props.departmentCollection.totalNumberOfRows === 1 ? (
								<span>
									{this.props.departmentCollection.totalNumberOfRows}{' '}
									{language['generic']['list']['table']['result']}
								</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={
								this.props.departmentCollection.totalNumberOfRows > 0
									? 'button-download'
									: 'button-download isDisabled'
							}
							onClick={this.handleExportClick}
						>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th className=''>
									<div className='table-header'>
										<span>{language['departments']['table']['columns']['descriptionSpanish']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveDepartmentOrderFilter.DepartmentNameSpanish ===
													this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.DepartmentNameSpanishOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionSpanish}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveDepartmentOrderFilter.DepartmentNameSpanish ===
													this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.DepartmentNameSpanishOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionSpanish}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['departments']['table']['columns']['descriptionEnglish']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveDepartmentOrderFilter.DepartmentNameEnglish ===
													this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.DepartmentNameEnglishOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionEnglish}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveDepartmentOrderFilter.DepartmentNameEnglish ===
													this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.DepartmentNameEnglishOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionEnglish}
											></i>
										</div>
									</div>
								</th>
								<th className=''></th>
							</tr>
						</thead>
						<tbody>
							{this.props.departmentCollection.list?.map((item: Department) => {
								return (
									<tr key={item.id.toString()}>
										<td className=''>{item.descriptionSpanish}</td>
										<td className=''>{item.descriptionEnglish}</td>
										<td className='deleteItem'>
											<div className='dialogContainer'>
												<DefaultButton
													text='. . .'
													disabled={!isAdmin}
													menuProps={{
														shouldFocusOnMount: true,
														items: [
															{
																key: 'Edit',
																onClick: () => {
																	this.props.history.push('/departments/edit/' + item.id);
																},
																iconProps: {
																	iconName: 'Edit',
																	className: 'context-menu__icon',
																},
																name: language['departments']['table']['options']['edit']
															},
															{
																key: 'delete',
																iconProps: {
																	iconName: 'Delete',
																	className: 'context-menu__icon',
																},
																onClick: () => {
																	this.ShowModal(item.id);
																},
																name: language['departments']['table']['options']['delete'],
															},
														],
													}}
													className='tdNoBorder'
												/>
												<DialogComponent
													showModal={this.state.showModal}
													TitleDialog={language['generic']['dialog']['title']}
													QuestionDialog={language['generic']['dialog']['question']}
													onSuccess={() => {
														this.Delete();
													}}
													onFail={() => {
														this.Fail();
													}}
													CancelTitle={language['generic']['dialog']['cancel']}
													SuccessTitle={language['generic']['dialog']['confirm']}
												/>
											</div>
										</td>
									</tr>
								);
							})
							}
						</tbody>
					</table>
				</div>
				{
					<AdvancedPagination
						currentPage={this.props.page}
						items={this.props.departmentCollection.totalNumberOfRows}
						link={routeUrls.departmentsPagination}
						updateMaxPageItems={this.handleItemsPerPageChange}
						actualPageItems={this.props.filter.ItemsPage}
						language={this.props.language}
					/>
				}
			</>
		);
	}
	public handleExportClick = () => {
		let filter: DepartmentFilter = this.props.filter;
		this.props.exportToExcel(filter);
	};

	public readonly handleOrderByDescriptionSpanish = () => {
		let filter: DepartmentFilter = this.props.filter;
		filter.CurrentFilter = ActiveDepartmentOrderFilter.DepartmentNameSpanish;
		let orderBy: string = '';

		if (filter.DepartmentNameSpanishOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DepartmentNameSpanishOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.DepartmentNameSpanishOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByDescriptionSpanish: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDepartmentCollection(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDescriptionEnglish = () => {
		let filter: DepartmentFilter = this.props.filter;
		filter.CurrentFilter = ActiveDepartmentOrderFilter.DepartmentNameEnglish;
		let orderBy: string = '';

		if (filter.DepartmentNameEnglishOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DepartmentNameEnglishOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.DepartmentNameEnglishOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByDescriptionEnglish: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDepartmentCollection(filter, 1, filter.ItemsPage);
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		let filter: DepartmentFilter = { ...this.state.actualFilter };
		filter.ItemsPage = itemsPage
		this.setState({ actualFilter: filter });
		this.props.getDepartmentCollection(filter, 1, itemsPage);
	};

	private readonly Delete = () => {
		this.props.deleteDepartment(this.state.actualFilter, this.props.language, this.state.id);
		this.Fail();
	};

	private Fail() {
		this.setState({ showModal: false, id: 0 });
	}

	private ShowModal(id: number) {
		this.setState({ showModal: true, id: id });
	}
}
export const DepartamentsDataGrid = withRouter(DepartamentsDataGridComponent);
