import { PowerApi } from '../../../../api/power';
import { actionTypes } from '../../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';

export const getLastBoxAction = () => (dispatch) => {
	dispatch(httpCallStartAction());
	PowerApi.GetLastBox().then((result) => {
		dispatch(getLastBoxCompleted(result));
	});
};

const getLastBoxCompleted = (result: string) => ({
	type: actionTypes.GET_LAST_BOX,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
