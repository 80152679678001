import { actionTypes } from '../../../common/const/actionTypes';
import { DocumentTypeFilter } from '../../../model/filters';

export const setDocumentTypeFilterAction = (filter: DocumentTypeFilter) => (dispatch) => {
	dispatch(setDocumentTypeFilterActionCompleted(filter));
};
const setDocumentTypeFilterActionCompleted = (documentTypeFilter: DocumentTypeFilter) => ({
	type: actionTypes.SET_DOCUMENT_TYPE_FILTER,
	payload: documentTypeFilter,
});
