import { GenericApi } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { Constants } from '../../../../common/const/constants';
import { Language } from '../../../../model';
import { NotaryPeopleFilter } from '../../../../model/filters/NotaryPeopleFilter';
import { getResourcesFile, showError, showSuccess } from '../../../../utils/extensions';
import { getNotaryPeopleAction } from '../../list/actions/getNotaryPeople';

export const deleteNotaryPeopleAction = (filter: NotaryPeopleFilter, currentLanguage: Language, id: number) => (dispatch) => {
    let language = getResourcesFile(currentLanguage);
    GenericApi.DeleteAsync(ApiUrls.NotaryPeople, id).then((result: any) => {
        if (!result.error) {
            showSuccess(language['notaryPeople']['form']['messages']['success-delete']);
        } else {
            showError(language['notaryPeople']['form']['messages']['error-delete'] + language['generic']['error']['NotaryPeopleAssigned']);
        }

        dispatch(getNotaryPeopleAction(filter, 1, Constants.ItemsPerPage));
        dispatch(deleteNotaryPeopleCompleted(result));
    });

};

const deleteNotaryPeopleCompleted = (result: boolean) => ({
    type: actionTypes.DELETE_PERSON,
    payload: result
});