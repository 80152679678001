import { OrderBy } from './OrderBy';
import { ActiveDocumentTypeOrderFilter } from './ActiveDocumentTypeOrderFilter';

export interface DocumentTypeFilter {
	DescriptionEnglishOrder: OrderBy;
	DescriptionSpanishOrder: OrderBy;
	TypeOrder: OrderBy;
	DescriptionEnglish: string;
	DescriptionSpanish: string;
	FatherId?: number;
	FatherName?: string;

	CurrentFilter: ActiveDocumentTypeOrderFilter;
	ItemsPage: number;
}

export const createEmptyDocumentTypeFilter = (): DocumentTypeFilter => {
	return {
		CurrentFilter: ActiveDocumentTypeOrderFilter.DescriptionSpanish,
		DescriptionSpanishOrder: OrderBy.Asc,
		DescriptionEnglishOrder: OrderBy.Asc,
		TypeOrder: OrderBy.Asc,
		DescriptionEnglish: '',
		DescriptionSpanish: '',
		FatherId: null,
		ItemsPage: 10,
	};
};
