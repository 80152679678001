import { OrderBy } from './OrderBy';
import { ActivePositionTypeOrderFilter } from './ActivePositionTypeOrderFilter';

export interface PositionTypeFilter {
	PositionTypeNameEnglishOrder: OrderBy;
	PositionTypeNameSpanishOrder: OrderBy;
	PositionTypeNameEnglish: string;
	PositionTypeNameSpanish: string;

	CurrentFilter: ActivePositionTypeOrderFilter;

	ItemsPage: number;
}

export const createEmptyPositionTypeFilter = (): PositionTypeFilter => {
	return {
		CurrentFilter: ActivePositionTypeOrderFilter.PositionTypeNameSpanish,
		PositionTypeNameEnglishOrder: OrderBy.Asc,
		PositionTypeNameSpanishOrder: OrderBy.Asc,
		PositionTypeNameEnglish: '',
		PositionTypeNameSpanish: '',
		ItemsPage: 10,
	};
};
