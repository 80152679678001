import { connect } from 'react-redux';
import { StateReducer } from '../../reducers';
import { MasterManagementPage } from './page';

const mapStateToProps = (state: StateReducer) => ({
	user: state.user,
	language: state.language,
});

const mapDispatchToProps = (dispatch: any) => ({});

export const MasterManagementPageContainer = connect(mapStateToProps, mapDispatchToProps)(MasterManagementPage);
