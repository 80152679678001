import * as fetch from 'isomorphic-fetch';
import { SiteProps } from '../../model/Config';
import { ApiUrls } from '../../common/const/api';
import * as utils from '../../utils/extensions';
import { DocumentationFilter } from '../../model/filters/DocumentationFilter';
import * as msal from '../../common/security/msal';
import { DocumentDetail, DocumentsCollection } from '../../model/Documentation';
import { ActiveDocumentationOrderFilter } from '../../model/filters';
import { DecideSortingOrder } from '../../utils/extensions';
import moment from 'moment';
import { Modules } from '../../model/enums';

const moduleId = Modules.Documentation;

async function FilterAsync(filter: DocumentationFilter, pageNumber: number, itemsPage: number): Promise<DocumentsCollection> {
	let baseUrl = `${SiteProps.SiteURL}/api/${ApiUrls.Documentation}/Get`;
	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}&moduleId=${moduleId}`;
	let queryString = GetQueryString(filter);
	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response: any) => response.json());
}

async function DeleteAsync(baseUrl: string, id: number): Promise<boolean> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Documentation}/Delete`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}/${id}`;
	return fetch(url, obj).then((response: any) => response.json());
}

async function ExportToExcel(filter: DocumentationFilter): Promise<any> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Documentation}/ExportToExcel`;
	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let randomGuid: string = utils.generateRandomGuid(false);
	let url = `${baseURL}?`;
	let queryString = GetFilterQueryString(filter);

	return fetch(url + queryString + randomGuid, obj).then((response: any) =>
		utils.downloadBlob(response, utils.getExcelNames('documentation')),
	);
}

function GetQueryString(filter: DocumentationFilter): string {
	return GetOrderByQueryString(filter) + GetFilterQueryString(filter);
}

function GetOrderByQueryString(filter: DocumentationFilter): string {
	let queryString: string = '';

	switch (filter.CurrentFilter) {
		case ActiveDocumentationOrderFilter.Company:
			queryString += '&CompanyOrder=' + DecideSortingOrder(filter.CompanyOrder);
			break;
		case ActiveDocumentationOrderFilter.DocTypeSpanish:
			queryString += '&DocTypeSpanishOrder=' + DecideSortingOrder(filter.DocTypeOrder);
			break;
		case ActiveDocumentationOrderFilter.DocTypeEnglish:
			queryString += '&DocTypeEnglishOrder=' + DecideSortingOrder(filter.DocTypeOrder);
			break;
		case ActiveDocumentationOrderFilter.DocSubtypeSpanish:
			queryString += '&DocSubtypeSpanishOrder=' + DecideSortingOrder(filter.DocSubtypeOrder);
			break;
		case ActiveDocumentationOrderFilter.DocSubtypeEnglish:
			queryString += '&DocSubtypeEnglishOrder=' + DecideSortingOrder(filter.DocSubtypeOrder);
			break;
		case ActiveDocumentationOrderFilter.Date:
			queryString += '&DateOrder=' + DecideSortingOrder(filter.DateOrder);
			break;
		case ActiveDocumentationOrderFilter.Copy:
			queryString += '&CopyOrder=' + DecideSortingOrder(filter.CopyOrder);
			break;
		case ActiveDocumentationOrderFilter.CountrySpanish:
			queryString += '&CountrySpanishOrder=' + DecideSortingOrder(filter.CountryOrder);
			break;
		case ActiveDocumentationOrderFilter.CountryEnglish:
			queryString += '&CountryEnglishOrder=' + DecideSortingOrder(filter.CountryOrder);
			break;
		case ActiveDocumentationOrderFilter.Project:
			queryString += '&ProjectOrder=' + DecideSortingOrder(filter.ProjectOrder);
			break;
		case ActiveDocumentationOrderFilter.Remark:
			queryString += '&RemarkOrder=' + DecideSortingOrder(filter.RemarkOrder);
			break;
		case ActiveDocumentationOrderFilter.Comments:
			queryString += '&CommentsOrder=' + DecideSortingOrder(filter.CommentsOrder);
			break;
		default:
			queryString += '&CompanyOrder=' + DecideSortingOrder(filter.CompanyOrder);
			break;
	}

	return queryString;
}

function GetFilterQueryString(filter: DocumentationFilter): string {
	let queryStringParts = '';

	if (filter.CompaniesId.length > 0) {
		queryStringParts += '&CompaniesId=' + filter.CompaniesId.toString() + '';
	}

	if (filter.TradeId > 0) {
		queryStringParts += '&TradeId=' + filter.TradeId;
	}

	if (filter.DocTypeId > 0) {
		queryStringParts += '&DocTypeId=' + filter.DocTypeId;
	}

	if (filter.DocSubtypeId > 0) {
		queryStringParts += '&DocSubtypeId=' + filter.DocSubtypeId;
	}

	if (filter.OriginalId > 0) {
		queryStringParts += '&OriginalId=' + filter.OriginalId;
	}

	if (filter.CountryId > 0) {
		queryStringParts += '&CountryId=' + filter.CountryId;
	}

	if (!utils.isNullOrEmpty(filter.Copy)) {
		queryStringParts += '&Copy=' + encodeURIComponent(filter.Copy);
	}

	if (!utils.isNullOrEmpty(filter.UniqueCode)) {
		queryStringParts += '&CodigoUnico=' + encodeURIComponent(filter.UniqueCode);
	}

	if (!utils.isNullOrEmpty(filter.Location)) {
		queryStringParts += '&Location=' + encodeURIComponent(filter.Location);
	}

	if (!utils.isNullOrEmpty(filter.Project)) {
		queryStringParts += '&Project=' + encodeURIComponent(filter.Project);
	}

	if (!utils.isNullOrEmpty(filter.Remark)) {
		queryStringParts += '&Remark=' + encodeURIComponent(filter.Remark);
	}

	if (!utils.isNullOrEmpty(filter.Simple)) {
		queryStringParts += '&Simple=' + encodeURIComponent(filter.Simple);
	}

	if (filter.FromDate != null) {
		queryStringParts += '&FromDate=' + encodeURIComponent(filter.FromDate.toDateString());
	}

	if (filter.ToDate != null) {
		queryStringParts += '&ToDate=' + encodeURIComponent(filter.ToDate.toDateString());
	}

	return queryStringParts;
}

async function AddAsync<T>(baseUrl: string, item: T): Promise<number> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Documentation}/Add`;

	const token = await msal.authProvider.getIdToken();

	Date.prototype.toJSON = function () {
		return moment(this).format();
	};
	let obj = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
		body: JSON.stringify(item),
	};
	return fetch(baseURL, obj).then((response: any) => RespuestaAdd(response));
}

async function UpdateAsync<T>(baseUrl: string, item: T): Promise<number> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Documentation}/Update`;

	const token = await msal.authProvider.getIdToken();

	Date.prototype.toJSON = function () {
		return moment(this).format();
	};
	let obj = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
		body: JSON.stringify(item),
	};

	return fetch(baseURL, obj).then((response: any) => RespuestaUpdate(response));
}

function RespuestaAdd(response: any) {
	if (response.status === 404) {
		return -404;
	} else {
		return response.json();
	}
}
function RespuestaUpdate(response: any) {
	if (response.status === 404) {
		return response.status;
	} else {
		return response.json();
	}
}

async function GetByIdAsync(id: number): Promise<DocumentDetail> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.Documentation}/GetById`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}/${id}`;
	return fetch(url, obj).then((response: any) => response.json());
}

export const DocumentationApi = {
	FilterAsync,
	DeleteAsync,
	ExportToExcel,
	AddAsync,
	UpdateAsync,
	GetByIdAsync,
};
