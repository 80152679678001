import * as React from 'react';
import PropTypes from 'prop-types';
import { RepresentativeDetail, Language } from '../../../model';
import { languages } from '../../../common/const/resources';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import '../../../styles/pages/representatives.scss';
import { formatNameCapitalize } from '../../../utils/extensions';
import { RepresentativeDetailFilter } from '../../../model/filters';
import { Constants } from '../../../common/const/constants';
import { ITooltipProps, TooltipHost } from 'office-ui-fabric-react/lib/components/Tooltip';
import * as utils from '../../../utils/extensions';

export interface IProps {
	representativeInfo: RepresentativeDetail[];
	language: Language;
	filter: RepresentativeDetailFilter;
}

export class RepresentativePowersGrid extends React.Component<IProps, {}> {
	static contextTypes = {
		router: PropTypes.object,
	};

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<div className='ms-Grid-row main-container__table'>
				<table className='detailTable'>
					<thead>
						<tr className='first_row'>
							<th colSpan={2} className='simple-border-right'></th>
							<th colSpan={5} className='simple-border-right'>
								{language['representatives']['detail']['table']['columns']['faculty-state']['granted']}
							</th>
							<th colSpan={5}>{language['representatives']['detail']['table']['columns']['faculty-state']['reject']}</th>
						</tr>
						<tr className='second_row'>
							<th>{language['representatives']['detail']['table']['columns']['powers']}</th>
							<th className='simple-border-right'>{language['representatives']['detail']['table']['columns']['society']}</th>
							<th>{language['representatives']['detail']['table']['columns']['faculty-state']['columns']['notary']}</th>
							<th>{language['representatives']['detail']['table']['columns']['faculty-state']['columns']['protocol']}</th>
							<th>{language['representatives']['detail']['table']['columns']['faculty-state']['columns']['date']}</th>
							<th>{language['representatives']['detail']['table']['columns']['faculty-state']['columns']['inscription']}</th>
							<th className='simple-border-right'>
								{language['representatives']['detail']['table']['columns']['faculty-state']['columns']['doc-icon']}
							</th>
							<th>{language['representatives']['detail']['table']['columns']['faculty-state']['columns']['notary']}</th>
							<th>{language['representatives']['detail']['table']['columns']['faculty-state']['columns']['protocol']}</th>
							<th>{language['representatives']['detail']['table']['columns']['faculty-state']['columns']['date']}</th>
							<th>{language['representatives']['detail']['table']['columns']['faculty-state']['columns']['inscription']}</th>
							<th>{language['representatives']['detail']['table']['columns']['faculty-state']['columns']['doc-icon']}</th>
						</tr>
					</thead>
					<tbody>
						{this.props.representativeInfo.map((item) => {
							return this.getPowerRow(item);
						})}
					</tbody>
				</table>
			</div>
		);
	}

	private getPowerRow(entry: RepresentativeDetail) {
		let powerTypeName: string = entry.descriptionSpanish;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			powerTypeName = entry.descriptionEnglish;
		}

		let entryRevoked = entry.revokedPower;

		let splitTooltip = entryRevoked !== null ? entryRevoked.documentUrl?.split('/') : entry.documentUrl?.split('/');
		let splitTooltipGrant = entry.documentUrl?.split('/');
		let tooltipProps: ITooltipProps = {
			onRenderContent: () => (
				<>
					<i className='ms-Icon ms-Icon--QuickNote tooltipIcon' aria-hidden='true'></i>
					<span className='tooltipText'>
						{splitTooltipGrant?.length > 0 ? splitTooltipGrant[splitTooltipGrant?.length - 1] : ""}
					</span>
				</>
			),
		};

		let tooltipPropsRevoked: ITooltipProps = {
			onRenderContent: () => (
				<>
					<i className='ms-Icon ms-Icon--QuickNote tooltipIcon' aria-hidden='true'></i>
					<span className='tooltipText'>
						{splitTooltip?.length > 0 ? splitTooltip[splitTooltip?.length - 1] : ""}
					</span>
				</>
			),
		};

		return (
			<tr>
				<td>{powerTypeName}</td>
				<td>{entry.companyName}</td>
				{entry.notary ? <td> {formatNameCapitalize(entry.notary)}</td> : <td className='align-center'>-</td>}
				{entry.protocol ? <td className='align-right'>{entry.protocol}</td> : <td className='align-center'>-</td>}
				{utils.formatDate(entry.notaryDate, this.props.language) ? (
					<td className='align-right'>{utils.formatDate(entry.notaryDate, this.props.language)} </td>
				) : (
					<td className='align-center'>-</td>
				)}
				{entry.inscription ? <td className='align-right'>{entry.inscription}</td> : <td className='align-center'>-</td>}
				<td className='align-right'>
					{!utils.isNullOrEmpty(entry.documentUrl) &&
						<a href={Constants.UrlSharePoint + entry.documentUrl}>
							<TooltipHost tooltipProps={tooltipProps}>
								<i className='ms-Icon ms-Icon--NoteForward' aria-hidden='true'></i>
							</TooltipHost>
						</a>
					}
				</td>
				{entryRevoked !== null ? (
					<>
						{entryRevoked.notary ? <td> {formatNameCapitalize(entryRevoked.notary)}</td> : <td className='align-center'>-</td>}
						{entryRevoked.protocol ? (
							<td className='align-right'>{entryRevoked.protocol}</td>
						) : (
							<td className='align-center'>-</td>
						)}
						{utils.formatDate(entryRevoked.revoked, this.props.language) ? (
							<td className='align-right'>{utils.formatDate(entryRevoked.revoked, this.props.language)} </td>
						) : (
							<td className='align-center'>-</td>
						)}
						{entryRevoked.inscription ? (
							<td className='align-right'>{entryRevoked.inscription}</td>
						) : (
							<td className='align-center'>-</td>
						)}
						<td className='align-right'>
							{!utils.isNullOrEmpty(entryRevoked.documentUrl) &&
								<a href={Constants.UrlSharePoint + entryRevoked.documentUrl}>
									<TooltipHost tooltipProps={tooltipPropsRevoked}>
										<i className='ms-Icon ms-Icon--NoteForward' aria-hidden='true'></i>
									</TooltipHost>
								</a>
							}
						</td>
					</>
				) : (
					<>
						<td className='align-center'>-</td>
						<td className='align-center'>-</td>
						<td className='align-center'>-</td>
						<td className='align-center'>-</td>
						<td className='align-center'></td>
					</>
				)}
			</tr>
		);
	}
}
