import { connect } from 'react-redux';
import { StateReducer } from '../../../reducers';
import { PositionsListPage } from './page';
import { getPositionTypeNamesAction } from './actions/getPositionTypeNames';
import { PositionFilter, PositionDetailFilter } from '../../../model/filters/';
import { getPositionsAction } from './actions/getPositions';
import { setPositionDetailFilterAction } from './actions/setPositionDetailFilter';
import { getCompaniesNamesAction } from './actions/getCompaniesNamesAction';
import { setPositionFilterAction } from './actions/setPositionFilter';
import { getPeopleNamesAction } from './actions/getPeopleNames';
import { exportToExcelAction } from './actions/exportToExcel';
import { Modules } from '../../../model/enums';

const moduleId = Modules.Positions;

const mapStateToProps = (state: StateReducer) => ({
	peopleCollection: state.peopleCollection,
	companies: state.companyNames,
	positionTypes: state.positionTypeNames,
	language: state.language,
	positions: state.positions,
	positionCollection: state.positions,
	peopleNames: state.peopleNames,
	positionTypeNames: state.positionTypeNames,
	user: state.user,
	currentUser: state.users,
	filter: state.positionFilter,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCompanyNames: () => dispatch(getCompaniesNamesAction(moduleId)),
	getPositionTypes: () => dispatch(getPositionTypeNamesAction()),
	setPositionDetailFilter: (filter: PositionDetailFilter) => dispatch(setPositionDetailFilterAction(filter)),
	setPositionFilter: (filter: PositionFilter) => dispatch(setPositionFilterAction(filter)),
	getPositions: (filter: PositionFilter, pageNumber: number, itemsPage: number) =>
		dispatch(getPositionsAction(filter, pageNumber, itemsPage)),
	getPeopleNames: (filter: string) => dispatch(getPeopleNamesAction(filter)),
	getPositionTypeNames: () => dispatch(getPositionTypeNamesAction()),
	exportToExcel: (filter: PositionFilter) => dispatch(exportToExcelAction(filter)),
});

export const PositionsPageContainer = connect(mapStateToProps, mapDispatchToProps)(PositionsListPage);
