import { GenericApi } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { Constants } from '../../../common/const/constants';
import { Language } from '../../../model';
import { DeedTypeFilter } from '../../../model/filters';
import { getResourcesFile, showError, showSuccess } from '../../../utils/extensions';
import { getDeedTypeCollectionAction } from './getDeedTypeCollection';

export const deleteDeedTypeAction = (filter: DeedTypeFilter, currentLanguage: Language, id: number) => (dispatch) => {
	let language = getResourcesFile(currentLanguage);
	GenericApi.DeleteAsync(ApiUrls.DeedTypes, id).then((result: any) => {
		if (!result.error) {
			showSuccess(language['deedType']['messages']['success-delete']);
		} else {
			showError(language['deedType']['messages']['error-delete'] + " " + language['generic']['error'][result.error]);
		}

		dispatch(getDeedTypeCollectionAction(filter, 1, Constants.ItemsPerPage));
		dispatch(deleteDeedTypeCompleted(result));
	});
};
const deleteDeedTypeCompleted = (result: boolean) => ({
	type: actionTypes.DELETE_BODY_TYPE,
	payload: result,
});
