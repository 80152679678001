import * as React from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import { Constants } from '../../../common/const/constants';
import { RepresentativeDetailCollection, RepresentativeDetail, Language, BaseNameList } from '../../../model';
import {
	createEmptyRepresentativeDetailFilter,
	createEmptyRepresentativeFilter,
	RepresentativeDetailFilter,
	RepresentativeFilter,
} from '../../../model/filters/RepresentativeFilter';
import { RepresentativePowersGrid } from './grid';
import { SpinnerContainer } from '../../../common/spinner';
import { languages } from '../../../common/const/resources';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import '../../../styles/pages/representatives.scss';
import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Icon } from 'office-ui-fabric-react';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import * as utils from '../../../utils/extensions';
import { routeUrls } from '../../../common/const/routes';

export interface IProps {
	representative: RepresentativeDetailCollection;
	representativeDetailFilter: RepresentativeDetailFilter;
	representativeFilter: RepresentativeFilter;
	language: Language;
	filter: RepresentativeDetailFilter;
	companiesByPersonId: BaseNameList;
	pdfDownloadLink: string;
	getRepresentativesInfo: (
		filter: RepresentativeDetailFilter,
		pageNumbers: number,
		itemsPage: number,
	) => Promise<RepresentativeDetailCollection>;
	GetCompaniesByPersonId: (filter: RepresentativeDetailFilter, pageNumbers: number, itemsPage: number) => Promise<BaseNameList>;
	exportToPdf: (filter: RepresentativeDetailFilter, totalNumberRows: number) => Promise<string>;
}

export interface IState {
	filter: RepresentativeDetailFilter;
	generalFilter: RepresentativeFilter;
	itemsPage: number;
	previousPage: string;
}

export class RepresentativeDetailPage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	static contextTypes = {
		router: PropTypes.object,
	};

	constructor(props) {
		super(props);

		this.goBack = this.goBack.bind(this);
		this.state = {
			filter: {
				PersonId: this.props.representativeDetailFilter.PersonId,
				CompanyId: this.props.companiesByPersonId.CompanyId,
				CompanyName: this.props.companiesByPersonId.CompanyName,
			},
			generalFilter: createEmptyRepresentativeFilter(),
			itemsPage: 10,
			previousPage: new URL(window.location.href).searchParams.get("previousPage")
		};
	}

	public componentDidMount() {

		let page = 1;
		let personId = 0;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}
		if (Number(this.props.match.params['id']) > 0) {
			personId = Number(this.props.match.params['id']);
		}
		if (
			this.props.representativeDetailFilter &&
			this.props.representativeDetailFilter.CompanyId !== 0 &&
			this.props.representativeDetailFilter.PersonId !== 0
		) {
			this.props.GetCompaniesByPersonId(this.state.filter, page, Constants.ItemsPerPage);
			this.props.getRepresentativesInfo(this.state.filter, page, Constants.ItemsPerPage);
		} else {
			let filter: RepresentativeDetailFilter = createEmptyRepresentativeDetailFilter();
			filter.PersonId = personId;

			this.props.GetCompaniesByPersonId(filter, page, Constants.ItemsPerPage);
			this.props.getRepresentativesInfo(filter, page, Constants.ItemsPerPage);
			this.setState({ filter: filter });
		}
	}

	public componentDidUpdate(prevProps) {
		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];

		if (newPage !== oldPage) {
			this.props.getRepresentativesInfo(this.state.filter, newPage, Constants.ItemsPerPage);
		}
	}

	public render() {
		let page: number = Number(this.props.match.params['page']);
		let maxPageITems = page * this.state.itemsPage;
		let minPageItems = page === 1 ? 0 : page * this.state.itemsPage - this.state.itemsPage;
		let items: RepresentativeDetail[] = [];
		let firstRepresentativeEntry: RepresentativeDetail = null;
		if (this.props.representative && this.props.representative.list?.length > 0) {
			firstRepresentativeEntry = this.props.representative.list[0];
			items = this.props.representative.list.slice(minPageItems, maxPageITems);
		}
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let personName: string = '';
		let personDni: string = '';
		let personNationality: string = '';
		let personalIdentificationLabel: string = '';
		if (firstRepresentativeEntry != null) {
			personName = firstRepresentativeEntry.name.toLowerCase();
			personDni = !utils.isNullOrEmpty(firstRepresentativeEntry.dni)
				? firstRepresentativeEntry.dni
				: firstRepresentativeEntry.passport;
			personNationality = firstRepresentativeEntry.nationality;
			personalIdentificationLabel = !utils.isNullOrEmpty(firstRepresentativeEntry.dni)
				? language['representatives']['detail']['fields']['personal-data']['dni']
				: language['representatives']['detail']['fields']['personal-data']['passport'];
		} else {
			personName = '-';
			personDni = '-';
			personNationality = '-';
		}

		let companies: IComboBoxOption[] = [];
		companies.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		companies.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		this.props.companiesByPersonId.list.forEach((item) => {
			companies.push({ key: item.id.toString(), text: item.name.toString() });
		});

		return (
			<>
				<SpinnerContainer message='Cargando...' />
				<div className='main-container__pageTitle detail_title'>
					<span onClick={this.goBack} className='back_arrow'>
						<Icon iconName='ChromeBack' />
					</span>
					<h1>{language['representatives']['detail']['title']}</h1>

					<DefaultButton className='button-download' onClick={this.handleExportClick}>
						<i className='ms-Icon ms-Icon--PDF'></i>
						<span>{language['representatives']['detail']['export-button']}</span>
					</DefaultButton>
				</div>
				<div id='divToPrint'>
					<div id='ms-Grid-row' className='ms-Grid-row representative_info_row' dir='ltr'>
						<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 main-container__content_edit_block col_border'>
							<div className='detail_representative_name'>
								<p>{personName}</p>
							</div>
							<div className='personal_data'>
								<p>
									{personalIdentificationLabel}
									<b>{personDni}</b>
								</p>
								<p></p>
								<p>
									{language['representatives']['detail']['fields']['personal-data']['nacionality']}
									<b>{personNationality}</b>
								</p>
							</div>
						</div>
						<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 main-container__content_edit_block society_combo'>
							<div className='combo_element'>
								<p>{language['representatives']['list']['filter']['options']['society']['title']}</p>{' '}
								<ComboBox
									options={companies.sort((a, b) => a.text.localeCompare(b.text))}
									placeholder={language['representatives']['list']['filter']['options']['society']['placeholder']}
									onChange={this.handleCompanyChange}
									selectedKey={companies.length === 3 ? companies[2]?.key : this.state.filter.CompanyId}
									disabled={companies.length > 3 ? false : true}
									useComboBoxAsMenuWidth
								/>
							</div>
						</div>{' '}
					</div>
					<div className='ms-Grid-row grid_standard_width'>
						<div className='ms-Grid-col ms-sm12 main-container__content_edit_block grid_title_count'>
							<p>
								{this.props.representative?.totalNumberOfRows > 1 ? (
									<span>
										{this.props.representative?.totalNumberOfRows}{' '}
										{language['representatives']['detail']['table']['results']}
									</span>
								) : this.props.representative?.totalNumberOfRows === 1 ? (
									<span>
										{this.props.representative?.totalNumberOfRows}{' '}
										{language['representatives']['detail']['table']['result']}
									</span>
								) : (
									<span>{language['representatives']['detail']['table']['noResults']}</span>
								)}
							</p>
						</div>
						{this.props.representative?.totalNumberOfRows > 0 ? (
							<RepresentativePowersGrid
								representativeInfo={items}
								language={this.props.language}
								filter={this.state.filter}
							/>
						) : (
							<span className='hidden_table_message'>{language['representatives']['detail']['table']['noData']}</span>
						)}
					</div>
					<div className='ms-Grid-row'>
						<div className='ms-Grid-col ms-sm12 ms-md8'></div>
						<div className='ms-Grid-col ms-sm12 ms-4'>
							{this.props.representative?.totalNumberOfRows > 0 ? (
								<AdvancedPagination
									currentPage={page}
									items={this.props.representative?.totalNumberOfRows}
									link={`/representatives/detail/${this.state.filter.PersonId}/`}
									updateMaxPageItems={this.handleItemsPerPageChange}
									language={this.props.language}
								/>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</>
		);
	}

	public goBack() {
		this.props.history.push(this.state.previousPage);
	}

	public handleExportClick = () => {
		let filter: RepresentativeDetailFilter = { ...this.state.filter };
		this.props.exportToPdf(filter, this.props.representative?.totalNumberOfRows);
	};

	private readonly handleCompanyChange = (event, option): void => {
		let filter = { ...this.state.filter };
		filter.CompanyId = option?.key;
		this.setState({ filter: filter });
		this.filterRepresentatives(filter.CompanyId);

		this.props.history.push(routeUrls.representativesDetailPagination + `${this.state.filter.PersonId}/` + 1);
		
	};

	private readonly filterRepresentatives = (companyId: number) => {
		let filter = { ...this.props.filter };
		filter.PersonId = this.state.filter.PersonId;
		filter.CompanyId = companyId;
		filter.CompanyName = this.state.filter.CompanyName;
			
		this.props.getRepresentativesInfo(filter, 1, this.state.itemsPage);
		this.setState({ filter: filter });
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		this.setState({ itemsPage: itemsPage });
		this.props.getRepresentativesInfo(this.state.filter, 1, itemsPage);
	};
}
