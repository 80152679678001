import { actionTypes } from '../../../../common/const/actionTypes';
//import { ApiUrls } from '../../../../common/const/api';
//import { GenericApi } from '../../../../api/generic';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { BaseType, Power, Trade, PowersRevokeRecordCompany } from '../../../../model';
import { getResourcesFile, showError, showSuccess } from '../../../../utils/extensions';
import { lang } from 'moment';
import useHistory from '../../../../history';
import { RecordCompaniesApi } from '../../../../api/recordCompanies';
import { Language } from './../../../../model/Language';

const browserHistory = useHistory;


export const savePowersRevokeAction = (companyId: number, revokeMsg: string, protocolNum: string, currentLanguage: Language) => (dispatch) => {
	dispatch(httpCallStartAction);
	let language=getResourcesFile(currentLanguage);
	handleUpdatePowersRevoke(companyId,revokeMsg,protocolNum,language,dispatch);
};

const handleUpdatePowersRevoke = (companyId: number, revokeMsg: string, protocolNum: string, language: any, dispatch) => {
	RecordCompaniesApi.UpdatePowersRevokeAsync(companyId,revokeMsg,protocolNum).then((result) => {
		if (result) {
			showSuccess(language['corporate']['list']['messages']['success-update']);
			dispatch(savePowersRevokeCompleted(result));
		} else {
			showError(language['corporate']['list']['messages']['error-update']);
		}
	});
}

const savePowersRevokeCompleted  = (result: boolean) => ({
	type: actionTypes.GET_RECORD_COMPANY_POWERS_REVOKE,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
