import { actionTypes } from '../../../../common/const/actionTypes';
import { PositionDetailFilter } from '../../../../model/filters/';

export const setPositionDetailFilterAction = (filter: PositionDetailFilter) => (dispatch) => {
    dispatch(setPositionFilterActionCompleted(filter));

};
const setPositionFilterActionCompleted = (positionFilter
    : PositionDetailFilter) => ({
        type: actionTypes.SET_POSITION_DETAIL_FILTER,
        payload: positionFilter
    });
