import { actionTypes } from '../common/const/actionTypes';
import { createEmptyDepartment, createEmptyDepartmentCollection, Department, DepartmentCollection } from '../model/department';
import { createEmptyDepartmentFilter, DepartmentFilter } from '../model/filters/DepartmentFilter';

export const departmentFilterReducer = (state = createEmptyDepartmentFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_DEPARTMENTS_FILTER:
			return handleSetDepartmentFilterCompleted(state, action.payload);
	}

	return state;
};

export const departmentCollectionReducer = (state = createEmptyDepartmentCollection(), action) => {
	switch (action.type) {
		case actionTypes.GET_DEPARTMENT_COLLECTION:
			return handleGetDepartmentCollectionCompleted(state, action.payload);
	}

	return state;
};

export const departmentReducer = (state = createEmptyDepartment(), action) => {
	switch (action.type) {
		case actionTypes.GET_DEPARTMENT:
			return handleGetDepartmentCompleted(state, action.payload);
	}

	return state;
};

export const departmentCrudReducer = (state: number = 0, action) => {
	switch (action.type) {
		case actionTypes.SAVE_DEPARTMENT_COMPLETED:
			return handleSaveDepartmentCompleted(state, action.payload);
		case actionTypes.DELETE_DEPARTMENT:
			return handleDeleteDepartmentCompleted(state, action.payload);
	}

	return state;
};

const handleGetDepartmentCollectionCompleted = (state: DepartmentCollection, payload: DepartmentCollection) => {
	return payload;
};

const handleGetDepartmentCompleted = (state: Department, payload: Department) => {
	return payload;
};

const handleDeleteDepartmentCompleted = (state: number, payload: boolean) => {
	return payload;
};

const handleSaveDepartmentCompleted = (state: number, payload: number) => {
	return payload;
};
const handleSetDepartmentFilterCompleted = (state: DepartmentFilter, payload: DepartmentFilter) => {
	return payload;
};
