import { connect } from 'react-redux';
import { StateReducer } from '../../reducers';
import { Home } from './home';
import { cleanFilterAction } from './actions/cleanFilterAction';

const mapStateToProps = (state: StateReducer) => ({
	language: state.language,
});

const mapDispatchToProps = (dispatch: any) => ({
	cleanFilter: () => dispatch(cleanFilterAction()),
});
export const HomePageContainer = connect(mapStateToProps, mapDispatchToProps)(Home);
