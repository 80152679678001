import { OrderBy } from './OrderBy';
import { ActiveDepartmentOrderFilter } from './ActiveDepartmentOrderFilter';

export interface DepartmentFilter {
	DepartmentNameEnglishOrder: OrderBy;
	DepartmentNameSpanishOrder: OrderBy;
	DepartmentNameEnglish: string;
	DepartmentNameSpanish: string;

	CurrentFilter: ActiveDepartmentOrderFilter;
	ItemsPage: number;
}
export const createEmptyDepartmentFilter = (): DepartmentFilter => {
	return {
		CurrentFilter: ActiveDepartmentOrderFilter.DepartmentNameSpanish,
		DepartmentNameEnglishOrder: OrderBy.Asc,
		DepartmentNameSpanishOrder: OrderBy.Asc,
		DepartmentNameEnglish: '',
		DepartmentNameSpanish: '',
		ItemsPage: 10,
	};
};
