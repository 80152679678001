export const routeUrls = {
	home: '/',
	forbidden: '/forbidden',
	errorPage: '/error',
	library: '/library',
	archiveManagement: 'archiveManagement',

	companies: '/companies',
	companiesNew: '/companies/new',
	companiesEdit: '/companies/edit/:id',
	companiesPage: '/companies/page/:page',
	companiesPagination: '/companies/page/',

	deeds: '/deeds',
	deedsNew: '/deeds/new',
	deedsEdit: '/deeds/edit/:id',
	deedsView: '/deeds/view/:id',
	deedsPage: '/deeds/page/:page',
	deedsPagination: '/deeds/page/',

	deedTypes: '/deedTypes',
	deedTypesNew: '/deedTypes/new',
	deedTypesEdit: '/deedTypes/edit/:id',
	deedTypesPage: '/deedTypes/page/:page',
	deedTypesPagination: '/deedTypes/page/',

	records: 'records',
	recordsNew: '/records/new',
	recordsEdit: '/records/edit/:id',
	recordsPage: '/records/page/:page',
	recordsPagination: '/records/page/',

	people: 'people',
	peopleNew: '/people/new',
	peopleEdit: '/people/edit/:id',
	peoplePage: '/people/page/:page',
	peoplePagination: '/people/page/',

	notaryPeople: 'notaryPeople',
	notaryPeopleNew: '/notaryPeople/new',
	notaryPeopleEdit: '/notaryPeople/edit/:id',
	notaryPeoplePage: '/notaryPeople/page/:page',
	notaryPeoplePagination: '/notaryPeople/page/',

	positionTypes: 'positionTypes',
	positionTypesNew: '/positionTypes/new',
	positionTypesEdit: '/positionTypes/edit/:id',
	positionTypesPage: '/positionTypes/page/:page',
	positionTypesPagination: '/positionTypes/page/',

	powers: 'powers',
	powersNew: '/powers/new',
	powersEdit: '/powers/edit/:id',
	powersPage: '/powers/page/:page',
	powersPagination: '/powers/page/',

	recordTypes: 'recordTypes',
	recordTypesNew: '/recordTypes/new',
	recordTypesEdit: '/recordTypes/edit/:id',
	recordTypesPage: '/recordTypes/page/:page',
	recordTypesPagination: '/recordTypes/page/',

	powerTypes: 'powerTypes',
	powerTypesNew: '/powerTypes/new',
	powerTypesEdit: '/powerTypes/edit/:id',
	powerTypesPage: '/powerTypes/page/:page',
	powerTypesPagination: '/powerTypes/page/',

	bodyTypes: 'bodyTypes',
	bodyTypesNew: '/bodyTypes/new',
	bodyTypesEdit: '/bodyTypes/new/:id',
	bodyTypesPage: '/bodyTypes/page/:page',
	bodyTypesPagination: '/bodyTypes/page/',

	positions: 'positions',
	positionsDetail: '/positions/detail/:id/:page',
	positionsPage: '/positions/page/:page',
	positionsPagination: '/positions/page/',

	recordCompanies: 'recordCompanies',
	recordCompaniesDetail: '/recordCompanies/detail/:id',
	recordCompaniesPage: '/recordCompanies/page/:page',
	recordCompaniesPagination: '/recordCompanies/page/',

	representatives: 'representatives',
	representativesNew: '/representatives/new',
	representativesDetail: '/representatives/detail/:id/:page',
	representativesDetailPagination: '/representatives/detail/',
	representativesPage: '/representatives/page/:page',
	representativesEdit: '/representatives/edit/:id',
	representativesView: '/representatives/view/:id',
	representativesPagination: '/representatives/page/',

	authorizations: 'authorizations',
	authorizationsDetail: '/authorizations/detail/:id',
	authorizationsPage: '/authorizations/page/:page',
	authorizationsPagination: '/authorizations/page/',

	departments: 'departments',
	departmentsPage: '/departments/page/:page',
	newDepartment: '/departments/new',
	editDepartment: '/departments/edit/:id',
	deleteDepartment: '/departments/delete:id',
	departmentsPagination: '/departments/page/',

	documentTypes: 'documentTypes',
	documentTypesPage: '/documentTypes/page/:page',
	newDocumentType: '/documentTypes/new',
	editDocumentType: '/documentTypes/edit/:id',
	deleteDocumentType: '/documentTypes/delete:id',
	documentTypesPagination: '/documentTypes/page/',

	documentation: 'documentation',
	documentationNew: '/documentation/new',
	documentationEdit: '/documentation/edit/:id',
	documentationView: '/documentation/view/:id',
	documentationPage: '/documentation/page/:page',
	documentationPagination: '/documentation/page/',
};
