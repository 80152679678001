import { actionTypes } from '../common/const/actionTypes';
import { PositionTypeCollection, PositionType } from '../model/PositionType';
import { createEmptyPositionTypeFilter, PositionTypeFilter } from '../model/filters/PositionTypeFilter';

const createEmptyPositionTypeCollection = (): PositionTypeCollection => {
	return {
		list: [],
		totalNumberOfRows: 0,
	};
};

export const createEmptyPositionType = (): PositionType => {
	return {
		id: 0,
		descriptionEnglish: '',
		descriptionSpanish: '',
		tradeId: 0,
	};
};

export const positionTypeFilterReducer = (state = createEmptyPositionTypeFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_POSITION_TYPES_FILTER:
			return handleSetPositionTypeFilterCompleted(state, action.payload);
	}

	return state;
};
export const positionTypeCollectionReducer = (state = createEmptyPositionTypeCollection(), action) => {
	switch (action.type) {
		case actionTypes.GET_POSITION_TYPES_COLLECTION:
			return handleGetPositionTypeCollectionCompleted(state, action.payload);
	}

	return state;
};

export const positionTypeReducer = (state = createEmptyPositionType(), action) => {
	switch (action.type) {
		case actionTypes.GET_POSITION_TYPE:
			return handleGetPositionTypeCompleted(state, action.payload);
	}

	return state;
};

export const positionTypeCrudReducer = (state: number = 0, action) => {
	switch (action.type) {
		case actionTypes.SAVE_POSITION_TYPE_COMPLETED:
			return handleSavePositionTypeCompleted(state, action.payload);
		case actionTypes.DELETE_POSITION_TYPE:
			return handleDeletePositionTypeCompleted(state, action.payload);
	}

	return state;
};

const handleGetPositionTypeCollectionCompleted = (state: PositionTypeCollection, payload: PositionTypeCollection) => {
	return payload;
};

const handleGetPositionTypeCompleted = (state: PositionType, payload: PositionType) => {
	return payload;
};

const handleDeletePositionTypeCompleted = (state: number, payload: boolean) => {
	return payload;
};

const handleSavePositionTypeCompleted = (state: number, payload: number) => {
	return payload;
};
const handleSetPositionTypeFilterCompleted = (state: PositionTypeFilter, payload: PositionTypeFilter) => {
	return payload;
};
