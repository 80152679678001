import { actionTypes } from '../common/const/actionTypes';
import { createEmptyNotaryPeopleFilter, NotaryPeopleFilter } from '../model/filters';
//import { createEmptyPeople, createPerson, Person, PersonCollection } from '../model/Person';
import { createNotaryPeople, createEmptyNotaryPeople, NotaryPeople, NotaryPeopleCollection } from '../model/NotaryPeople';

export const notaryPeopleListReducer = (state = createEmptyNotaryPeople(), action) => {
	switch (action.type) {
		case actionTypes.GET_NOTARYPEOPLE:
			return handleGetNotaryPeopleListCompleted(state, action.payload);
	}

	return state;
};

export const notaryPeopleReducer = (state = createNotaryPeople(), action) => {
	switch (action.type) {
		case actionTypes.GET_NOTARYPERSON:
			return handleGetNotaryPeopleCompleted(state, action.payload);
	}

	return state;
};

export const notaryPeopleNamesReducer = (state = [createNotaryPeople()], action) => {
	switch (action.type) {
		case actionTypes.GET_NOTARYPEOPLE_NAMES:
			return handleGetNotaryPeopleNamesCompleted(state, action.payload);
	}
	return state;
};

export const notaryPeopleFormReducer = (state: number = 0, action) => {
	switch (action.type) {
		case actionTypes.SAVE_NOTARYPERSON_COMPLETED:
			return handleSaveNotaryPeopleCompleted(state, action.payload);
		case actionTypes.DELETE_NOTARYPERSON:
			return handleDeleteNotaryPeopleCompleted(state, action.payload);
	}

	return state;
};

export const personAuthorizedNamesReducer = (state = [createNotaryPeople()], action) => {
	switch (action.type) {
		case actionTypes.GET_PERSONS_AUTHORIZED:
			return handleGetNotaryPeopleNamesCompleted(state, action.payload);
	}
	return state;
};
// export const nameAzureAdReducer = (state: string = '', action) => {
// 	switch (action.type) {
// 		case actionTypes.GET_NAME_AD:
// 			return handleGetNameAzureAdCompleted(state, action.payload);
// 	}
// 	return state;
// };

export const notaryPeopleFilterReducer = (state = createEmptyNotaryPeopleFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_PERSON_FILTER:
			return handleSetNotaryPeopleFilterCompleted(state, action.payload);
	}

	return state;
};

const handleGetNotaryPeopleListCompleted = (state: NotaryPeopleCollection, payload: NotaryPeopleCollection) => {
	return payload;
};

// const handleGetNameAzureAdCompleted = (state: string, payload: string) => {
// 	return payload;
// };

const handleGetNotaryPeopleCompleted = (state: NotaryPeople, payload: NotaryPeople) => {
	return payload;
};

const handleGetNotaryPeopleNamesCompleted = (state: NotaryPeople[], payload: NotaryPeople[]) => {
	return payload;
};
const handleDeleteNotaryPeopleCompleted = (state: number, payload: boolean) => {
	return payload;
};

const handleSaveNotaryPeopleCompleted = (state: number, payload: number) => {
	return payload;
};

const handleSetNotaryPeopleFilterCompleted = (state: NotaryPeopleFilter, payload: NotaryPeopleFilter) => {
	return payload;
};
