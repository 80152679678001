export interface DocumentType {
	id: number;
	descriptionSpanish: string;
	descriptionEnglish: string;
	fatherId?: number;
}
export interface DocumentTypeCollection {
	list: DocumentType[];
	totalNumberOfRows: number;
}

export interface DocumentTypeSubType {
	id: number;
	descriptionSpanish: string;
	descriptionEnglish: string;
	listSubTypes: DocumentType[];
}

export const createEmptyDocumentType = (): DocumentType => {
	let documentType: DocumentType = {
		id: 0,
		descriptionEnglish: '',
		descriptionSpanish: '',
		fatherId: null,
	};

	return documentType;
};
export const createEmptyDocumentTypeCollection = (): DocumentTypeCollection => {
	return {
		list: [createEmptyDocumentType()],
		totalNumberOfRows: 0,
	};
};
export const createEmptyDocumentTypeSubType = (): DocumentTypeSubType => {
	let documentTypeSubType: DocumentTypeSubType = {
		id: 0,
		descriptionEnglish: '',
		descriptionSpanish: '',
		listSubTypes: [createEmptyDocumentType()],
	};

	return documentTypeSubType;
};
