import { GenericApi } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { Constants } from '../../../common/const/constants';
import { Language } from '../../../model';
import { DepartmentFilter } from '../../../model/filters';
import { getResourcesFile, showError, showSuccess } from '../../../utils/extensions';
import { getDepartmentCollectionAction } from '../actions/getDepartmentCollection';

export const deleteDepartmentAction = (filter: DepartmentFilter, currentLanguage: Language, id: number) => (dispatch) => {
	let language = getResourcesFile(currentLanguage);
	GenericApi.DeleteAsync(ApiUrls.Department, id).then((result: any) => {
		if (!result.error) {
			showSuccess(language['departments']['messages']['success-delete']);
		} else {
			showError(language['departments']['messages']['error-delete']);
		}

		dispatch(getDepartmentCollectionAction(filter, 1, Constants.ItemsPerPage));
		dispatch(deleteDepartmentCompleted(result));
	});
};
const deleteDepartmentCompleted = (result: boolean) => ({
	type: actionTypes.DELETE_DEPARTMENT,
	payload: result,
});
