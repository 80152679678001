import { actionTypes } from '../../../common/const/actionTypes';
import { PositionTypeFilter } from '../../../model/filters';

export const setPositionTypesFilterAction = (filter: PositionTypeFilter) => (dispatch) => {
	dispatch(setPositionTypesFilterActionCompleted(filter));
};
const setPositionTypesFilterActionCompleted = (positionTypesFilter: PositionTypeFilter) => ({
	type: actionTypes.SET_POSITION_TYPES_FILTER,
	payload: positionTypesFilter,
});
