import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { BaseType } from '../../../../model/BaseType';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { GenericApi } from '../../../../api/generic';

export const getPositionTypeNamesAction = () => (dispatch) => {
    dispatch(httpCallStartAction());

    GenericApi.GetAsync<BaseType>(ApiUrls.PositionTypes).then((result) => {
        dispatch(getPositionTypeNamesActionCompleted(result));
    });    
};

const getPositionTypeNamesActionCompleted = (positionTypeNames
    : BaseType[]) => ({
        type: actionTypes.GET_POSITION_TYPE_NAMES,
        payload: positionTypeNames,
        meta: {
            httpEnd: true,
        }
    });
