import { ActiveDeedTypeOrderFilter } from './ActiveDeedTypeFilter';
import { OrderBy } from './OrderBy';

export interface DeedTypeFilter {
	DescriptionEnglishOrder: OrderBy;
	DescriptionSpanishOrder: OrderBy;
	FatherDescriptionEnglishOrder: OrderBy;
	FatherDescriptionSpanishOrder: OrderBy;	
	TypeOrder: OrderBy;
	AffectsCapitalOrder: OrderBy;
	DescriptionEnglish: string;
	DescriptionSpanish: string;
	FatherId?: number;
	FatherName?: string;
	AffectsCapital: number;
	CurrentFilter: ActiveDeedTypeOrderFilter;
	ItemsPage: number;
}

export const createEmptyDeepTypeFilter = (): DeedTypeFilter => {
	return {
		CurrentFilter: ActiveDeedTypeOrderFilter.DescriptionSpanish,
		DescriptionSpanishOrder: OrderBy.Asc,
		DescriptionEnglishOrder: OrderBy.Asc,
		FatherDescriptionSpanishOrder: OrderBy.Asc,
		FatherDescriptionEnglishOrder: OrderBy.Asc,		
		TypeOrder: OrderBy.Asc,
		AffectsCapitalOrder: OrderBy.Asc,
		DescriptionEnglish: '',
		DescriptionSpanish: '',
		FatherId: null,
		AffectsCapital: -1,
		ItemsPage: 10,
	};
};
