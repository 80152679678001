import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { DocumentsCollection } from '../../../model';
import { DocumentationFilter } from '../../../model/filters/DocumentationFilter';
import { DocumentationApi } from '../../../api/documentation';

export const getDocumentationAction = (filter: DocumentationFilter, actualPage: number, itemsPage: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	dispatch(setDocumentationFilterCompleted(filter));
	DocumentationApi.FilterAsync(filter, actualPage, itemsPage).then((content) => {
		dispatch(getDocumentationCompletedAction(content));
	});
};

const getDocumentationCompletedAction = (documentsCollection: DocumentsCollection) => ({
	type: actionTypes.GET_DOCUMENTATION,
	payload: documentsCollection,
	meta: {
		httpEnd: true,
	},
});

const setDocumentationFilterCompleted = (filter: DocumentationFilter) => ({
	type: actionTypes.SET_DOCUMENTATION_FILTER,
	payload: filter,
	meta: {
		httpEnd: true,
	},
});
