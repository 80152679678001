import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';

export interface DialogProps {
	showModal: boolean;
	TitleDialog: string;
	QuestionDialog: string;
	onSuccess: (event: any) => void;
	onFail?: (event: any) => void;
	SuccessTitle: string;
	CancelTitle: string;
}
export interface DialogState {
	hideDialog: boolean;
}
export class DialogComponent extends React.Component<DialogProps, DialogState> {
	constructor(props: DialogProps) {
		super(props);

		this.state = {
			hideDialog: false,
		};
		this._closeDialog = this._closeDialog.bind(this);
		this._successDialog = this._successDialog.bind(this);
	}

	public render() {
		if (this.props.showModal) {
			const dialogStyles = { main: { maxWidth: 450 } };

			const dialogContentProps = {
				type: DialogType.normal,
				title: this.props.TitleDialog,
				subText: this.props.QuestionDialog,
			};
			const modalProps = {
				titleAriaId: 'myLabelId',
				subtitleAriaId: 'mySubTextId',
				isBlocking: true,
				styles: dialogStyles,
				isModeless: false,
			};
			return (
				<>
					<Dialog
						hidden={this.state.hideDialog}
						onDismiss={this._closeDialog}
						dialogContentProps={dialogContentProps}
						modalProps={modalProps}
					>
						{null /** You can also include null values as the result of conditionals */}
						<DialogFooter>
							<PrimaryButton onClick={this._successDialog} text={this.props.SuccessTitle} className='dialogBtn-delete' />
							<DefaultButton onClick={this._closeDialog} text={this.props.CancelTitle} className='dialogBtn-cancel' />
						</DialogFooter>
					</Dialog>
				</>
			);
		} else {
			return <div></div>;
		}
	}

	private readonly _showDialog = () => {
		this.setState({ hideDialog: true });
	};

	private readonly _successDialog = (event) => {
		this.props.onSuccess(event);
		this._closeDialog(event);
	};
	private readonly _closeDialog = (event) => {
		this.props.onFail(event);
		this.setState({ hideDialog: false });
	};
}
