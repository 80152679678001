import { Person, Position } from '.';

export interface AdminBody {
	id: number;
	membersDescription: string;
	bodyTypeId?: number;
	dateAssigned: Date;
}


export const createEmptyAdminBody = (): AdminBody => {
	return {
		id: 0,
		bodyTypeId: null,
		membersDescription : "",
		dateAssigned: null
	};
};

export interface BodyPosition {
	id: number;
	isPerson: boolean;
	appointmentDate?: Date;
	dateAppointmentFinal?: Date;
	terminationDate?: Date;
	proposedAppoiment: string;
	representativeId?: number;
	personId?: number;
	companyId?: number;
	companyName?: string;
	positionTypeId?: number;
	undefined: boolean;
	representative?: Person;
	deedId?: number;
}

export interface BodyRecordCompany {
	id: number;
	members: Position[];
	BodyTypeId: number;
}

export const createEmptyBodyPosition = (): BodyPosition => {
	return {
		id: 0,
		isPerson: true,
		appointmentDate: null,
		dateAppointmentFinal: null,
		terminationDate: null,
		proposedAppoiment: '',
		representativeId: 0,
		personId: null,
		companyId: null,
		companyName: null,
		positionTypeId: 0,
		undefined: false,
		representative: null,
	};
};
