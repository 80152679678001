import { PersonTypes } from './enums/personType';
export interface Person {
	id: number;
	name: string;
	dni: string;
	passport: string;
	email: string;
	nationality: string;
	tradeSpanish: string;
	tradeEnglish: string;
	tradeId: number;
	personType: PersonTypes;
}

export const createPerson = (): Person => {
	let person: Person = {
		dni: '',
		email: '',
		id: 0,
		name: '',
		passport: '',
		nationality: '',
		tradeSpanish: '',
		tradeEnglish: '',
		tradeId: 0,
		personType: PersonTypes.Interna,
	};

	return person;
};

export interface AuthorizedPerson {
	id: number;
	name: string;
	email: string;
}
export const createAuthorizedPerson = (): AuthorizedPerson => {
	let person: AuthorizedPerson = {
		email: '',
		id: 0,
		name: '',
	};
	return person;
};

export interface PersonCollection {
	list: Person[];
	totalNumberOfRows: number;
}
export const createEmptyPeople = (): PersonCollection => {
	return {
		list: [],
		totalNumberOfRows: 0,
	};
};
