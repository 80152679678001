import { actionTypes } from '../../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { PowerRepresentativeDetail } from '../../../../model';
import { PowerApi } from '../../../../api/power';

export const getPowerAction = (id: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	PowerApi.GetByIdAsync(id).then((result) => {
		dispatch(getPowerCompleted(result));
	});
};

const getPowerCompleted = (result: PowerRepresentativeDetail) => ({
	type: actionTypes.GET_POWER_DETAIL,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
