import { actionTypes } from '../common/const/actionTypes';
import { PowerRepresentativeDetail, PowerSubject } from '../model';

const createEmptyPoweSubjectList = (): PowerSubject[] => {
	return [];
};

const createEmptyPowerRepresentative = () : PowerRepresentativeDetail => {
	return {
		id: 0,
		departmentId: 0,
		powerExcerciseId: null,
		personId: null,
		powerTypeId: 0,
		revokeOn: null,
		state: null,
		personName: '',
		powerRepresentative: [],
		documents: [],
		legalCompanyId:null,
		companyId:0,
		legalCompanyName:'',
		// powerType: {
		// 	id: null,
		// 	descriptionEnglish: '',
		// 	descriptionSpanish: ''
		// }
	}
}

const createEmptyLastBox= (): string => {
	return "";
};

export const powerReducer = (state = createEmptyPowerRepresentative(), action) => {
	switch (action.type) {
		case actionTypes.GET_POWER_DETAIL:
			return handleGetPowerCompleted(state, action.payload);
	}

	return state;
};

export const deedReducer = (state, action) => {
	switch (action.type) {
		case actionTypes.SAVE_POWER_COMPLETED:
			return handleSavePowerCompleted(state, action.payload);
	}

	return state;
};

export const powerSubjectListReducer = (state = createEmptyPoweSubjectList(), action) => {
	switch (action.type) {
		case actionTypes.GET_POWER_SUBJECTS:
			return handleGetPowerSubjectListCompleted(state, action.payload);
	}

	return state;
}

export const getLastBoxReducer = (state = createEmptyLastBox(), action) => {
	switch (action.type) {
		case actionTypes.GET_LAST_BOX:
			return handleGetLastBoxCompleted(state, action.payload);
	}

	return state;
}

const handleSavePowerCompleted = (state: number, payload: number) => {
    return payload;
}

const handleGetPowerCompleted = (state: PowerRepresentativeDetail, payload: PowerRepresentativeDetail) => {
    return payload;
}


const handleGetPowerSubjectListCompleted = (state: PowerSubject[], payload: PowerSubject[]) => {
	return payload;
};

const handleGetLastBoxCompleted = (state: string, payload: string) => {
    return payload;
};