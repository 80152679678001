import * as fetch from 'isomorphic-fetch';
import { ApiUrls } from '../../common/const/api';
import * as msal from "../../common/security/msal";
import { SiteProps } from "../../model/Config";
import { UserInfo } from '../../model/User';

import { AppInsights } from "applicationinsights-js";

const GetUserInfo = async (): Promise<UserInfo> => {

	AppInsights.trackTrace("FRONT - GetUserInfo - Init");

	let baseUrl = `${SiteProps.SiteURL}/api/${ApiUrls.Me}/Me`;

	let token = await msal.authProvider.getIdToken();

    let obj = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	return fetch(baseUrl, obj).then((response: any) => response.json());
};


export const MeApi = {
    GetUserInfo
}

