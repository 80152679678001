export enum ActiveDeedFilter {
	Company = 1,
	TypeSpanish = 2,
	TypeEnglish = 3,
	SubtypeSpanish = 4,
	SubtypeEnglish = 5,
	DocumentDate = 6,
	Notary = 7,
	Inscription = 8,
	protocol = 9,
}
