import { actionTypes } from '../common/const/actionTypes';
import { createEmptyPersonFilter, PersonFilter } from '../model/filters';
import { createEmptyPeople, createPerson, Person, PersonCollection } from '../model/Person';

export const peopleListReducer = (state = createEmptyPeople(), action) => {
	switch (action.type) {
		case actionTypes.GET_PEOPLE:
			return handleGetPeopleCompleted(state, action.payload);
	}

	return state;
};

export const personReducer = (state = createPerson(), action) => {
	switch (action.type) {
		case actionTypes.GET_PERSON:
			return handleGetPersonCompleted(state, action.payload);
	}

	return state;
};

export const personNamesReducer = (state = [createPerson()], action) => {
	switch (action.type) {
		case actionTypes.GET_PEOPLE_NAMES:
			return handleGetPersonNamesCompleted(state, action.payload);
	}
	return state;
};

export const personFormReducer = (state: number = 0, action) => {
	switch (action.type) {
		case actionTypes.SAVE_PERSON_COMPLETED:
			return handleSavePersonCompleted(state, action.payload);
		case actionTypes.DELETE_PERSON:
			return handleDeletePersonCompleted(state, action.payload);
	}

	return state;
};

export const personAuthorizedNamesReducer = (state = [createPerson()], action) => {
	switch (action.type) {
		case actionTypes.GET_PERSONS_AUTHORIZED:
			return handleGetPersonNamesCompleted(state, action.payload);
	}
	return state;
};
export const nameAzureAdReducer = (state: string = '', action) => {
	switch (action.type) {
		case actionTypes.GET_NAME_AD:
			return handleGetNameAzureAdCompleted(state, action.payload);
	}
	return state;
};

export const personFilterReducer = (state = createEmptyPersonFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_PERSON_FILTER:
			return handleSetPersonFilterCompleted(state, action.payload);
	}

	return state;
};

const handleGetPeopleCompleted = (state: PersonCollection, payload: PersonCollection) => {
	return payload;
};
const handleGetNameAzureAdCompleted = (state: string, payload: string) => {
	return payload;
};

const handleGetPersonCompleted = (state: Person, payload: Person) => {
	return payload;
};

const handleGetPersonNamesCompleted = (state: Person[], payload: Person[]) => {
	return payload;
};
const handleDeletePersonCompleted = (state: number, payload: boolean) => {
	return payload;
};

const handleSavePersonCompleted = (state: number, payload: number) => {
	return payload;
};

const handleSetPersonFilterCompleted = (state: PersonFilter, payload: PersonFilter) => {
	return payload;
};
