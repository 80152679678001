import moment from 'moment';
import { MessageBarType } from 'office-ui-fabric-react';
import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import {
	BaseName,
	BaseType,
	BodyPosition,
	CompanySearch,
	createEmptyPowerRepresentativeDetail,
	createEmptyStockholder,
	Language,
	NotaryData,
	Person,
	Power,
	PowerRepresentativeDetail,
	PowerRevocationFilter,
	PowerSubject,
	RecordData,
	Stockholder,
	NotaryPeople
} from '../../../model';

import '../../../styles/pages/representatives.scss';
import * as utils from '../../../utils/extensions';
import { getResourcesFile } from '../../../utils/extensions';
import { NotaryDataTab, RegistryDataTab, RepresentativesTab } from './Tabs';
import { RepresentativesForm } from './Tabs/Forms/representativesForm';

interface IProps {
	power: Power;
	peopleNames: Person[];
	notaryPeopleNames: NotaryPeople[];
	powerTypes: BaseType[];
	language: Language;
	departments: BaseType[];
	powerExerciseNames: BaseType[];
	powerTypeId: number;
	powerSubjects: PowerSubject[];
	updateFormRepresentative: (power: Power) => void;
	getPeopleNames: (filter: string) => void;
	getNotaryPeopleNames: (filter: string) => void;
	getCompaniesSearch: (filter: string) => void;
	getPowerSubjects: (filter: PowerRevocationFilter) => void;
	isReadOnly: boolean;
	powerRevocationFilter: PowerRevocationFilter;
	companiesSearch:CompanySearch[];
}

interface IState {
	isAdminBodyFormVisible: boolean;
	isStockholdersFormVisible: boolean;
	isRepresentativesFormVisible: boolean;
	stockholder: Stockholder;
	power: Power;
	selectedOptions: BaseName[];
	position: BodyPosition;
	errorMessages: string[];
	representative: PowerRepresentativeDetail;
	editRepresentativeIndex: number;
	isEdit: boolean;
	finishedEdit: boolean;
}

export class RepresentativeTabs extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			isAdminBodyFormVisible: false,
			isStockholdersFormVisible: false,
			isRepresentativesFormVisible: false,
			stockholder: createEmptyStockholder(),
			power: this.props.power,
			position: {
				id: 0,
				isPerson: true,
				appointmentDate: null,
				companyId: null,
				dateAppointmentFinal: null,
				positionTypeId: 0,
				proposedAppoiment: null,
				representativeId: 0,
				terminationDate: null,
				undefined: false,
			},
			selectedOptions: [],
			errorMessages: [],
			representative: null,
			editRepresentativeIndex: -1,
			isEdit: false,
			finishedEdit: true,
		};
	}


	public render() {

		let language = getResourcesFile(this.props.language);
		let validationErrors = this.state.errorMessages?.map((item: string) => {
			return <MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />;
		});

		return (
			<Tabs className='content_edit_block-tabs content_edit_block_filter '>
				<TabList>
					<Tab>{language['deeds']['form']['sections']['representatives']['title']}</Tab>
					<Tab>{language['deeds']['form']['sections']['notarial-data']['title']}</Tab>
					<Tab>{language['deeds']['form']['sections']['registral-data']['title']}</Tab>
				</TabList>
				<TabPanel>
					<div className='representativeTab'>
						<div className='representativeTab_newRepresentativeForm'>
							<RepresentativesForm
								addRepresentative={this.addRepresentative}
								people={this.props.peopleNames}
								powerTypes={this.props.powerTypes}
								language={this.props.language}
								selectedOptions={this.state.selectedOptions}
								getPeopleNames={this.props.getPeopleNames}
								departments={this.props.departments}
								powerExerciseNames={this.props.powerExerciseNames}
								powerTypeId={this.props.powerTypeId}
								isReadOnly={this.props.isReadOnly}
								representative={this.state.representative}
								updateRepresentative={this.updateRepresentative}
								cleanRepresentativeEdit={this.cleanRepresentativeEdit}
								getPowerSubjects={this.props.getPowerSubjects}
								powerSubjects={this.props.powerSubjects}
								powerRevocationFilter={this.props.powerRevocationFilter}
								documentDate={this.props.power.documentDate}
								setRepresentative={this.setRepresentative}
								isEdit={this.state.isEdit}
								setFinishedEdit={this.setFinishedEdit}
								getCompaniesSearch={this.props.getCompaniesSearch}
								companiesSearch={this.props.companiesSearch}
								isAnyRepresentative={(this.props.power.representatives.length>0)}

							/>
						</div>
						<RepresentativesTab
							representatives={this.props.power.representatives}
							updateRepresentatives={this.updateRepresentatives}
							showForm={this.toggleRepresentativeForm}
							powerTypes={this.props.powerTypes}
							language={this.props.language}
							editRepresentative={this.editRepresentative}
							powerExerciseNames={this.props.powerExerciseNames}
							isReadOnly={this.props.isReadOnly}
							documentDate={this.props.power.documentDate}
							departments={this.props.departments}
							index={this.state.editRepresentativeIndex}
							cancelEditRepresentative={this.cleanRepresentativeEdit}
							setFinishedEdit={this.setFinishedEdit}
						/>
					</div>
					<div className='ms-Grid-col main-container__content_edit_block errors-container'>{validationErrors}</div>
				</TabPanel>
				<TabPanel>
					<div className='notaryTab'>
						<div className='notaryTab_newNotaryForm'>
							<NotaryDataTab
								notaryData={this.props.power.notaryData}
								updateNotaryData={this.updateNotaryData}
								language={this.props.language}
								isReadOnly={this.props.isReadOnly}
								notaryPeople={this.props.notaryPeopleNames}
								getNotaryPeopleNames={this.props.getNotaryPeopleNames}
							/>
						</div>
					</div>
				</TabPanel>
				<TabPanel>
					<div className='recordTab'>
						<div className='recordTab_newRecordForm'>
							<RegistryDataTab
								recordData={this.props.power.recordData}
								updateRecordData={this.updateRecordData}
								language={this.props.language}
								isReadOnly={this.props.isReadOnly}
							/>
						</div>
					</div>
				</TabPanel>
			</Tabs>
		);
	}

	private readonly updateNotaryData = (notaryData: NotaryData) => {
		let power = { ...this.props.power };
		power.notaryData = notaryData;
		this.props.updateFormRepresentative(power);
	};

	private readonly setRepresentative = (representative: PowerRepresentativeDetail) => {
		this.setState({ representative });
	};

	private readonly updateRecordData = (recordData: RecordData) => {
		let power = { ...this.props.power };
		power.recordData = recordData;
		this.props.updateFormRepresentative(power);
	};

	private readonly updateRepresentatives = (representatives: PowerRepresentativeDetail[]) => {
		let power = { ...this.props.power };
		power.representatives = representatives;

		this.props.updateFormRepresentative(power);
	};

	private readonly addRepresentative = (newRepresentative: PowerRepresentativeDetail) => {
		let power = { ...this.props.power };
		let errorMessages: string[] = [];
		let language = utils.getResourcesFile(this.props.language);

		if (power.representatives === null) {
			power.representatives = [];
		}
		
		var existsInArray = -1;

		let format = 'DD/MM/YYYY';	

		if(newRepresentative.personId === null){

			existsInArray = power.representatives.findIndex(
				(x) =>
					x.legalCompanyId === newRepresentative.legalCompanyId &&
					x.powerTypeId === newRepresentative.powerTypeId &&
					x.state === newRepresentative.state &&					
					x.powerToRevokeId === newRepresentative.powerToRevokeId &&
					moment(x.revokeOn).format(format) === moment(newRepresentative.revokeOn).format(format) &&
					x.departmentId === newRepresentative.departmentId &&
					x.powerExcerciseId === newRepresentative.powerExcerciseId,
			);
		}else{
			existsInArray = power.representatives.findIndex(
				(x) =>
					x.personId === newRepresentative.personId &&
					x.powerTypeId === newRepresentative.powerTypeId &&
					x.state === newRepresentative.state &&
					x.powerToRevokeId === newRepresentative.powerToRevokeId &&
					moment(x.revokeOn).format(format) === moment(newRepresentative.revokeOn).format(format) &&
					x.departmentId === newRepresentative.departmentId &&
					x.powerExcerciseId === newRepresentative.powerExcerciseId,
			);			
		}		

		if (existsInArray === -1) {
			power.representatives.push(newRepresentative);
			this.props.updateFormRepresentative(power);
			this.setState({ errorMessages: [] });
		} else {
			errorMessages.push(language['generic']['messages']['required-duplicated-representative']);
			this.setState({ errorMessages });
		}
	};

	private readonly toggleRepresentativeForm = () => {
		this.setState({ isRepresentativesFormVisible: !this.state.isRepresentativesFormVisible });
	};

	private readonly editRepresentative = (editRepresentative: PowerRepresentativeDetail, index: number, isEdit: boolean) => {
		this.setState({ representative: editRepresentative, editRepresentativeIndex: index, isEdit });
	};

	private readonly updateRepresentative = (editRepresentative: PowerRepresentativeDetail) => {
		let power = { ...this.props.power };
		let errorMessages: string[] = [];
		let language = utils.getResourcesFile(this.props.language);

		let existsInArray = -1;
		let format = 'DD/MM/YYYY';	

		if(editRepresentative.personId === null || editRepresentative.personId === 0){
			existsInArray = power.representatives.findIndex(
				(x) =>
					x.legalCompanyId === editRepresentative.legalCompanyId &&
					x.powerTypeId === editRepresentative.powerTypeId &&
					x.state === editRepresentative.state &&					
					x.powerToRevokeId === editRepresentative.powerToRevokeId &&
					moment(x.revokeOn).format(format) === moment(editRepresentative.revokeOn).format(format) &&
					x.departmentId === editRepresentative.departmentId &&
					x.powerExcerciseId === editRepresentative.powerExcerciseId,
			);
		}else{
			existsInArray = power.representatives.findIndex(
				(x) =>
					x.personId === editRepresentative.personId &&
					x.powerTypeId === editRepresentative.powerTypeId &&
					x.state === editRepresentative.state &&
					x.powerToRevokeId === editRepresentative.powerToRevokeId &&
					moment(x.revokeOn).format(format) === moment(editRepresentative.revokeOn).format(format) &&
					x.departmentId === editRepresentative.departmentId &&
					x.powerExcerciseId === editRepresentative.powerExcerciseId,
			);
		}			

		if (existsInArray === -1 || existsInArray === this.state.editRepresentativeIndex) {
			power.representatives[this.state.editRepresentativeIndex] = editRepresentative;
			this.props.updateFormRepresentative(power);
			this.setState({ errorMessages: [] });
		} else {
			errorMessages.push(language['generic']['messages']['required-duplicated-representative']);
			this.setState({ errorMessages });
		}
		this.setState({ editRepresentativeIndex: -1, representative: createEmptyPowerRepresentativeDetail() });
	};

	private readonly cleanRepresentativeEdit = (isEdit: boolean) => {
		this.setState({ editRepresentativeIndex: -1, representative: createEmptyPowerRepresentativeDetail(), isEdit });
	};

	private readonly setFinishedEdit = (finishedEdit: boolean) => {
		this.setState({ isEdit: !finishedEdit });
	};
}
