import { GenericApi } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import useHistory from '../../../history';
import { Department, Language } from '../../../model';
import { getResourcesFile, showError, showSuccess } from '../../../utils/extensions';
const browserHistory = useHistory;

export const saveDepartmentAction = (department: Department, currentLanguage: Language) => (dispatch) => {
    dispatch(httpCallStartAction());
    let language = getResourcesFile(currentLanguage);
    if (department.id > 0) {
        handleUpdateDepartment(department, language, dispatch);
    } else {
        handleCreateDepartment(department, language, dispatch);
    }
};


const handleUpdateDepartment = (department: Department, language: any, dispatch) => {
    GenericApi.UpdateAsync(ApiUrls.Department, department).then((result: any) => {
        if (result.error) {
            showError(language['departments']['messages']['error-update'] + " " + language['generic']['error'][result.error]);
            dispatch(saveDepartmentCompleted(result));
        }
        else {
            showSuccess(language['departments']['messages']['success-update']);
            browserHistory.goBack();
            dispatch(saveDepartmentCompleted(result));
        }
    });
}

const handleCreateDepartment = (department: Department, language: any, dispatch) => {
    GenericApi.AddAsync(ApiUrls.Department, department).then((result: any) => {
        if (result > 0) {
            showSuccess(language['departments']['messages']['success']);
            browserHistory.goBack();
            dispatch(saveDepartmentCompleted(result));
        } else {
            showError(language['departments']['messages']['error'] + " " + language['generic']['error'][result.error]);
            dispatch(saveDepartmentCompleted(result));
        }
    });
}

const saveDepartmentCompleted = (result: number) => ({
    type: actionTypes.SAVE_DEPARTMENT_COMPLETED,
    payload: result,
    meta: {
        httpEnd: true,
    }
});