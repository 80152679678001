import * as React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Stockholder, Language, TradeCompany, createEmptyStockholder, OperationType } from '../../../../../model';
import { ActiveLanguage } from '../../../../../model/enums/activeLanguage';
import { DialogComponent } from '../../../../../common/dialog/dialogComponent';
import { languages } from '../../../../../common/const/resources';

export interface IProps {
	stockholders: Stockholder[];
	companies: TradeCompany[];
	language: Language;
	isReadOnly: boolean;
	maxShares: number;
	index: number;
	editStockholder: (stockholder: Stockholder, index: number, isEdit: boolean) => void;
	deleteStockholder: (stockholder: Stockholder) => void;
	cancelEditStockholder: (isEdit: boolean) => void;
	setFinishedEdit: (finishedEdit: boolean) => void;
}

interface IState {
	stockholder: Stockholder;
	showModal: boolean;
}

export class ActualStockholdersGrid extends React.Component<IProps, IState> {
	static contextTypes = {
		router: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			stockholder: createEmptyStockholder(),
			showModal: false,
		};
	}
	
	public render() {
		let stockholders = this.props.stockholders ? this.props.stockholders : [];
		let locale = this.props.language.name.includes(ActiveLanguage.English) ? 'en-EN' : 'de-DE';
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				{stockholders.map((stockholder, index) => {
					let company = this.props.companies.find((item) => item.id === stockholder.companyStockholderId);
					let numberStockStart = stockholder.startNumbering?.toLocaleString(locale, {
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
				  	});
					let numberStock = stockholder.numberStock ?.toLocaleString(locale, {
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
				  	});
					let participation: string =
						stockholder.numberStock > 0 && this.props.maxShares > 0
							? ((stockholder.numberStock / this.props.maxShares) * 100).toLocaleString(locale, {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
							  }) + '%'
							: '';
					let numberStockRange = stockholder.numberStock
						? (stockholder.startNumbering + (stockholder.numberStock - 1))?.toLocaleString(locale, {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
						  })
						: '';
					let operation = ''
					if(stockholder.operationType !== null && stockholder.operationType !== undefined){
						if(parseInt(stockholder.operationType.toString()) === OperationType.Compra){
							operation = language['deeds']['form']['sections']['actual-shareholders']['fields']['operation-type']['options']['buy']
						}
						if(parseInt(stockholder.operationType.toString()) === OperationType.Venta){
							operation = language['deeds']['form']['sections']['actual-shareholders']['fields']['operation-type']['options']['sell']
						}
					}

					return (
						<tr>
							<td>{company ? company.name : ''}</td>
							<td>{numberStock && numberStock !== '0' ? numberStock.toString() : ''}</td>
							<td>{numberStockStart && numberStock !== '0'? numberStockStart : ''}</td>
							<td>
								{numberStockStart&& numberStockRange
									? `De ${numberStockStart} a ${numberStockRange}`
									: ''}
							</td>
							<td>{participation}</td>
							<td>{operation}</td>
							<td style={{ visibility: this.props.isReadOnly ? 'hidden' : 'visible' }}>
							<div style={{ display: this.props.index > -1 ? 'none' : '' }}>
									<IconButton iconProps={{ iconName: 'EditContact' }} onClick={() => this.Edit(stockholder, index)} />
									<IconButton iconProps={{ iconName: 'UserRemove' }} onClick={() => this.ShowModal(stockholder)} />
								</div>
								<div style={{ display: this.props.index !== index ? 'none' : '' }}>
									<IconButton
										iconProps={{ iconName: 'FollowUser' }}
										onClick={() => this.props.cancelEditStockholder(false)}
									/>
								</div>
								<DialogComponent
									showModal={this.state.showModal}
									TitleDialog={language['generic']['dialog']['title']}
									QuestionDialog={language['generic']['dialog']['question']}
									onSuccess={() => {
										this.Delete();
									}}
									onFail={() => {
										this.Fail();
									}}
									CancelTitle={language['generic']['dialog']['cancel']}
									SuccessTitle={language['generic']['dialog']['confirm']}
								/>
							</td>
						</tr>
					);
				})}
			</>
		);
	}
	private readonly Edit = (stockholder: Stockholder, index: number) => {
		this.props.editStockholder(stockholder, index, true);
		this.props.setFinishedEdit(false);
	};

	private readonly Delete = () => {
		this.props.deleteStockholder(this.state.stockholder);
		this.Fail();
	};

	private Fail() {
		this.setState({ showModal: false, stockholder: createEmptyStockholder() });
	}

	private ShowModal(stockholder: Stockholder) {
		this.setState({ showModal: true, stockholder: stockholder });
	}
}
