import { actionTypes } from '../common/const/actionTypes';
import { createEmptyPosition, createEmptyPositions, PositionCollection, PositionDetail } from '../model/';
import { createEmptyPositionDetailFilter, createEmptyPositionFilter, PositionDetailFilter, PositionFilter } from '../model/filters/';

export const positionListReducer = (state = createEmptyPositions(), action) => {
	switch (action.type) {
		case actionTypes.GET_POSITIONS:
			return handleGetPositionsCompleted(state, action.payload);
	}

	return state;
};

export const positionDetailReducer = (state = createEmptyPosition(), action) => {
	switch (action.type) {
		case actionTypes.GET_POSITION:
			return handleGetPositionCompleted(state, action.payload);
	}

	return state;
};

export const positionFilterReducer = (state = createEmptyPositionFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_POSITION_FILTER:
			return handleSetPositionFilterCompleted(state, action.payload);
	}

	return state;
};

export const positionDetailFilterReducer = (state = createEmptyPositionDetailFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_POSITION_DETAIL_FILTER:
			return handleSetPositionDetailFilterCompleted(state, action.payload);
	}

	return state;
};

const handleGetPositionsCompleted = (state: PositionCollection, payload: PositionCollection) => {
	return payload;
};

const handleGetPositionCompleted = (state: PositionDetail, payload: PositionDetail) => {
	return payload;
};

const handleSetPositionFilterCompleted = (state: PositionFilter, payload: PositionFilter) => {
	return payload;
};

const handleSetPositionDetailFilterCompleted = (state: PositionDetailFilter, payload: PositionDetailFilter) => {
	return payload;
};
