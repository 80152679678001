export const actionsDef = {
	http: {
		HTTP_CALL_START: 'HTTP_CALL_START',
		HTTP_CALL_END: 'HTTP_CALL_END',
	},
	error: {
		REDIRECT_TO_ERRORPAGE: 'REDIRECT_TO_ERRORPAGE',
    },
    home:{
        LOAD_HOME:'LOAD_HOME'
    }
};
