import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { GenericApi } from '../../../../api/generic';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { BaseType } from '../../../../model';

export const getDepartmentsAction = () => (dispatch) => {
	dispatch(httpCallStartAction());

	GenericApi.GetAsync<BaseType>(ApiUrls.Department).then((result) => {
		dispatch(getDepartmentsCompleted(result));
	});
};

const getDepartmentsCompleted = (departments: BaseType[]) => ({
	type: actionTypes.GET_DEPARTMENTS,
	payload: departments,
	meta: {
		httpEnd: true,
	},
});
