import { actionTypes } from '../../../common/const/actionTypes';
import { DepartmentFilter } from '../../../model/filters';

export const setDepartmentsFilterAction = (filter: DepartmentFilter) => (dispatch) => {
	dispatch(setDepartmentsFilterActionCompleted(filter));
};
const setDepartmentsFilterActionCompleted = (departmentsFilter: DepartmentFilter) => ({
	type: actionTypes.SET_DEPARTMENTS_FILTER,
	payload: departmentsFilter,
});
