import { connect } from 'react-redux';
import { StateReducer } from '../../reducers';
import { DepartmentsHome } from './departmentsHome';
import { Department, Language } from '../../model';
import {
	deleteDepartmentAction,
	exportToExcelAction,
	getDepartmentAction,
	getDepartmentCollectionAction,
	saveDepartmentAction,
	setDepartmentsFilterAction,
} from './actions';
import { DepartmentFilter } from '../../model/filters';

const mapStateToProps = (state: StateReducer) => ({
	language: state.language,
	user: state.user,
	department: state.departments,
	departmentCollection: state.departmentCollection,
	departmentFilter: state.departmentFilter,
	departmentSave: state.departmentResponse,
	departmentDelete: state.departmentResponse,
	business: state.businessNames,
	currentUser: state.users,
	filter: state.departmentFilter,
});

const mapDispatchToProps = (dispatch: any) => ({
	deleteDepartment: (filter: DepartmentFilter, language: Language, id: number) => dispatch(deleteDepartmentAction(filter, language, id)),
	exportToExcel: (filter: DepartmentFilter) => dispatch(exportToExcelAction(filter)),
	getDepartment: (id: number) => dispatch(getDepartmentAction(id)),
	getDepartmentCollection: (filter: DepartmentFilter, pageNumber: number, itemsPage: number) =>
		dispatch(getDepartmentCollectionAction(filter, pageNumber, itemsPage)),
	setDepartmentFilter: (filter: DepartmentFilter) => dispatch(setDepartmentsFilterAction(filter)),
	saveDepartment: (department: Department, language: Language) => dispatch(saveDepartmentAction(department, language)),
});

export const DepartmentsContainer = connect(mapStateToProps, mapDispatchToProps)(DepartmentsHome);
