import * as React from 'react';
import { languages } from '../const/resources';
import { ActiveLanguage } from '../../model/enums/activeLanguage';
import { Language } from '../../model/Language';
import { Icon } from 'office-ui-fabric-react/lib/components/Icon';
export interface IProps {
	language: Language;
}
export class UnauthenticatedView extends React.Component<IProps, {}> {
	/*constructor(props: any) {
		super(props);
	}*/

	public render() {
        let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		return (
			<div className='unauthenticated'>
				<Icon className='icon-info' iconName='Info' />
				<span>{language['generic']['unauthenticathed']}</span>
			</div>
		);
	}
}
