import { DefaultButton } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { languages } from '../../../common/const/resources';
import { routeUrls } from '../../../common/const/routes';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { AuthorizationAccount, AuthorizationAccountCollection, Language, OrderBy, User } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { ActiveAuthorizationFilter, AuthorizationFilter } from '../../../model/filters';
import { formatDate } from '../../../utils/extensions';

export interface IProps {
	authorizationsCollection: AuthorizationAccountCollection;
	user: User;
	filter: AuthorizationFilter;
	language: Language;
	onchangeFilter: any;
	page: number;
	getAuthorizations: (filter: AuthorizationFilter, pageNumber: number) => Promise<AuthorizationAccountCollection>;
	exportToExcel: (filter: AuthorizationFilter) => Promise<any>;
	setAuthorizationFilter: (filter: AuthorizationFilter) => AuthorizationFilter;

}

export interface IState {
	id: number;
	showModal: boolean;
	actualFilter: AuthorizationFilter;
	orderbyAuthorized: string;
	orderbyNifAuthorized: string;
	orderByCompany: string;
	orderByAccount: string;
	orderByCreationDate: string;
	orderByRevocationDate: string;
	orderByStatusDate: string;
	itemsPage: number;
}

class AuthorizationsAccountDataGridComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			id: 0, showModal: false,
			orderbyAuthorized: 'ms-Icon--ChevronUpSmall',
			orderbyNifAuthorized: 'ms-Icon--ChevronUpSmall',
			orderByCompany: 'ms-Icon--ChevronUpSmall',
			orderByAccount: 'ms-Icon--ChevronUpSmall',
			orderByCreationDate: 'ms-Icon--ChevronUpSmall',
			orderByRevocationDate: 'ms-Icon--ChevronUpSmall',
			orderByStatusDate: 'ms-Icon--ChevronUpSmall',
			itemsPage: 10,
			actualFilter: this.props.filter
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			this.setState({ actualFilter: this.props.filter });
		}
	}

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		return (
			<>
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.authorizationsCollection.totalNumberOfRows > 1 ? (
								<span>{this.props.authorizationsCollection.totalNumberOfRows} {language['generic']['list']['table']['results']}</span>
							) : this.props.authorizationsCollection.totalNumberOfRows === 1 ? (
								<span>{this.props.authorizationsCollection.totalNumberOfRows} {language['generic']['list']['table']['result']}</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={
								this.props.authorizationsCollection.totalNumberOfRows > 0
									? 'button-download' : 'button-download isDisabled'
							}
							onClick={this.handleExportClick}>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th className=''>
									<div className='table-header'>
										<span>{language['authorizations']['list']['table']['columns']['authorized']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveAuthorizationFilter.AuthorizedName === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.AuthorizedOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByAuthorized}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveAuthorizationFilter.AuthorizedName === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.AuthorizedOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByAuthorized}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['authorizations']['list']['table']['columns']['authorized-nif']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveAuthorizationFilter.AuthorizedNif === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.NifAutorizrdOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByNifAuthorized}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveAuthorizationFilter.AuthorizedNif === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.NifAutorizrdOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByNifAuthorized}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['authorizations']['list']['table']['columns']['society']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveAuthorizationFilter.Company === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.CompanyOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByCompany}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveAuthorizationFilter.Company === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.CompanyOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByCompany}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['authorizations']['list']['table']['columns']['account']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveAuthorizationFilter.Account === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.AccountOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByAccount}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveAuthorizationFilter.Account === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.AccountOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByAccount}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['authorizations']['list']['table']['columns']['creation-date']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveAuthorizationFilter.CreationDate === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.CreationDateOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByCreationDate}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveAuthorizationFilter.CreationDate === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.CreationDateOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByCreationDate}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['authorizations']['list']['table']['columns']['revocation-date']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveAuthorizationFilter.RevocationDate === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.RevocationDateOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByRevocationDate}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveAuthorizationFilter.RevocationDate === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.RevocationDateOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByRevocationDate}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['authorizations']['list']['table']['columns']['status']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveAuthorizationFilter.Status === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.StatusOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByStatus}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveAuthorizationFilter.Status === this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.StatusOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByStatus}
											></i>
										</div>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{this.props.authorizationsCollection.list.map((item: AuthorizationAccount) => {
								let state = item.descriptionSpanish;
								let stateColor = '';
								if (item.stateId && item.stateId === 1) {
									stateColor = 'status-green';
								}

								if (item.stateId && item.stateId === 2) {
									stateColor = 'status-red';
								}

								if (this.props.language.name.includes(ActiveLanguage.English)) {
									state = item.descriptionEnglish;
								}
								return (
									<tr className='flex-option'>
										<td className='mainColumn'>{item.name}</td>
										<td className=''>{item.authorizedDNI}</td>
										<td className=''>{item.companyName}</td>
										<td className='ellipsis'>{item.account}</td>
										<td className=''>{formatDate(item.creationDate, this.props.language)}</td>
										<td className=''>{formatDate(item.revokeDate, this.props.language)}</td>
										<td className='status-cell'>
											<i className={'ms-Icon ms-Icon--StatusCircleInner ' + stateColor} aria-hidden='true'></i>
											{state}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				{<AdvancedPagination
					currentPage={this.props.page}
					items={this.props.authorizationsCollection.totalNumberOfRows}
					link= {routeUrls.authorizationsPagination}
					updateMaxPageItems={this.handleItemsPerPageChange} 
					language={this.props.language}
					/>
				}

			</>
		);
	}
	public readonly deleteAuthorizedAccount = (id: number): Promise<Boolean> => {
		let result = this.props.deleteAuthorizedAccount(this.state.actualFilter, this.props.language, id);
		return result;
	};

	public readonly handleOrderByCompany = () => {
		let filter: AuthorizationFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveAuthorizationFilter.Company;
		let orderBy: string = '';

		if (filter.CompanyOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.CompanyOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.CompanyOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByCompany: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getAuthorizations(filter, 1, this.state.itemsPage);
	};

	public readonly handleOrderByAuthorized = () => {
		let filter: AuthorizationFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveAuthorizationFilter.AuthorizedName;
		let orderBy: string = '';

		if (filter.AuthorizedOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.AuthorizedOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.AuthorizedOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderbyAuthorized: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getAuthorizations(filter, 1, this.state.itemsPage);
	};

	public readonly handleOrderByNifAuthorized = () => {
		let filter: AuthorizationFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveAuthorizationFilter.AuthorizedNif;
		let orderBy: string = '';

		if (filter.NifAutorizrdOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.NifAutorizrdOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.NifAutorizrdOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderbyNifAuthorized: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getAuthorizations(filter, 1, this.state.itemsPage);
	};

	public readonly handleOrderByAccount = () => {
		let filter: AuthorizationFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveAuthorizationFilter.Account;
		let orderBy: string = '';

		if (filter.AccountOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.AccountOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.AccountOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByAccount: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getAuthorizations(filter, 1, this.state.itemsPage);
	};

	public readonly handleOrderByCreationDate = () => {
		let filter: AuthorizationFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveAuthorizationFilter.CreationDate;
		let orderBy: string = '';

		if (filter.CreationDateOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.CreationDateOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.CreationDateOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByCreationDate: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getAuthorizations(filter, 1, this.state.itemsPage);
	};

	public readonly handleOrderByRevocationDate = () => {
		let filter: AuthorizationFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveAuthorizationFilter.RevocationDate;
		let orderBy: string = '';

		if (filter.RevocationDateOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.RevocationDateOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.RevocationDateOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByRevocationDate: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getAuthorizations(filter, 1, this.state.itemsPage);
	};

	public readonly handleOrderByStatus = () => {
		let filter: AuthorizationFilter = { ...this.state.actualFilter };
		filter.CurrentFilter = ActiveAuthorizationFilter.Status;
		let orderBy: string = '';

		if (filter.StatusOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.StatusOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--SortDown';
			filter.StatusOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByStatusDate: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getAuthorizations(filter, 1, this.state.itemsPage);
	};

	public handleExportClick = () => {
		let filter: AuthorizationFilter = { ...this.state.actualFilter };
		this.props.exportToExcel(filter);
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		this.setState({ itemsPage: itemsPage });
		this.props.getAuthorizations(this.state.actualFilter, 1, itemsPage);
	}
}
export const AuthorizationsAccountDataGrid = withRouter(AuthorizationsAccountDataGridComponent);

