export * from './iError';
export * from './Base';
export * from './BaseType';
export * from './BodyTypes';
export * from './Company';
export * from './Config';
export * from './Deed';
export * from './DeedType';
export * from './inputSelectModel';
export * from './Person';
export * from './Representative';
export * from './OrderBy';
export * from './Document';
export * from './AdminBody';
export * from './Stockholder';
export * from './User';
export * from './Language';
export * from './Position';
export * from './FileSp';
export * from './AuthorizationAccount';
export * from './AuthorizationStatus';
export * from './Business';
export * from './department';
export * from './trade';
export * from './Powers/index';
export * from './PositionType';
export * from './DocumentType';
export * from './Country';
export * from './Documentation';
export * from './KeyValue';
export * from './ModuleInfo';
export * from './NotaryPeople';
