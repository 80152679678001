import { actionTypes } from '../common/const/actionTypes';

export const csvDownloadReducer = (state = '', action) => {
	switch (action.type) {
		case actionTypes.SET_CSV_DOWNLOAD_LINK:
			return handleSetCsvDownloadLinkCompleted(state, action.payload);
	}

	return state;
};

const handleSetCsvDownloadLinkCompleted = (state: string, payload: string) => {
	return payload;
};
