import { showSuccess, showError } from '../../../../utils/extensions';
import { actionTypes } from '../../../../common/const/actionTypes';
import { RepresentativeApi } from '../../../../api/representatives';
import { RepresentativeFilter } from '../../../../model/filters/RepresentativeFilter';
import { getRepresentativesAction } from './getRepresentatives';
import { Language } from '../../../../model';
import { getResourcesFile } from '../../../../utils/extensions';

export const deleteRepresentativeAction = (filter: RepresentativeFilter, currentLanguage: Language, id: number) => (dispatch) => {
	let language = getResourcesFile(currentLanguage);
	RepresentativeApi.DeleteAsync(id).then((result) => {
		if (result) {
			showSuccess(language['representatives']['form']['messages']['success-delete']);
		} else if (result === false) {
			showError(language['representatives']['form']['messages']['error-delete']);
		} else {
			showError(language['representatives']['form']['messages']['error-delete']);
		}

		dispatch(getRepresentativesAction(filter, 1, 10));
		dispatch(deleteRepresentativeCompleted(result));
	});
};
const deleteRepresentativeCompleted = (result: boolean) => ({
	type: actionTypes.DELETE_REPRESENTATIVE,
	payload: result,
});
