import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { ComboBox, IComboBoxOption } from 'office-ui-fabric-react/lib/ComboBox';
import { MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { languages } from '../../../common/const/resources';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import { createEmptyDocumentType, DocumentType, Language } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';

export interface IProps {
	saveDocumentType: (documentType: DocumentType, language: Language) => Promise<number>;
	getDocumentType: (id: number) => DocumentType;
	getDocumentTypePrimaries: () => DocumentType[];
	documentType: DocumentType;
	documentTypePrimaries: DocumentType[];
	documentTypeResponse: number | boolean;
	language: Language;
	loading: boolean;
}
export interface IState {
	errorMessages: string[];
	newDocumentType: DocumentType;
}
export class DocumentTypeComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			errorMessages: [],
			newDocumentType: createEmptyDocumentType(),
		};
	}
	public componentDidMount() {
		if (this.props.match.params['id'] != null) {
			this.props.getDocumentType(this.props.match.params['id']);
		}
		this.props.getDocumentTypePrimaries();
	}

	public componentWillReceiveProps(props) {
		if (props.documentType !== this.props.documentType) {
			this.setState({ newDocumentType: { ...props.documentType } });
		}
	}

	public render() {
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		let validationErrors = this.state.errorMessages?.map((item: string) => {
			return <MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />;
		});

		let documentTypePrimaries: IComboBoxOption[] = [];
		this.props.documentTypePrimaries.forEach((item: DocumentType) => {
			documentTypePrimaries.push({
				key: item.id,
				text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish,
			});
		});
		return (
			<>
				<div className='ms-Grid-row'>
					<h1>{this.props.match.params['id'] == null ? language['documentType']['new'] : language['documentType']['edit']}</h1>
				</div>
				<div className='ms-Grid-col ms-sm12 ms-lg9 ms-xl10'>
					<div className='ms-Grid-row'>
						<div className='ms-Grid-col ms-sm12 ms-xl12'>
							<div className='main-container__content_edit ms-Grid-col ms-sm12 ms-xl3'>
								<p>{language['documentType']['fields']['descriptionSpanish']['title']}</p>
								<TextField
									type='text'
									value={this.state.newDocumentType.descriptionSpanish}
									onChange={this.handleDescriptionSpanishChange}
									onBlur={this.handleDescriptionSpanishBlur}
									maxLength={100}
								/>
								<p>{language['documentType']['fields']['descriptionEnglish']['title']}</p>
								<TextField
									type='text'
									value={this.state.newDocumentType.descriptionEnglish}
									onChange={this.handleDescriptionEnglishChange}
									onBlur={this.handleDescriptionEnglishBlur}
									maxLength={100}
								/>
								<p>{language['documentType']['fields']['type']['title']} </p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={documentTypePrimaries.sort((a, b) => a.text.localeCompare(b.text))}
									onChange={this.handleTypeChange}
									selectedKey={this.state.newDocumentType.fatherId}
									defaultSelectedKey={this.state.newDocumentType.fatherId}
									placeholder={language['documentType']['fields']['type']['placeholder']}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='ms-Grid-row'>
					<hr />
					{validationErrors}
					{isAdmin && (
						<button
							type='button'
							className={`button-primary ${!this.props.loading ? '' : 'isDisabled'} `}
							name='saveDocumentType'
							onClick={this.saveDocumentType}
						>
							{language['documentType']['save-button']}
						</button>
					)}
					<button onClick={utils.navigateBack} type='button' name='back' className='button-cancel'>
						{language['documentType']['cancel-button']}
					</button>
				</div>
			</>
		);
	}
	private readonly handleTypeChange = (event, option): void => {
		let state = { ...this.state };
		state.newDocumentType.fatherId = parseInt(option?.key);
		this.setState(state);
	};
	private readonly handleDescriptionSpanishChange = (value) => {
		let state = { ...this.state };
		state.newDocumentType.descriptionSpanish = value.target.value;
		this.setState(state);
	};
	private readonly handleDescriptionSpanishBlur = (value) => {
		let state = { ...this.state };
		state.newDocumentType.descriptionSpanish = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly handleDescriptionEnglishChange = (value) => {
		let state = { ...this.state };
		state.newDocumentType.descriptionEnglish = value.target.value;
		this.setState(state);
	};
	private readonly handleDescriptionEnglishBlur = (value) => {
		let state = { ...this.state };
		state.newDocumentType.descriptionEnglish = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly saveDocumentType = (): number => {
		let documentType = { ...this.state.newDocumentType };
		if (this.validateDocumentType(documentType)) {
			return this.props.saveDocumentType(documentType, this.props.language);
		}
		return 0;
	};
	private readonly validateDocumentType = (documentType: DocumentType) => {
		let errorMessages: string[] = [];
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		let hasDescriptionSpanish = !utils.isNullOrEmpty(documentType.descriptionSpanish);
		let hasDescriptionEnglish = !utils.isNullOrEmpty(documentType.descriptionEnglish);
		let isDocumentTypeValid = hasDescriptionSpanish && hasDescriptionEnglish;
		if (!hasDescriptionSpanish) {
			errorMessages.push(language['generic']['messages']['required-spanish-description']);
		}
		if (!hasDescriptionEnglish) {
			errorMessages.push(language['generic']['messages']['required-english-description']);
		}

		if (!isDocumentTypeValid) {
			this.setState({ errorMessages });
		}

		return isDocumentTypeValid;
	};
}
