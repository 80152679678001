
export interface NotaryPeople {
	id: number;
	name: string;
	email: string;
}

export const createNotaryPeople = (): NotaryPeople => {
	let notary: NotaryPeople = {
		email: '',
		id: 0,
		name: '',
	};

	return notary;
};

export interface NotaryPeopleCollection {
	list: NotaryPeople[];
	totalNumberOfRows: number;
}
export const createEmptyNotaryPeople = (): NotaryPeopleCollection => {
	return {
		list: [],
		totalNumberOfRows: 0,
	};
};
