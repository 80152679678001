import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { GenericApi } from '../../../../api/generic';
import { BodyTypes } from '../../../../model';

export const getBodyTypeAction = (id: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	GenericApi.GetByIdAsync<BodyTypes>(ApiUrls.BodyTypes, id).then((result) => {
		dispatch(getBodyTypeCompleted(result));
	});
};

const getBodyTypeCompleted = (result: BodyTypes) => ({
	type: actionTypes.GET_BODY_TYPE,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
