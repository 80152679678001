import { actionTypes } from '../../../../common/const/actionTypes';
import { PeopleApi } from '../../../../api/people';
import { Person } from '../../../../model';

export const getPeopleNamesAction = (filter: string) => (dispatch) => {

    PeopleApi.GetPeopleNamesFilteredAsync(filter).then((content) => {
        dispatch(getPeopleCompleted(content));
    });
};

const getPeopleCompleted = (people: Person[]) => ({
    type: actionTypes.GET_PEOPLE_NAMES,
    payload: people,
});
