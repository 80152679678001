import { actionTypes } from '../common/const/actionTypes';
import { BusinessCollection } from '../model';

const createEmptyBusinessList = (): BusinessCollection => {
	return {
		list: [],
		totalNumberOfRows: 0,
	};
};

export const businessListReducer = (state = createEmptyBusinessList(), action) => {
	switch (action.type) {
		case actionTypes.GET_BUSINESS:
			return handleGetCompaniesCompleted(state, action.payload);
	}

	return state;
};

const handleGetCompaniesCompleted = (state: BusinessCollection, payload: BusinessCollection) => {
	return payload;
};
