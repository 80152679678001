import * as React from 'react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

interface IProps {
	onClick: (collapsed) => void;
	collapsed?: boolean;
}

interface IState {}

export class CollapseFiltersButton extends React.Component<IProps, IState> {
	/* constructor(props) {
		super(props);
	}		Clean Warning Code */

	public render() {
		return (
			<div className='button__filters-collapse' onClick={this.handleClick}>
				<Icon iconName='Filter' className='button__filters-collapse-icon' />
				<Icon
					iconName='ChevronDown'
					className={`button__filters-collapse-caret ${this.props.collapsed ? 'caretDown' : 'caretUp'}`}
				/>
			</div>
		);
	}

	private handleClick = () => {
		let isCollapsed = !this.props.collapsed;
		this.props.onClick(isCollapsed);
	};
}
