import { MessageBarType } from "office-ui-fabric-react/lib/components/MessageBar";
import * as React from "react";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import { MessageBarComponent } from "../../../common/messageBar/messageBarComponent";
import { 
    AdminBody, 
    BaseName, 
    BaseType, 
    BodyPosition, 
    createEmptyBodyPosition, 
    createEmptyStockholder, 
    Deed, 
    Language, 
    NotaryData, 
    Person, 
    RecordData, 
    SocialCapital, 
    Stockholder, 
    TradeCompany,
    NotaryPeople
 } from "../../../model";
import * as utils from '../../../utils/extensions';
import { getResourcesFile } from "../../../utils/extensions";
import { ActualStockholdersTab, AdminBodyTab, NotaryDataTab, RegistryDataTab, SocialCapitalTab } from "./Tabs";

interface IProps {
    deed: Deed;
    companies: TradeCompany[];
    bodyTypes: BaseType[];
    positionTypes: BaseType[];
    peopleNames: Person[];
    notaryPeopleNames: NotaryPeople[];
    powers: BaseType[];
    powerTypes: BaseType[];
    language: Language
    isReadOnly: boolean;
    updateFormDeed: (deed: Deed) => void;
    getPeopleNames: (filter: string) => Promise<Person[]>;
    getNotaryPeopleNames: (filter: string) => void;
}

interface IState {
    isAdminBodyFormVisible: boolean;
    isStockholdersFormVisible: boolean;
    stockholder: Stockholder;
    selectedOptions: BaseName[];
    position: BodyPosition;
    errorMessages: string[];
    editStockholderIndex: number;
	isEdit: boolean;
	finishedEdit: boolean;
    editPositionIndex: number;
	isEditPosition: boolean;
	finishedEditPosition: boolean;
}

export class DeedTabs extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            isAdminBodyFormVisible: false,
            isStockholdersFormVisible: false,
            stockholder: createEmptyStockholder(),
            position: createEmptyBodyPosition(),
            selectedOptions: [],
            errorMessages: [],
            editStockholderIndex: -1,
			isEdit: false,
			finishedEdit: true,
            editPositionIndex: -1,
			isEditPosition: false,
			finishedEditPosition: true,
        }
    }

    public render() {
        let language = getResourcesFile(this.props.language);

        let validationErrors = this.state.errorMessages?.map((item: string) => {
            return (
                <MessageBarComponent
                    Message={item}
                    Type={MessageBarType.error}
                    MessageVisible={true}
                    close={null} />);
        })

        let adminBodyTab =
            <AdminBodyTab 
                members={this.props.deed.members} 
                updateAdminBody={this.updateAdminBody} 
                positionTypes={this.props.positionTypes}
                peopleNames={this.props.peopleNames} 
                bodyTypes={this.props.bodyTypes} 
                showForm={this.toggleBodyForm} 
                language={this.props.language}
                editPosition={this.editPosition}
                addPosition={this.addPosition} 
                companies={this.props.companies} 
                position={this.state.position} 
                getPeopleNames={this.props.getPeopleNames} 
                isReadOnly={this.props.isReadOnly} 
                index={this.state.editPositionIndex}
                cancelEditPosition={this.cleanPositionEdit}
                setFinishedEdit={this.setFinishedEditPosition}
                isEdit={this.state.isEditPosition}
                updatePosition={this.updatePosition}
                setPosition={this.setPosition}
                deed={this.props.deed} 
                updateBody={this.updateBody}
                />;

        let stockholdersTab =
            <ActualStockholdersTab 
                addStockholder={this.addStockholder} 
                stockholders={this.props.deed.stockholders} 
                updateStockholders={this.updateStockholders} 
                deed={this.props.deed} 
                stockholder={this.state.stockholder}
                companies={this.props.companies} 
                showForm={this.toggleStockholdersForm} 
                language={this.props.language} 
                editStockholder={this.editStockholder} 
                isReadOnly={this.props.isReadOnly} 
                maxShares={this.props.deed.socialCapital?.totalNumberStock}
                index={this.state.editStockholderIndex}
                cancelEditStockholder={this.cleanStockholderEdit}
                setFinishedEdit={this.setFinishedEdit}
                isEdit={this.state.isEdit}
                updateStockholder={this.updateStockholder}
                setStockholder={this.setStockholder}
            />;

        return <Tabs className='content_edit_block-tabs content_edit_block_filter'>
            <TabList>
                <Tab>{language['deeds']['form']['sections']['notarial-data']['title']}</Tab>
                <Tab>{language['deeds']['form']['sections']['registral-data']['title']}</Tab>
                <Tab>{language['deeds']['form']['sections']['social-capital']['title']}</Tab>
                <Tab>{language['deeds']['form']['sections']['administrative-body']['title']}</Tab>
                <Tab>{language['deeds']['form']['sections']['actual-shareholders']['title']}</Tab>
            </TabList>
            <TabPanel>
                <NotaryDataTab 
                    notaryData={this.props.deed.notaryData} 
                    updateNotaryData={this.updateNotaryData} 
                    language={this.props.language} 
                    isReadOnly={this.props.isReadOnly} 
                    notaryPeople={this.props.notaryPeopleNames}
                    getNotaryPeopleNames={this.props.getNotaryPeopleNames}
                />
            </TabPanel>
            <TabPanel>
                <RegistryDataTab recordData={this.props.deed.recordData} updateRecordData={this.updateRecordData} language={this.props.language} isReadOnly={this.props.isReadOnly} />
            </TabPanel>
            <TabPanel>
                <SocialCapitalTab socialCapital={this.props.deed.socialCapital} updateSocialCapital={this.updateSocialCapital} language={this.props.language} isReadOnly={this.props.isReadOnly} />
            </TabPanel>
            <TabPanel>
                {adminBodyTab}
                <div className="ms-Grid-col main-container__content_edit_block errors-container">
                    {validationErrors}
                </div>
            </TabPanel>
            <TabPanel>
                {stockholdersTab}
            </TabPanel>
        </Tabs>
    };

    private readonly updateNotaryData = (notaryData: NotaryData) => {
        let deed = { ...this.props.deed };
        deed.notaryData = notaryData;
        this.props.updateFormDeed(deed);
    }

    private readonly updateRecordData = (recordData: RecordData) => {
        let deed = { ...this.props.deed };
        deed.recordData = recordData;
        this.props.updateFormDeed(deed);
    }

    private readonly updateSocialCapital = (socialCapital: SocialCapital) => {
        let deed = { ...this.props.deed };
        deed.socialCapital = socialCapital;
        this.props.updateFormDeed(deed);
    }

    private readonly updateStockholders = (stockHolders: Stockholder[]) => {
        let deed = { ...this.props.deed };
        deed.stockholders = stockHolders;
        this.props.updateFormDeed(deed);
    }

    private readonly updateAdminBody = (members: BodyPosition[]) => {
        let deed = { ...this.props.deed };
        deed.members = members;
        this.props.updateFormDeed(deed);
    }

    private readonly updateBody = (body: AdminBody) => {
        let deed = { ...this.props.deed };
        body.dateAssigned = deed.documentDate ? deed.documentDate : new Date();
        deed.body = body;
        deed.bodyId=body.id;
        this.props.updateFormDeed(deed);
    }

    private readonly addPosition = (position: BodyPosition) => {
        let deed = { ...this.props.deed };
        let errorMessages: string[] = [];
        let language = utils.getResourcesFile(this.props.language);

        let positionIndex = deed.members.findIndex((item) =>
            item.representativeId === position.representativeId && item.positionTypeId === position.positionTypeId &&
            item.companyId === position.companyId && item.isPerson === position.isPerson);
        if (positionIndex === -1) {
            deed.members.push(position);
            this.setState({ errorMessages: [] });
        } else {
            errorMessages.push(language['generic']['messages']['required-duplicated-adminBody']);
            this.setState({ errorMessages });
        }
        this.props.updateFormDeed(deed);
    }

    private readonly addStockholder = (stockholder: Stockholder, oldStockholder: Stockholder) => {
        let deed = { ...this.props.deed };
        let stockholderIndex = deed.stockholders.findIndex((item) => item === oldStockholder);
        if (stockholderIndex > -1) {
            deed.stockholders.splice(stockholderIndex, 1);
        }
        deed.stockholders.push(stockholder);

        this.props.updateFormDeed(deed);
    }
	private readonly updateStockholder = (editStockholder: Stockholder) => {
		let deed = { ...this.props.deed };

        deed.stockholders[this.state.editStockholderIndex] = editStockholder;
		this.props.updateFormDeed(deed);
		this.setState({ editStockholderIndex: -1, stockholder: createEmptyStockholder() });
	};
    
    private readonly toggleBodyForm = () => {
        let state = { ...this.state }
        state.isAdminBodyFormVisible = !this.state.isAdminBodyFormVisible;
        this.setState(state);
    }

    private readonly toggleStockholdersForm = () => {
        let state = { ...this.state }
        state.isStockholdersFormVisible = !this.state.isStockholdersFormVisible;
        this.setState(state);
    }



	private readonly editStockholder = (editStockholder: Stockholder, index: number, isEdit: boolean) => {
		this.setState({ stockholder: editStockholder, editStockholderIndex: index, isEdit });
	};
	private readonly cleanStockholderEdit = (isEdit: boolean) => {
		this.setState({ editStockholderIndex: -1, stockholder: createEmptyStockholder(), isEdit });
	};

	private readonly setFinishedEdit = (finishedEdit: boolean) => {
		this.setState({ isEdit: !finishedEdit });
	};

    private readonly setStockholder = (stockholder: Stockholder) => {
		this.setState({ stockholder });
	};
    private readonly editPosition = (editPosition: BodyPosition, index: number, isEdit: boolean) => {
		this.setState({ position: editPosition, editPositionIndex: index, isEditPosition: isEdit });
	};

    private readonly cleanPositionEdit = (isEdit: boolean) => {
		this.setState({ editPositionIndex: -1, position: createEmptyBodyPosition(), isEditPosition: isEdit });
	};

	private readonly setFinishedEditPosition = (finishedEdit: boolean) => {
		this.setState({ isEditPosition: !finishedEdit });
	};

    private readonly setPosition = (position: BodyPosition) => {
		this.setState({ position });
	};

    private readonly updatePosition = (editPosition: BodyPosition) => {
		let deed = { ...this.props.deed };

        deed.members[this.state.editPositionIndex] = editPosition;
		this.props.updateFormDeed(deed);
		this.setState({ editPositionIndex: -1, position: createEmptyBodyPosition() });
	};
}