import { DocumentTypeApi } from '../../../api/documentType/documentTypeApi';
import { actionTypes } from '../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import { DocumentTypeSubType } from '../../../model';

export const getDocumentTypeSubTypeAction = () => (dispatch) => {
	dispatch(httpCallStartAction());

	DocumentTypeApi.GetDocumentTypeSubType().then((result) => {
		dispatch(getDocumentTypeSubTypesCompleted(result));
	});
};

const getDocumentTypeSubTypesCompleted = (documentTypeSubtypes: DocumentTypeSubType[]) => ({
	type: actionTypes.GET_DOCUMENT_TYPE_SUBTYPES,
	payload: documentTypeSubtypes,
	meta: {
		httpEnd: true,
	},
});
