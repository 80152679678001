import { IComboBoxOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react/lib/components/ComboBox';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import * as React from 'react';
import { languages } from '../../../common/const/resources';
import { BaseType, BodyTypesCollection, Language } from '../../../model/';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { BodyTypesFilter, createEmptyBodyTypesFilter } from '../../../model/filters/BodyTypesFilter';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';

export interface IProps {
	filter: BodyTypesFilter;
	business: BaseType[];
	onchangeFilter: any;
	language: Language;
	itemsPage: number;
	getBodyTypes: (filter: BodyTypesFilter, pageNumber: number, itemsPage: number) => Promise<BodyTypesCollection>;
}

export interface IState {
	filter: BodyTypesFilter;
	collapsed: boolean,
}

export class BodyTypesSearchComponent extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			collapsed: false,
			filter: createEmptyBodyTypesFilter(),
		}
	}


	componentDidMount() {
		this.setState({ filter: this.props.filter });
	}

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let businessCombo: IComboBoxOption[] = [];
		businessCombo.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		businessCombo.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		this.props.business.forEach((item) => {
			businessCombo.push({
				key: item.id,
				text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish,
			});
		});

		return (
			<>
				<div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
					<div
						className={
							this.state.collapsed
								? 'ms-Grid-row filter_header-collapse'
								: 'ms-Grid-row filter_header'
						}
					>
						<div className='ms-Grid-col ms-xl6'>
							{this.state.collapsed
								? language['generic']['list']['filter']['collapsedTitle']
								: language['generic']['list']['filter']['btnTitle']}
						</div>
						<div className='ms-Grid-col ms-xl6'>
							<CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? 'none' : '' }}>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['body-types']['list']['filter']['options']['description-es']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.DescriptionSpanish}
									onChange={this.handleDescriptionSpanishChange}
									onBlur={this.handleDescriptionSpanishBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['body-types']['list']['filter']['options']['description-en']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.DescriptionEnglish}
									onChange={this.handleDescriptionEnglishChange}
									onBlur={this.handleDescriptionEnglishBlur}
								/>
							</div>
						</div>
						<hr />
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
								<button type='button' className='filter_button-clean' onClick={this.cleanFilterBodyTypes}>
									{language['generic']['list']['filter']['btnClean']}
								</button>
								<button type='button' className='filter_button-apply' onClick={this.filterBodyTypes}>
									{language['generic']['list']['filter']['btnApply']}
								</button>
							</div>
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? '' : 'none' }}>
						<div className='filter_collapse'>
							<p style={{ display: this.state.filter.DescriptionSpanish !== '' ? '' : 'none' }}>
								<span className='key'>{language['body-types']['list']['filter']['options']['description-es']['title']}</span>
								<span className='value'>{this.state.filter.DescriptionSpanish}</span>
							</p>
							<p style={{ display: this.state.filter.DescriptionEnglish !== '' ? '' : 'none' }}>
								<span className='key'>{language['body-types']['list']['filter']['options']['description-en']['title']}</span>
								<span className='value'>{this.state.filter.DescriptionEnglish}</span>
							</p>
						</div>
					</div>
				</div>
			</>
		);
	}

	public readonly handleDescriptionSpanishChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.DescriptionSpanish = value.target.value;
		this.setState({ filter: filter });
	};
	public readonly handleDescriptionSpanishBlur = (value): void => {
		let filter = { ...this.state.filter };
		filter.DescriptionSpanish = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	public readonly handleDescriptionEnglishChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.DescriptionEnglish = value.target.value;
		this.setState({ filter: filter });
	};
	public readonly handleDescriptionEnglishBlur = (value): void => {
		let filter = { ...this.state.filter };
		filter.DescriptionEnglish = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	private readonly filterBodyTypes = () => {
		let filter = { ...this.state.filter };
		this.setState({ collapsed: true });
		this.props.onchangeFilter(filter);
		this.props.getBodyTypes(filter, 1, this.props.itemsPage);
	};

	private readonly cleanFilterBodyTypes = () => {
		let resetFilter = createEmptyBodyTypesFilter();
		this.props.onchangeFilter(resetFilter);
		this.setState({ filter: resetFilter });
		this.props.getBodyTypes(resetFilter, 1, this.props.itemsPage);
	};
	private readonly handleFiltersCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};
}
