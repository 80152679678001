import * as React from 'react';
import { TextField } from 'office-ui-fabric-react';
import * as utils from '../../../../utils/extensions';
import { SocialCapital, Language } from '../../../../model';

interface IProps {
	socialCapital: SocialCapital;
	updateSocialCapital: (socialCapital: SocialCapital) => void;
	language: Language;
	isReadOnly: boolean;
}

export class SocialCapitalTab extends React.Component<IProps, {}> {
	public render() {
		let language = utils.getResourcesFile(this.props.language);
		return (
			<div>
				<div className='socialCapitalForm'>
					<div className='ms-Grid-row socialCapitalForm_inputs flex-option'>
						<div className='ms-Grid-col ms-md3 main-container__content_edit_block'>
							<p>{language['deeds']['form']['sections']['social-capital']['fields']['nominal']['title']}</p>
							<TextField
								type='text'
								className='main-container__content_edit_block-inner'
								value={parseInt(this.props.socialCapital.nominal) > 0 ? this.props.socialCapital.nominal : ''}
								onChange={this.handleNominalChange}
								disabled={this.props.isReadOnly}
								maxLength={11}
							/>
							<p>{language['deeds']['form']['sections']['social-capital']['fields']['outlay']['title']}</p>
							<TextField
								type='text'
								className='main-container__content_edit_block-inner'
								value={
									parseInt(this.props.socialCapital.disbursement) > 0
										? this.props.socialCapital.disbursement : ''
								}								
								onChange={this.handleDisbursmentChange}
								disabled={this.props.isReadOnly}
								maxLength={11}
							/>
							<p>{language['deeds']['form']['sections']['social-capital']['fields']['social-capital']['title']}</p>
							<TextField
								type='text'
								className='main-container__content_edit_block-inner'
								value={
									parseInt(this.props.socialCapital.initialSocialCapital) > 0
										? this.props.socialCapital.initialSocialCapital : ''
								}
								onChange={this.handleInitialSocialCapitalChange}
								disabled={this.props.isReadOnly}
								maxLength={11}
							/>
							<p>{language['deeds']['form']['sections']['social-capital']['fields']['titles']['title']}</p>
							<TextField
								type='text'
								className='main-container__content_edit_block-inner'
								value = {utils.isNullOrEmpty(this.props.socialCapital.securities) ? '' : this.props.socialCapital.securities}
								onChange={this.handleSecuritiesChange}
								disabled={this.props.isReadOnly}
								maxLength={50}
							/>
							<p>{language['deeds']['form']['sections']['social-capital']['fields']['participation']['title']}</p>
							<TextField
								type='text'
								className='main-container__content_edit_block-inner'
								value={
									this.props.socialCapital.totalNumberStock > 0 ? this.props.socialCapital.totalNumberStock.toString() : ''
								}
								onChange={this.handleTotalNumberStockChange}
								disabled={this.props.isReadOnly}
								maxLength={11}
							/>
						</div>
						<div className='ms-Grid-col ms-md3 main-container__content_edit_block'>
							<p>{language['deeds']['form']['sections']['social-capital']['fields']['last-numeration']['title']}</p>
							<TextField
								type='text'
								className='main-container__content_edit_block-inner'
								value={
									parseInt(this.props.socialCapital.lastNumbering) > 0
										? this.props.socialCapital.lastNumbering : ''
								}								
								onChange={this.handleLastNumberingChange}
								disabled={this.props.isReadOnly}
								maxLength={11}
							/>
							<p>{language['deeds']['form']['sections']['social-capital']['fields']['amortized-shares']['title']}</p>
							<TextField
								type='text'
								className='main-container__content_edit_block-inner'
								value={
									parseInt(this.props.socialCapital.amortizedStock) > 0
										? this.props.socialCapital.amortizedStock : ''
								}								
								onChange={this.handleAmortizedStockChange}
								disabled={this.props.isReadOnly}
								maxLength={11}
							/>
							<p>{language['deeds']['form']['sections']['social-capital']['fields']['issued-shares']['title']}</p>
							<TextField
								type='text'
								className='main-container__content_edit_block-inner'
								value={
									parseInt(this.props.socialCapital.issuedStock) > 0
										? this.props.socialCapital.issuedStock : ''
								}									
								onChange={this.handleIssuedStockChanged}
								disabled={this.props.isReadOnly}
								maxLength={11}
							/>
							<p>{language['deeds']['form']['sections']['social-capital']['fields']['amount']['title']}</p>
							<TextField
								type='text'
								className='main-container__content_edit_block-inner'
								value={parseInt(this.props.socialCapital.extendedAmountType) > 0 ?
									 this.props.socialCapital.extendedAmountType : ''}
								onChange={this.handleExtendedAmountTypeChanged}
								disabled={this.props.isReadOnly}
								maxLength={11}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private readonly handleNominalChange = (event) => {
		let socialCapital = { ...this.props.socialCapital };
		let value = event.target.value;
		socialCapital.nominal = utils.isNullOrEmpty(value) ? null : parseInt(value).toString();
		this.props.updateSocialCapital(socialCapital);
	};

	private readonly handleDisbursmentChange = (event) => {
		let socialCapital = { ...this.props.socialCapital };
		let value = event.target.value;
		if (parseInt(value) || utils.isNullOrEmpty(value)) {
			socialCapital.disbursement = utils.isNullOrEmpty(value) ? '' : parseInt(value).toString();
		}
		this.props.updateSocialCapital(socialCapital);
	};

	private readonly handleInitialSocialCapitalChange = (event) => {
		let socialCapital = { ...this.props.socialCapital };
		let value = event.target.value;
		socialCapital.initialSocialCapital = utils.isNullOrEmpty(value) ? null : parseInt(value).toString();
		this.props.updateSocialCapital(socialCapital);
	};

	private readonly handleSecuritiesChange = (event) => {
		let socialCapital = { ...this.props.socialCapital };
		socialCapital.securities = event.target.value;
		this.props.updateSocialCapital(socialCapital);
	};

	private readonly handleTotalNumberStockChange = (event) => {
		let socialCapital = { ...this.props.socialCapital };
		let value = event.target.value;
		socialCapital.totalNumberStock = utils.isNullOrEmpty(value) ? 0 : parseInt(value);
		this.props.updateSocialCapital(socialCapital);
	};

	private readonly handleLastNumberingChange = (event) => {
		let socialCapital = { ...this.props.socialCapital };
		let value = event.target.value;
		if (parseInt(value) || utils.isNullOrEmpty(value)) {
			socialCapital.lastNumbering = utils.isNullOrEmpty(value) ? '' : parseInt(value).toString();
		}
		this.props.updateSocialCapital(socialCapital);
	};

	private readonly handleAmortizedStockChange = (event) => {
		let socialCapital = { ...this.props.socialCapital };
		let value = event.target.value;
		if (parseInt(value) || utils.isNullOrEmpty(value)) {
			socialCapital.amortizedStock = utils.isNullOrEmpty(value) ? '' : parseInt(value).toString();
		}
		this.props.updateSocialCapital(socialCapital);
	};

	private readonly handleIssuedStockChanged = (event) => {
		let socialCapital = { ...this.props.socialCapital };
		let value = event.target.value;
		if (parseInt(value) || utils.isNullOrEmpty(value)) {
			socialCapital.issuedStock = utils.isNullOrEmpty(value) ? '' : parseInt(value).toString();
		}
		this.props.updateSocialCapital(socialCapital);
	};

	private readonly handleExtendedAmountTypeChanged = (event) => {
		let socialCapital = { ...this.props.socialCapital };
		let value = event.target.value;
		socialCapital.extendedAmountType = utils.isNullOrEmpty(value) ? null : parseInt(value).toString();
		this.props.updateSocialCapital(socialCapital);
	};
}
