import { ISelectOption } from '../../common/customSelect/customSelect';
import { ActivePositionOrderFilter } from './ActivePositionOrderFilter';
import { OrderBy } from './OrderBy';

export interface PositionFilter {
	CompanyNameOrder: OrderBy;
	NameOrder: OrderBy;
	DniOrder: OrderBy;
	CifOrder: OrderBy;
	PositionTypeNameOrder: OrderBy;
	CompaniesId: number[];
	CompaniesName: string[];
	Cif: string;
	Dni: string;
	Name: string;
	PersonId: number;
	Valid: number;
	PositionTypeId: number;
	PositionTypeName: string;
	StateId: number;
	State: string;
	CurrentFilter: ActivePositionOrderFilter;
	ItemsPage: number;
	TradeId: number;
	selectedCompanies: ISelectOption[]
}

export interface PositionDetailFilter {
	PersonId: number;
}

export const createEmptyPositionFilter = (): PositionFilter => {
	return {
		CompanyNameOrder: OrderBy.Asc,
		NameOrder: OrderBy.Asc,
		DniOrder: OrderBy.Asc,
		CifOrder: OrderBy.Asc,
		PositionTypeNameOrder: OrderBy.Asc,
		CompaniesId: [],
		CompaniesName: [],
		Cif: '',
		Dni: '',
		Name: '',
		Valid: 0,
		PositionTypeId: 0,
		PositionTypeName: '',
		StateId: 0,
		State: '',
		CurrentFilter: ActivePositionOrderFilter.NameOrder,
		ItemsPage: 10,
		PersonId: 0,
		TradeId: 0,
		selectedCompanies: []
	};
};

export const createEmptyPositionDetailFilter = (): PositionDetailFilter => {
	return {
		PersonId: 0,
	};
};
