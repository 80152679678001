import { actionTypes } from '../../../../common/const/actionTypes';
import { RecordCompaniesApi } from '../../../../api/recordCompanies';
import { RecordCompanyCustom } from '../../../../model/';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { RecordCompanyDetailFilter } from '../../../../model/filters';

export const getRecordCompanyAction = (filter: RecordCompanyDetailFilter) => (dispatch) => {
	dispatch(httpCallStartAction());

	RecordCompaniesApi.GetRecordCompany(filter).then((content) => {
		dispatch(getRecordCompanyCompleted(content));
	});
};

const getRecordCompanyCompleted = (recordCompany: RecordCompanyCustom) => ({
	type: actionTypes.GET_RECORD_COMPANY,
	payload: recordCompany,
	meta: {
		httpEnd: true,
	},
});
