import { actionTypes } from '../common/const/actionTypes';
import { BaseType, BaseTypeCollection, UntranslatedBaseType } from '../model/BaseType';

const createEmptyBaseTypeList = (): BaseTypeCollection => {
	return {
		list: [createBaseType()],
		totalNumberOfRows: 0,
	};
};

const createBaseType = (): BaseType => {
	return {
		descriptionEnglish: '',
		descriptionSpanish: '',
		id: 0,
	};
};

const createBaseTypeArray = (): BaseType[] => {
	return [];
};

const createUntranslatedBaseTypeArray = (): UntranslatedBaseType[] => {
	return [];
};

export const baseTypeListReducer = (state = createEmptyBaseTypeList(), action) => {
	switch (action.type) {
		case actionTypes.GET_POSITION_TYPES:
		case actionTypes.GET_POWER_TYPES:
		case actionTypes.GET_POWERS:
		case actionTypes.GET_AUTHORIZATION_STATUS:
			return handleGetBaseTypeListCompleted(state, action.payload);
	}

	return state;
};

export const baseTypeReducer = (state = createBaseType(), action) => {
	switch (action.type) {
		case actionTypes.GET_POSITION_TYPE:
		case actionTypes.GET_POWER_TYPE:
		case actionTypes.GET_POWER:
			return handleGetBaseTypeCompleted(state, action.payload);
	}

	return state;
};

export const baseTypeArrayReducer = (state = createBaseTypeArray(), action) => {
	switch (action.type) {
		case actionTypes.GET_POWER_NAMES:
			return handleGetBaseTypeArrayCompleted(state, action.payload);
	}

	return state;
};

export const notaryDocumentsReducer = (state = createUntranslatedBaseTypeArray(), action) => {
	switch (action.type) {
		case actionTypes.GET_NOTARY_DOCUMENTS:
			return handleGetUntranslatedBaseTypeArrayCompleted(state, action.payload);
	}

	return state;
};

export const bodyTypeNamesReducer = (state = createBaseTypeArray(), action) => {
	switch (action.type) {
		case actionTypes.GET_BODY_TYPE_NAMES:
			return handleGetBaseTypeArrayCompleted(state, action.payload);
	}

	return state;
};

export const positionTypeNamesReducer = (state = createBaseTypeArray(), action) => {
	switch (action.type) {
		case actionTypes.GET_POSITION_TYPE_NAMES:
			return handleGetBaseTypeArrayCompleted(state, action.payload);
	}

	return state;
};

export const powerTypeNamesReducer = (state = createBaseTypeArray(), action) => {
	switch (action.type) {
		case actionTypes.GET_POWER_TYPE_NAMES:
			return handleGetBaseTypeArrayCompleted(state, action.payload);
	}

	return state;
};

export const powerExerciseNamesReducer = (state = createBaseTypeArray(), action) => {
	switch (action.type) {
		case actionTypes.GET_POWER_EXERCISE_NAMES:
			return handleGetBaseTypeArrayCompleted(state, action.payload);
	}

	return state;
};

export const departmentsReducer = (state = createBaseTypeArray(), action) => {
	switch (action.type) {
		case actionTypes.GET_DEPARTMENTS:
			return handleGetBaseTypeArrayCompleted(state, action.payload);
	}

	return state;
};

export const authorizationStatusesNamesReducer = (state = createBaseTypeArray(), action) => {
	switch (action.type) {
		case actionTypes.GET_AUTHORIZATION_STATUS_NAMES:
			return handleGetBaseTypeArrayCompleted(state, action.payload);
	}
	return state;
};

export const businessNameListReducer = (state = createBaseTypeArray(), action) => {
	switch (action.type) {
		case actionTypes.GET_BUSINESS_NAMES:
			return handleGetBaseTypeArrayCompleted(state, action.payload);
	}

	return state;
};

export const originalsReducer = (state = createBaseTypeArray(), action) => {
	switch (action.type) {
		case actionTypes.GET_ORIGINALS:
			return handleGetBaseTypeArrayCompleted(state, action.payload);
	}
	return state;
};

export const baseTypeFormReducer = (state: number = 0, action) => {
	switch (action.type) {
		case actionTypes.SAVE_POWER_TYPE_COMPLETED:
		case actionTypes.SAVE_POWERTYPE_COMPLETED:
		case actionTypes.SAVE_POSITION_TYPE_COMPLETED:
			return handleSaveBaseTypeCompleted(state, action.payload);
		case actionTypes.DELETE_POSITION_TYPE:
		case actionTypes.DELETE_POWER_TYPE:
		case actionTypes.DELETE_POWER:
			return handleDeleteBaseTypeCompleted(state, action.payload);
	}

	return state;
};

const handleGetBaseTypeListCompleted = (state: BaseTypeCollection, payload: BaseTypeCollection) => {
	return payload;
};

const handleGetBaseTypeCompleted = (state: BaseType, payload: BaseType) => {
	return payload;
};

const handleGetBaseTypeArrayCompleted = (state: BaseType[], payload: BaseType[]) => {
	return payload;
};

const handleGetUntranslatedBaseTypeArrayCompleted = (state: UntranslatedBaseType[], payload: UntranslatedBaseType[]) => {
	return payload;
};

const handleDeleteBaseTypeCompleted = (state: number, payload: boolean) => {
	return payload;
};

const handleSaveBaseTypeCompleted = (state: number, payload: number) => {
	return payload;
};
