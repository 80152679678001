import { actionTypes } from '../common/const/actionTypes';
import { Country } from '../model/Country';

const createEmptyCountryList = (): Country[] => {
	return [];
};

export const countryReducer = (state = createEmptyCountryList(), action) => {
	switch (action.type) {
		case actionTypes.GET_COUNTRIES:
			return handleGetCountryListCompleted(state, action.payload);
	}

	return state;
};

const handleGetCountryListCompleted = (state: Country[], payload: Country[]) => {
	return payload;
};
