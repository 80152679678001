export enum Modules {
	Powers = 1,
	Authorized = 2,
	Deeds = 3,
	RecordCompanies = 4,
	Positions = 5,
	People = 6,
	Companies = 7,
	Departments = 8,
	DeedsTypes = 9,
	PositionsTypes = 10,
	BodyTypes = 11,
	Documentation = 12,
}
