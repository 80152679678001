import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import { state, StateReducer } from './reducers';
import { createLogger } from 'redux-logger';
import { httpMiddleware } from './common/middleware/http/middleware';


let middlewares = [reduxThunk, httpMiddleware];

var env = process.env.REACT_APP_ENVIRONMENT

if (env === 'LOCAL' || env === 'INT') {
	middlewares = [...middlewares, createLogger()];
}

export const store: Store<StateReducer> = createStore(state, composeWithDevTools(applyMiddleware(...middlewares)));
