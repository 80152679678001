import * as React from 'react';
import * as utils from '../../utils/extensions';
import AsyncSelect from 'react-select/async';
import { Language, SearchSelectModel } from '../../model';
import { IconButton } from 'office-ui-fabric-react/lib/Button';

export interface IProps {
	language: Language;
	selectedOption: SearchSelectModel;
	title: string;
	placeholder: string;
	disabled?: boolean;
	getCompaniesSearch: (value: string) => any;
	handleNameChange: (selectedOption: SearchSelectModel) => any;
	loadOptions: (inputValue: any, callback: any) => void;
	onClearValue: () => any;
	showDeleteButton?: boolean;
}

export class SearchCompany extends React.Component<IProps, {}> {
	/* constructor(props) {
		super(props);
	}		Clean Warning Code */

	public render() {
		let language = utils.getResourcesFile(this.props.language);
		return (
			<>
				<p>{this.props.title}</p>
				<div className='searchPeopleWithDelete'>
					<div className='search-select'>
						<i className={'ms-Icon ms-Icon--Search search-icon'} aria-hidden='true'></i>
						<AsyncSelect
							noOptionsMessage={() => language['generic']['list']['filter']['notOptions']}
							loadingMessage={() => language['generic']['list']['filter']['loadingText']}
							className={'search-person'}
							cacheOptions
							loadOptions={this.props.loadOptions}
							value={this.props.selectedOption}
							onInputChange={this.handleInputChange}
							onChange={this.props.handleNameChange}
							placeholder={this.props.placeholder}
							isDisabled={this.props.disabled}
						/>
					</div>
					{this.props.showDeleteButton ? (
						<IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => this.props.onClearValue()} />
					) : null}						
				</div>
			</>
		);
	}
	private readonly handleInputChange = (newValue: string) => {
		const inputValue = newValue
			.trimStart()
		if (inputValue.length >= 4) {
			this.props.getCompaniesSearch(inputValue);
		}
		return inputValue;
	};
}
