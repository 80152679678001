import { actionTypes } from '../../../../common/const/actionTypes';
import { Person } from '../../../../model/Person';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { GenericApi } from '../../../../api/generic';
import { ApiUrls } from '../../../../common/const/api';

export const getPersonAction = (id: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	GenericApi.GetByIdAsync<Person>(ApiUrls.People, id).then((result) => {
		dispatch(getPersonCompleted(result));
	});
};

const getPersonCompleted = (result: Person) => ({
	type: actionTypes.GET_PERSON,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
