import { connect } from 'react-redux';
import { StateReducer } from '../../reducers';
import { AuthorizationsAccountPage } from './authorizationsHome';
import { getCompaniesNamesAction, getTradeCompaniesAction } from './actions/getCompanies';
import { AuthorizationFilter } from '../../model/filters';
import { getAuthorizationsAction } from './actions/getAuthorizations';
import { setAuthorizarionFilterAction } from './actions/setAuthorizationFilter';
import { getBusinessNamesAction } from './actions/getBusiness';
import { getAuthorizationStatusNamesAction } from './actions/getAuthorizedStatuses';
import { getAuhorizationUsersAction } from './actions/getAuthorizationUsers';
import {exportToExcelAction} from './actions/exportToExcel';
import { Modules } from '../../model/enums';

const moduleId = Modules.Authorized;

const mapStateToProps = (state: StateReducer) => ({
	companies: state.companyNames,
	tradeCompanies: state.tradeCompanies,
	authorizationsCollection: state.authorizations,
	user: state.user,
	language: state.language,
	csvDownloadLink: state.csvDownloadLink,
	business: state.businessNames,
	authorizationStatusesNames: state.authorizationStatusesNames,
	authorizationsUsers: state.authorizationsUsers,
	currentUser: state.users
});

const mapDispatchToProps = (dispatch: any) => ({
	getAuthorizations: (filter: AuthorizationFilter, pageNumber: number, itemsPage: number) => dispatch(getAuthorizationsAction(filter, pageNumber, itemsPage)),
	getCompanies: () => dispatch(getCompaniesNamesAction(moduleId)),
	getTradeCompanies: (tradeId?: number) => dispatch(getTradeCompaniesAction(moduleId, tradeId)),
	getBusiness: () => dispatch(getBusinessNamesAction()),
	getAuthorizationsUsers: (filter: string) => dispatch(getAuhorizationUsersAction(filter)),
	getAuthorizationStatusesNames: () => dispatch(getAuthorizationStatusNamesAction()),
	setAuthorizationFilter: (filter: AuthorizationFilter) => dispatch(setAuthorizarionFilterAction(filter)),
	exportToExcel: (filter: AuthorizationFilter) => dispatch(exportToExcelAction(filter))
});

export const AuthorizationsListPageContainer = connect(mapStateToProps, mapDispatchToProps)(AuthorizationsAccountPage);
