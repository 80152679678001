import * as React from 'react';
import { BaseOptionModel } from '../../model/inputSelectModel';

interface Props {
  onChange: (event: any, value: any) => void;
  options: BaseOptionModel[];
  value: string | number;
  className?: string;
  disabled?: boolean;
}

export class ButtonGroupComponent extends React.Component<Props> {

  change = (event: any, key: any) => {
    this.props.onChange(event.target.value, key);
  };

  public render() {
    return (
      <div className= {`${this.props.className} ${!this.props.disabled ? '' : 'isDisabled'} `} >
        {this.props.options.map((option, i) => (
          <button
            key={option.value}
            onClick={(event) => this.change(event, option.value)}
            className={option.value === this.props.value ? "buttonGroup active" : "buttonGroup"}
          >
            {option.label}
          </button>
        ))}
      </div>
    );
  }
}