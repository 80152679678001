import { actionTypes } from '../../../../common/const/actionTypes';
import { RepresentativeApi } from '../../../../api/representatives';
import { RepresentativeDetailFilter } from '../../../../model/filters/RepresentativeFilter';
import { RepresentativeDetailCollection } from '../../../../model/Representative';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';

export const getRepresentativePageAction = (filter: RepresentativeDetailFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
	dispatch(httpCallStartAction());

	RepresentativeApi.FilterPageAsync(filter, pageNumber, itemsPage).then((content) => {
		dispatch(getRepresentativesCompleted(content));
	});
};
const getRepresentativesCompleted = (representativeCollection: RepresentativeDetailCollection) => ({
	type: actionTypes.GET_REPRESENTATIVE,
	payload: representativeCollection,
	meta: {
		httpEnd: true,
	},
});
