import { GenericApi } from '../../../api/generic';
import { actionTypes } from '../../../common/const/actionTypes';
import { ApiUrls } from '../../../common/const/api';
import { httpCallStartAction } from '../../../common/middleware/http/actions';
import useHistory from '../../../history';
import { DocumentType, Language } from '../../../model';
import { getResourcesFile, showError, showSuccess } from '../../../utils/extensions';
const browserHistory = useHistory;

export const saveDocumentTypeAction = (documentType: DocumentType, currentLanguage: Language) => (dispatch) => {
	dispatch(httpCallStartAction());
	let language = getResourcesFile(currentLanguage);
	if (documentType.id > 0) {
		handleUpdateDocumentType(documentType, language, dispatch);
	} else {
		handleCreateDocumentType(documentType, language, dispatch);
	}
};

const handleUpdateDocumentType = (documentType: DocumentType, language: any, dispatch) => {
	GenericApi.UpdateAsync(ApiUrls.DocumentTypes, documentType).then((result: any) => {
		if (result.error) {
			showError(language['documentType']['messages']['error-update'] + ' ' + language['generic']['error'][result.error]);
			dispatch(saveDocumentTypeCompleted(result));
		} else {
			showSuccess(language['documentType']['messages']['success-update']);
			browserHistory.goBack();
			dispatch(saveDocumentTypeCompleted(result));
		}
	});
};

const handleCreateDocumentType = (documentType: DocumentType, language: any, dispatch) => {
	GenericApi.AddAsync(ApiUrls.DocumentTypes, documentType).then((result: any) => {
		if (result > 0) {
			showSuccess(language['documentType']['messages']['success']);
			browserHistory.goBack();
			dispatch(saveDocumentTypeCompleted(result));
		} else {
			showError(language['documentType']['messages']['error'] + ' ' + language['generic']['error'][result.error]);
			dispatch(saveDocumentTypeCompleted(result));
		}
	});
};

const saveDocumentTypeCompleted = (result: number) => ({
	type: actionTypes.SAVE_DOCUMENT_TYPE_COMPLETED,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
