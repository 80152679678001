export enum ActiveRepresentativeOrderFilter {
	Name = 1,
	Dni = 2,
	CompanyId = 3,
	Protocol = 4,
	Inscription = 5,
	State = 6,
	PowerType = 7,
	Type = 8,
	Subject = 9,
	Date = 10
}
