import { OrderBy } from './OrderBy';
import { ActiveDeedFilter } from './ActiveDeedFilter';
import { ISelectOption } from '../../common/customSelect/customSelect';

export interface DeedFilter {
	CompanyNameOrder: OrderBy;
	DeedTypeOrder: OrderBy;
	SubtypeDeedNameOrder: OrderBy;
	DocumentDateOrder: OrderBy;
	NotaryOrder: OrderBy;
	InscriptionOrder: OrderBy;
	ProtocolOrder: OrderBy;

	CompaniesId: number[];
	CompaniesName?: string[];
	DeedTypeId: number;
	DeedTypeName?: string;
	DeedSubtypeId: number;
	DeedSubtypeName?: string;
	AffectsCapital?: number;
	DocumentFromDate: Date;
	DocumentToDate: Date;
	NotaryPeopleId?: number;
	NotaryPeopleName: string;
	NotaryName: string;
	ProtocolNumber: string;
	Inscription: string;
	InscriptionFromDate: Date;
	InscriptionToDate: Date;
	Subject: string;
	UniqueCode?: string;
	TradeId: number;
	Trade: string;
	OriginalId: number;
	OriginalName: string;
	Copy: string;
	Simple: string;
	LocationDeed: string;

	CurrentFilter: ActiveDeedFilter;

	ItemsPage: number;

	selectedCompanies: ISelectOption[];
}

export const createEmptyDeedFilter = (): DeedFilter => {
	return {
		CurrentFilter: ActiveDeedFilter.Company,
		CompanyNameOrder: OrderBy.Asc,
		SubtypeDeedNameOrder: OrderBy.Asc,
		DeedTypeOrder: OrderBy.Asc,
		DocumentDateOrder: OrderBy.Asc,
		ProtocolOrder: OrderBy.Asc,
		InscriptionOrder: OrderBy.Asc,
		NotaryOrder: OrderBy.Asc,
		CompaniesId: [],
		CompaniesName: [],
		DeedSubtypeId: 0,
		DeedTypeId: 0,
		DocumentFromDate: null,
		DocumentToDate: null,
		Inscription: '',
		NotaryName: '',
		ProtocolNumber: '',
		InscriptionFromDate: null,
		InscriptionToDate: null,
		Subject: '',
		AffectsCapital: -1,
		ItemsPage: 10,
		UniqueCode: '',
		Trade: '',
		TradeId: 0,
		OriginalId: 0,
		OriginalName: '',
		Copy: '',
		Simple: '',
		LocationDeed: '',
		NotaryPeopleId: null,
		NotaryPeopleName: '',
		selectedCompanies: []
	};
};
