import { actionTypes } from '../../../../common/const/actionTypes';
import { RepresentativeDetailFilter } from '../../../../model/filters/RepresentativeFilter';

export const setRepresentativeDetailFilterAction = (filter: RepresentativeDetailFilter) => (dispatch) => {
	dispatch(setRepresentativeFilterActionCompleted(filter));
};
const setRepresentativeFilterActionCompleted = (representativeFilter: RepresentativeDetailFilter) => ({
	type: actionTypes.SET_REPRESENTATIVE_DETAIL_FILTER,
	payload: representativeFilter,
});
