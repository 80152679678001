import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { authHome } from '../../common/const/rolesAssignments';
import { Language } from '../../model/';
import { ArchiveManagementComponent } from '../archivemanagement/list/page';
import { MasterManagementComponent } from '../masterManagement/list/page';
import * as utils from '../../utils/extensions';

export interface IProps {
	language: Language;
	cleanFilter: () => Promise<[]>;
}

export class Home extends React.Component<RouteComponentProps<{}> & IProps, {}> {
	public componentDidMount() {
		let isAuth = utils.isInRoles(authHome);
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});
		this.props.cleanFilter();
	}

	public render() {
		return (
			<div className='ms-Grid'>
				<ArchiveManagementComponent language={this.props.language} />
				<MasterManagementComponent language={this.props.language} />
			</div>
		);
	}
}
