import { mergeStyles, mergeStyleSets } from "office-ui-fabric-react/lib/Styling";

export const Constants = {
	ItemsPerPage: 10,
	PaginationLinks: 10,
	UrlSharePoint: process.env.REACT_APP_SHAREPOINT,
	ConstitutionDeed: "CONSTITUTION",
	AmendmentOfByLawsDeed: "AMENDMENT OF BYLAWS",
	MaxSizeFile: 10485760,
	MinSizeFile: 0
};

export const iconClass = mergeStyles({
	fontSize: 24,
	height: 27,
    width: 27,
    justifyContent: 'center',
    display: 'inline-flex !important'
});

export const classNames = mergeStyleSets({
	homeIconPrimary: [{ color: '#EBB700' }, iconClass],
});
