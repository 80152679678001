import { connect } from 'react-redux';
import { StateReducer } from '../../reducers';
import { PositionTypesHome } from './positionTypesHome';
import { Language, PositionType } from '../../model';
import {
	deletePositionTypeAction,
	exportToExcelAction,
	getBusinessNamesAction,
	getPositionTypeAction,
	getPositionTypesCollectionAction,
	savePositionTypeAction,
	setPositionTypesFilterAction,
} from './actions';
import { PositionTypeFilter } from '../../model/filters';

const mapStateToProps = (state: StateReducer) => ({
	language: state.language,
	user: state.user,
	positionType: state.positionType,
	positionTypeCollection: state.positionTypeCollection,
	positionTypeFilter: state.positionTypeFilter,
	positionTypeSave: state.positionTypeResponse,
	positionTypeDelete: state.positionTypeResponse,
	business: state.businessNames,
	currentUser: state.users,
	filter: state.positionTypeFilter,
});

const mapDispatchToProps = (dispatch: any) => ({
	deletePositionType: (filter: PositionTypeFilter, language: Language, id: number) =>
		dispatch(deletePositionTypeAction(filter, language, id)),
	exportToExcel: (filter: PositionTypeFilter) => dispatch(exportToExcelAction(filter)),
	getPositionType: (id: number) => dispatch(getPositionTypeAction(id)),
	getPositionTypeCollection: (filter: PositionTypeFilter, pageNumber: number, itemsPage: number) =>
		dispatch(getPositionTypesCollectionAction(filter, pageNumber, itemsPage)),
	setPositiontypesFilter: (filter: PositionTypeFilter) => dispatch(setPositionTypesFilterAction(filter)),
	savePositionType: (positionType: PositionType, language: Language) => dispatch(savePositionTypeAction(positionType, language)),
	getBusiness: () => dispatch(getBusinessNamesAction()),
});

export const PositionTypesContainer = connect(mapStateToProps, mapDispatchToProps)(PositionTypesHome);
