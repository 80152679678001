import { GenericApi } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import useHistory from '../../../../history';
import { Language, NotaryPeople } from '../../../../model';
import { getResourcesFile, showError, showSuccess } from '../../../../utils/extensions';
const browserHistory = useHistory;


export const saveNotaryPeopleAction = (notary: NotaryPeople, currentLanguage: Language) => (dispatch) => {
    let language = getResourcesFile(currentLanguage);
    dispatch(httpCallStartAction());
    if (notary.id > 0) {
        handleUpdateNotaryPeople(notary, language, dispatch);
    } else {
        handleCreateNotaryPeople(notary, language, dispatch);
    }
};

const handleUpdateNotaryPeople = (notary: NotaryPeople, language: any, dispatch) => {
    GenericApi.UpdateAsync(ApiUrls.NotaryPeople, notary).then((result: any) => {
        if (result.error) {
            showError(language['notaryPeople']['form']['messages']['error-update'] + language['generic']['error'][result.error]);
            dispatch(saveNotaryPeopleCompleted(result));
        } else {
            showSuccess(language['notaryPeople']['form']['messages']['success-update']);
            browserHistory.goBack();
            dispatch(saveNotaryPeopleCompleted(result));
        }
    });
}

const handleCreateNotaryPeople = (notary: NotaryPeople, language: any, dispatch) => {
    GenericApi.AddAsync(ApiUrls.NotaryPeople, notary).then((result: any) => {
        if (result > 0) {
            showSuccess(language['notaryPeople']['form']['messages']['success']);
            browserHistory.goBack();
            dispatch(saveNotaryPeopleCompleted(result));

        } else {
            showError(language['notaryPeople']['form']['messages']['error'] + language['generic']['error'][result.error]);
            dispatch(saveNotaryPeopleCompleted(result));
        }
    });
}

const saveNotaryPeopleCompleted = (result: number) => ({
    type: actionTypes.SAVE_NOTARYPERSON_COMPLETED,
    payload: result,
    meta: {
        httpEnd: true,
    }
});