import { ActiveBodyTypesFilter } from './ActiveBodyTypesFilter';
import { OrderBy } from './OrderBy';

export interface BodyTypesFilter {
	DescriptionSpanishOrder: OrderBy;
	DescriptionEnglishOrder: OrderBy;
	DescriptionSpanish: string;
	DescriptionEnglish: string;
	CurrentFilter: ActiveBodyTypesFilter;
	ItemsPage: number;
}

export const createEmptyBodyTypesFilter = (): BodyTypesFilter => {
	return {
		CurrentFilter: ActiveBodyTypesFilter.DescriptionSpanish,
		DescriptionSpanishOrder: OrderBy.Asc,
		DescriptionEnglishOrder: OrderBy.Asc,
		DescriptionEnglish: '',
		DescriptionSpanish: '',
		ItemsPage: 10,
	};
};
