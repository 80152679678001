import { actionTypes } from '../common/const/actionTypes';
import { BaseName, BaseNameList } from '../model/Base';

const createEmptyBaseNameList = (): BaseName[] => {
	return [];
};

const createEmptyBaseNameListCompanies = (): BaseNameList => {
	return { list: [] };
}

export const baseNameListReducer = (state = createEmptyBaseNameList(), action) => {
	switch (action.type) {
		case actionTypes.GET_COMPANY_NAMES:
			return handleGetBaseNameListCompleted(state, action.payload);
	}

	return state;
};

export const currencyReducer = (state = createEmptyBaseNameList(), action) => {
	switch (action.type) {
		case actionTypes.GET_CURRENCIES:
			return handleGetBaseNameListCompleted(state, action.payload);
	}

	return state;
};

export const companiesByPersonReducer = (state = createEmptyBaseNameListCompanies(), action) => {
	switch (action.type) {
		case actionTypes.GET_COMPANIES_BY_PERSON:
			return handleGetBaseNameCompaniesListCompleted(state, action.payload);
	}

	return state;
};

const handleGetBaseNameListCompleted = (state: BaseName[], payload: BaseName[]) => {
	return payload;
};


const handleGetBaseNameCompaniesListCompleted = (state: BaseNameList, payload: BaseNameList) => {
	return payload;
};
