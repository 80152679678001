export enum ActiveDocumentationOrderFilter {
	Company = 1,
	DocTypeSpanish = 2,
	DocTypeEnglish = 3,
	DocSubtypeSpanish = 4,
	DocSubtypeEnglish = 5,
	Date = 6,
	Copy = 7,
	CountrySpanish = 8,
	CountryEnglish = 9,
	Project = 10,
	Remark = 11,
	Comments = 12,
}
