import { userRoles } from '../../utils/enums/userRolesEnum';

export const authHome: string[] = [
	userRoles.adminMaster,
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.tesoAeropuertos,
	userRoles.tesoCintra,
	userRoles.tesoConstruccion,
	userRoles.tesoCorporacion,
	userRoles.ajFeim,
	userRoles.ajFeimH
];
export const allMasterModule: string[] = [
	userRoles.adminMaster,
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];


export const allEntityModule: string[] = [
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.tesoAeropuertos,
	userRoles.tesoCintra,
	userRoles.tesoConstruccion,
	userRoles.tesoCorporacion,
	userRoles.ajFeim,
	userRoles.ajFeimH
];

export const onlyDeedsModule: string[] = [
	userRoles.adminMaster,
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];

export const onlyFinanceRolesType: string[] = [
	userRoles.tesoAeropuertos,
	userRoles.tesoConstruccion,
	userRoles.tesoCintra,
	userRoles.tesoCorporacion
];

export const onlyAuthorizationModule: string[] = [
	userRoles.admin,
	userRoles.tesoAeropuertos,
	userRoles.tesoCintra,
	userRoles.tesoConstruccion,
	userRoles.tesoCorporacion,
];

export const onlyDocumentationModule: string[] = [
	userRoles.adminMaster,
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];

export const onlySocietalSheetModule: string[] = [
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];

export const onlyPositionsSheetModule: string[] = [
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];

export const onlyRecordCompaniesModule: string[] = [
	userRoles.admin,
	userRoles.tesoAeropuertos,
	userRoles.tesoCintra,
	userRoles.tesoConstruccion,
	userRoles.tesoCorporacion,
];

export const onlyCompaniesModule: string[] = [
	userRoles.adminMaster,
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];

export const onlyDepartmentsModule: string[] = [
	userRoles.adminMaster,
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];
export const onlyDeedTypeModule: string[] = [
	userRoles.adminMaster,
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];

export const onlyPeopleModule: string[] = [
	userRoles.adminMaster,
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];

export const onlyBodyTypesModule: string[] = [
	userRoles.adminMaster,
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];

export const onlyPositionTypesModule: string[] = [
	userRoles.adminMaster,
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];

export const onlyDocumentTypesModule: string[] = [
	userRoles.adminMaster,
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];


export const onlyNetherlands: string[] = [
	userRoles.ajAeropuertosH,
	userRoles.ajCintraH,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacionH,
	userRoles.ajFeimH
];

export const powersAllowWrite: string[] = [
	userRoles.admin,
	userRoles.ajAeropuertos,
	userRoles.ajCintra,	
	userRoles.ajConstruccion,
	userRoles.ajCorporacion,
	userRoles.ajFeim,
];

export const aJNoCoporacion: string[] = [
	userRoles.ajAeropuertos,
	userRoles.ajAeropuertosH,
	userRoles.ajCintra,	
	userRoles.ajCintraH,	
	userRoles.ajConstruccion,
	userRoles.ajConstruccionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];

export const isAj : string[] = [
	userRoles.ajAeropuertos,
	userRoles.ajAeropuertosH,
	userRoles.ajCintra,	
	userRoles.ajCintraH,	
	userRoles.ajConstruccion,
	userRoles.ajConstruccionH,
	userRoles.ajCorporacion,
	userRoles.ajCorporacionH,
	userRoles.ajFeim,
	userRoles.ajFeimH
];