import * as React from 'react';
import * as utils from '../../../../utils/extensions';
import { DatePicker, DayOfWeek, IDatePickerStrings, TextField } from 'office-ui-fabric-react';
import { Language, RecordData } from '../../../../model';
import { getResourcesFile } from '../../../../utils/extensions';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { ActiveLanguage } from '../../../../model/enums/activeLanguage';
import { languages } from '../../../../common/const/resources';

interface IProps {
	recordData: RecordData;
	updateRecordData: (recordData: RecordData) => void;
	language: Language;
	isReadOnly: boolean;
}

export class RegistryDataTab extends React.Component<IProps, {}> {
	public render() {
		let language = getResourcesFile(this.props.language);
		let calendarStrings: IDatePickerStrings = language['calendar'];
		return (
			<div className='recordForm'>
				<div className='ms-Grid-row recordForm_inputs flex-option'>
					<div className='ms-Grid-col ms-xl3 main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['registral-data']['fields']['commercial-register']['title']}</p>
						<TextField
							type='text'
							className=' content_edit_block_filter-inner'
							value = {utils.isNullOrEmpty(this.props.recordData?.merchandRecord) ? '' : this.props.recordData?.merchandRecord}
							onChange={this.handleCommercialRegistryChange}
							onBlur={this.handleCommercialRegistryBlur}
							disabled={this.props.isReadOnly}
							maxLength={250}
						/>
						<p>{language['deeds']['form']['sections']['registral-data']['fields']['volume']['title']}</p>
						<TextField
							type='text'
							className=' content_edit_block_filter-inner'
							value = {utils.isNullOrEmpty(this.props.recordData?.volume) ? '' : this.props.recordData?.volume}
							onChange={this.handleVolumeChange}
							onBlur={this.handleVolumeBlur}
							disabled={this.props.isReadOnly}
							maxLength={150}
						/>
						<p>{language['deeds']['form']['sections']['registral-data']['fields']['section']['title']}</p>
						<TextField
							type='text'
							className=' content_edit_block_filter-inner'
							value = {utils.isNullOrEmpty(this.props.recordData?.section) ? '' : this.props.recordData?.section}
							onChange={this.handleSectionChange}
							onBlur={this.handleSectionBlur}
							disabled={this.props.isReadOnly}
							maxLength={150}
						/>
						<p>{language['deeds']['form']['sections']['registral-data']['fields']['page']['title']}</p>
						<TextField
							type='text'
							className=' content_edit_block_filter-inner'
							value = {utils.isNullOrEmpty(this.props.recordData?.folio) ? '' : this.props.recordData?.folio}
							onChange={this.handleInvoiceChange}
							onBlur={this.handleInvoiceBlur}
							disabled={this.props.isReadOnly}
							maxLength={50}
						/>
					</div>
					<div className='ms-Grid-col ms-xl3 main-container__content_edit_block'>
						<p>{language['deeds']['form']['sections']['registral-data']['fields']['sheet']['title']}</p>
						<TextField
							type='text'
							className=' content_edit_block_filter-inner'
							value = {utils.isNullOrEmpty(this.props.recordData?.sheet) ? '' : this.props.recordData?.sheet}
							onChange={this.handleSheetChange}
							onBlur={this.handleSheetBlur}
							disabled={this.props.isReadOnly}
							maxLength={50}
						/>
						<p>{language['deeds']['form']['sections']['registral-data']['fields']['inscription']['title']}</p>
						<TextField
							type='text'
							className=' content_edit_block_filter-inner'
							value = {utils.isNullOrEmpty(this.props.recordData?.inscription) ? '' : this.props.recordData?.inscription}
							onChange={this.handleInscriptionChange}
							onBlur={this.handleInscriptionBlur}
							disabled={this.props.isReadOnly}
							maxLength={250}
						/>
						<p>{language['deeds']['form']['sections']['registral-data']['fields']['inscription-date']['title']}</p>
						<div className='range-picker datepickerContainer'>
							<DatePicker
								formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
								className={'full-width'}
								allowTextInput={true}
								parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
								firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
								placeholder={language['generic']['date']}
								ariaLabel={language['deeds']['form']['sections']['registral-data']['fields']['inscription-date']['title']}
								strings={calendarStrings}
								value={utils.getParsedDate(this.props.recordData?.inscriptionDate)}
								onSelectDate={this.handleInscriptionDateChange}
								disabled={this.props.isReadOnly}
							/>
							<IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => this.onClearDate()} disabled={this.props.isReadOnly} />
						</div>
					</div>
				</div>
			</div>
		);
	}

	private readonly handleCommercialRegistryChange = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.merchandRecord = event.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleCommercialRegistryBlur = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.merchandRecord = event.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleVolumeChange = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.volume = event.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleVolumeBlur = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.volume = event.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleSectionChange = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.section = event.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleSectionBlur = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.section = event.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleInvoiceChange = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.folio = event.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleInvoiceBlur = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.folio = event.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleSheetChange = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.sheet = event.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleSheetBlur = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.sheet = event.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleInscriptionChange = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.inscription = event.target.value;
		this.props.updateRecordData(recordData);
	};
	private readonly handleInscriptionBlur = (event) => {
		let recordData = { ...this.props.recordData };
		recordData.inscription = event.target.value.toUpperCase();
		this.props.updateRecordData(recordData);
	};

	private readonly handleInscriptionDateChange = (event): void => {
		let recordData = { ...this.props.recordData };
		recordData.inscriptionDate = event;
		this.props.updateRecordData(recordData);
	};

	private readonly onClearDate = (): void => {
		this.handleInscriptionDateChange(null);
    };	
}
