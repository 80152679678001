import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routeUrls } from './common/const/routes';
import { ArchiveManagementPageContainer } from './components/archivemanagement/pageContainer';
import { AuthorizationsListPageContainer } from './components/authorizations/authorizationsContainer';
import { BodyTypesFormContainer } from './components/bodyTypes/form/pageContainer';
import { BodyTypesPageContainer } from './components/bodyTypes/list/pageContainer';
import { CompaniesListPageContainer } from './components/companies/list/pageContainer';
import { DeedsFormContainer } from './components/deeds/form/pageContainer';
import { DeedsListPageContainer } from './components/deeds/list/pageContainer';
import { DeedTypeContainer } from './components/deedType/deedTypeContainer';
import { DeedTypesContainer } from './components/deedType/deedTypesContainer';
import { departmentContainer } from './components/departments/departmentContainer';
import { DepartmentsContainer } from './components/departments/departmentsContainer';
import { DocumentTypeContainer } from './components/documentTypes/documentTypeContainer';
import { DocumentTypesContainer } from './components/documentTypes/documentTypesContainer';
import { HomePageContainer } from './components/home/pageContainer';
import { LayoutContainer } from './components/layoutContainer';
import { MasterManagementPageContainer } from './components/masterManagement/pageContainer';
import { PersonFormContainer } from './components/people/form/pageContainer';
import { PeoplePageContainer } from './components/people/list/pageContainer';
import { NotaryPeopleFormContainer } from './components/notaryPeople/form/pageContainer';
import { NotaryPeoplePageContainer } from './components/notaryPeople/list/pageContainer';
import { PositionDetailContainer } from './components/positions/detail/pageContainer';
import { PositionsPageContainer } from './components/positions/list/pageContainer';
import { PositionTypeContainer } from './components/positionTypes/positionTypeContainer';
import { PositionTypesContainer } from './components/positionTypes/positionTypesContainer';
import { RecordCompanyDetailContainer } from './components/recordCompanies/detail/pageContainer';
import { RecordCompaniesPageContainer } from './components/recordCompanies/list/pageContainer';
import { RepresentativeDetailContainer } from './components/representatives/detail/pageContainer';
import { RepresentativeFormContainer } from './components/representatives/form/pageContainer';
import { RepresentativesPageContainer } from './components/representatives/list/pageContainer';
import { DocumentationFormContainer } from './components/documentation/form/pageContainer';
import { DocumentationPageContainer } from './components/documentation/list/pageContainer';

export const routes = (
	<Switch>
		<LayoutContainer>
			<Route exact path={routeUrls.home} strict={false} component={HomePageContainer} />
			<Route exact path={routeUrls.library} strict={false} component={MasterManagementPageContainer} />
			<Route exact path={routeUrls.archiveManagement} strict={false} component={ArchiveManagementPageContainer} />

			<Route exact path={routeUrls.companies} strict={false} component={CompaniesListPageContainer} />
			<Route exact path={routeUrls.companiesPage} strict={false} component={CompaniesListPageContainer} />

			<Route exact path={routeUrls.deeds} strict={false} component={DeedsListPageContainer} />
			<Route exact path={routeUrls.deedsNew} strict={false} component={DeedsFormContainer} />
			<Route exact path={routeUrls.deedsEdit} strict={false} component={DeedsFormContainer} />
			<Route exact path={routeUrls.deedsView} strict={false} component={DeedsFormContainer} />
			<Route exact path={routeUrls.deedsPage} strict={false} component={DeedsListPageContainer} />

			<Route exact path={routeUrls.deedTypes} strict={false} component={DeedTypesContainer} />
			<Route exact path={routeUrls.deedTypesNew} strict={false} component={DeedTypeContainer} />
			<Route exact path={routeUrls.deedTypesEdit} strict={false} component={DeedTypeContainer} />
			<Route exact path={routeUrls.deedTypesPage} strict={false} component={DeedTypesContainer} />

			<Route exact path={routeUrls.people} strict={false} component={PeoplePageContainer} />
			<Route exact path={routeUrls.peopleNew} strict={false} component={PersonFormContainer} />
			<Route exact path={routeUrls.peopleEdit} strict={false} component={PersonFormContainer} />
			<Route exact path={routeUrls.peoplePage} strict={false} component={PeoplePageContainer} />

			<Route exact path={routeUrls.notaryPeople} strict={false} component={NotaryPeoplePageContainer} />
			<Route exact path={routeUrls.notaryPeopleNew} strict={false} component={NotaryPeopleFormContainer} />
			<Route exact path={routeUrls.notaryPeopleEdit} strict={false} component={NotaryPeopleFormContainer} />
			<Route exact path={routeUrls.notaryPeoplePage} strict={false} component={NotaryPeoplePageContainer} />			

			<Route exact path={routeUrls.bodyTypes} strict={false} component={BodyTypesPageContainer} />
			<Route exact path={routeUrls.bodyTypesNew} strict={false} component={BodyTypesFormContainer} />
			<Route exact path={routeUrls.bodyTypesEdit} strict={false} component={BodyTypesFormContainer} />
			<Route exact path={routeUrls.bodyTypesPage} strict={false} component={BodyTypesPageContainer} />

			<Route exact path={routeUrls.positions} strict={false} component={PositionsPageContainer} />
			<Route exact path={routeUrls.positionsDetail} strict={false} component={PositionDetailContainer} />
			<Route exact path={routeUrls.positionsPage} strict={false} component={PositionsPageContainer} />

			<Route exact path={routeUrls.recordCompanies} strict={false} component={RecordCompaniesPageContainer} />
			<Route exact path={routeUrls.recordCompaniesDetail} strict={false} component={RecordCompanyDetailContainer} />
			<Route exact path={routeUrls.recordCompaniesPage} strict={false} component={RecordCompaniesPageContainer} />

			<Route exact path={routeUrls.representatives} strict={false} component={RepresentativesPageContainer} />
			<Route exact path={routeUrls.representativesNew} strict={false} component={RepresentativeFormContainer} />
			<Route exact path={routeUrls.representativesEdit} strict={false} component={RepresentativeFormContainer} />
			<Route exact path={routeUrls.representativesView} strict={false} component={RepresentativeFormContainer} />
			<Route exact path={routeUrls.representativesDetail} strict={false} component={RepresentativeDetailContainer} />
			<Route exact path={routeUrls.representativesPage} strict={false} component={RepresentativesPageContainer} />

			<Route exact path={routeUrls.authorizations} strict={false} component={AuthorizationsListPageContainer} />
			<Route exact path={routeUrls.authorizationsDetail} strict={false} component={RepresentativeDetailContainer} />
			<Route exact path={routeUrls.authorizationsPage} strict={false} component={AuthorizationsListPageContainer} />

			<Route exact path={routeUrls.departments} strict={false} component={DepartmentsContainer} />
			<Route exact path={routeUrls.departmentsPage} strict={false} component={DepartmentsContainer} />
			<Route exact path={routeUrls.newDepartment} strict={false} component={departmentContainer} />
			<Route exact path={routeUrls.editDepartment} strict={false} component={departmentContainer} />

			<Route exact path={routeUrls.positionTypes} strict={false} component={PositionTypesContainer} />
			<Route exact path={routeUrls.positionTypesPage} strict={false} component={PositionTypesContainer} />
			<Route exact path={routeUrls.positionTypesNew} strict={false} component={PositionTypeContainer} />
			<Route exact path={routeUrls.positionTypesEdit} strict={false} component={PositionTypeContainer} />

			<Route exact path={routeUrls.documentTypes} strict={false} component={DocumentTypesContainer} />
			<Route exact path={routeUrls.documentTypesPage} strict={false} component={DocumentTypesContainer} />
			<Route exact path={routeUrls.newDocumentType} strict={false} component={DocumentTypeContainer} />
			<Route exact path={routeUrls.editDocumentType} strict={false} component={DocumentTypeContainer} />

			<Route exact path={routeUrls.documentation} strict={false} component={DocumentationPageContainer} />
			<Route exact path={routeUrls.documentationNew} strict={false} component={DocumentationFormContainer} />
			<Route exact path={routeUrls.documentationEdit} strict={false} component={DocumentationFormContainer} />
			<Route exact path={routeUrls.documentationView} strict={false} component={DocumentationFormContainer} />
			<Route exact path={routeUrls.documentationPage} strict={false} component={DocumentationPageContainer} />
		</LayoutContainer>
	</Switch>
);
