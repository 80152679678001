import { actionTypes } from '../../../../common/const/actionTypes';
import { NotaryPeople } from '../../../../model/NotaryPeople';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { GenericApi } from '../../../../api/generic';
import { ApiUrls } from '../../../../common/const/api';

export const getNotaryPeopleAction = (id: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	GenericApi.GetByIdAsync<NotaryPeople>(ApiUrls.NotaryPeople, id).then((result) => {
		dispatch(getNotaryPeopleCompleted(result));
	});
};

const getNotaryPeopleCompleted = (result: NotaryPeople) => ({
	type: actionTypes.GET_NOTARYPERSON,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
