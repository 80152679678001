import { actionTypes } from '../common/const/actionTypes';
import { CompanyCollection, RecordCompanyCustom, RecordCompanyDetails } from '../model/';
import { RecordCompanyDetailFilter } from '../model/filters/';

const createEmptyRecordCompanies = (): CompanyCollection => {
	let result: CompanyCollection = {
		list: [],
		totalNumberOfRows: 0,
	};

	return result;
};

const createEmptyRecordCompany = (): RecordCompanyCustom => {
	let details: RecordCompanyDetails = {
		id: 0,
		cif: '',
		cifEuropeo: '',
		interlocutor: '',
		name: '',
		tradeSpanish: '',
		tradeEnglish: '',
		ute: null,
		socialCapital: 0,
		socialAddress: '',
		countrySpanish: '',
		countryEnglish: '',
		location: '',
		address: '',
		number: 0,
		postalCode: '',
		notary: '',
		notaryDate: null,
		protocolNumber: '',
		merchandRecord: '',
		volume: '',
		section: '',
		folio: '',
		sheet: '',
		inscriptionDate: null,
		positionCompany: [],
		positionCompanyTotalNumberRows: 0,
		stockholderCompany: [],
		stockholderCompanyTotalNumberRows: 0,
		historicalStockholderCompany: [],
		historicalStockholderCompanyTotalNumberRows: 0,
	};

	let result: RecordCompanyCustom = {
		companyDetails: details,
	};

	return result;
};

const createEmptyRecordCompanyDetailFilter = (): RecordCompanyDetailFilter => {
	return {
		CompaniesId: [],
		PositionsPage: 1,
		PositionsSizePage: 10,
		StockholdersPage: 1,
		StockholdersSizePage: 10,
		HistoricalStockholdersPage: 1,
		HistoricalStockholdersSizePage: 10,
	};
};

export const recordCompaniesListReducer = (state = createEmptyRecordCompanies(), action) => {
	switch (action.type) {
		case actionTypes.GET_RECORD_COMPANIES:
			return handleGetRecordCompaniesCompleted(state, action.payload);
	}

	return state;
};

export const recordCompanyDetailReducer = (state = createEmptyRecordCompany(), action) => {
	switch (action.type) {
		case actionTypes.GET_RECORD_COMPANY:
			return handleGetRecordCompanyCompleted(state, action.payload);
	}

	return state;
};

export const recordCompanyDetailFilterReducer = (state = createEmptyRecordCompanyDetailFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_RECORD_COMPANY_DETAIL_FILTER:
			return handleSetRecordCompanyDetailFilterCompleted(state, action.payload);
	}

	return state;
};

const handleGetRecordCompaniesCompleted = (state: CompanyCollection, payload: CompanyCollection) => {
	return payload;
};

const handleGetRecordCompanyCompleted = (state: RecordCompanyCustom, payload: RecordCompanyCustom) => {
	return payload;
};

const handleSetRecordCompanyDetailFilterCompleted = (state: RecordCompanyDetailFilter, payload: RecordCompanyDetailFilter) => {
	return payload;
};
