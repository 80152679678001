import { CompanyApi } from '../../../../api/companies/companiesApi';
import { actionTypes } from '../../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { CompanyBase } from '../../../../model';

export const getCompaniesNamesAction = (moduleId: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	CompanyApi.GetCompaniesByTradeFilter(moduleId).then((content) => {
		dispatch(getCompaniesNamesCompleted(content));
	});
};
const getCompaniesNamesCompleted = (companies: CompanyBase[]) => ({
	type: actionTypes.GET_COMPANY_NAMES,
	payload: companies,
	meta: {
		httpEnd: true,
	},
});
