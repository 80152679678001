import { connect } from 'react-redux';
import { StateReducer } from '../../reducers';
import { ArchiveManagementPage } from './page';

const mapStateToProps = (state: StateReducer) => ({
	language: state.language,
});

const mapDispatchToProps = () => ({});

export const ArchiveManagementPageContainer = connect(mapStateToProps, mapDispatchToProps)(ArchiveManagementPage);
