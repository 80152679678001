import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react/lib/components/ComboBox';
import * as React from 'react';
import { languages } from '../../../common/const/resources';
import { isAj } from '../../../common/const/rolesAssignments';
import { CustomSelect, ISelectOption } from '../../../common/customSelect/customSelect';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';
import {
	BaseName,
	BaseType,
	CompanyBase,
	Country,
	DocumentType,
	DocumentTypeSubType,
	DocumentsCollection,
	Language,
	UserState,
} from '../../../model/';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { DocumentationFilter, createEmptyDocumentationFilter } from '../../../model/filters/DocumentationFilter';
import { businessTypeEnum } from '../../../utils/enums/businessTypeEnum';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';

export interface IProps {
	filter: DocumentationFilter;
	onchangeFilter: any;
	language: Language;
	itemsPage: number;
	companies: CompanyBase[];
	tradeCompanies: CompanyBase[];
	business: BaseType[];
	countries: Country[];
	originals: BaseType[];
	documentTypes: DocumentType[];
	documentSubtypes: DocumentTypeSubType[];
	user: UserState;
	page: number;
	getDocumentation: (filter: DocumentationFilter, pageNumber: number, itemsPage: number) => Promise<DocumentsCollection>;
	getCompanies: (tradeId?: number) => Promise<BaseName[]>;
}

export interface IState {
	filter: DocumentationFilter;
	collapsed: boolean;
	isBusinessDisabled: boolean;
	isCountryDisabled: boolean;
	showDissolved: boolean;
}

export class DocumentationSearchComponent extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			collapsed: false,
			filter: createEmptyDocumentationFilter(),
			isBusinessDisabled: false,
			isCountryDisabled: false,
			showDissolved: true,
		};
	}

	private getTradeIdRole(): number {
		switch (this.props.user.currentUser.roles.sort()[0]) {
			case userRoles.ajAeropuertos:
			case userRoles.ajAeropuertosH:
				return businessTypeEnum.aeropuertos;
			case userRoles.ajCintra:
			case userRoles.ajCintraH:
				return businessTypeEnum.cintra;
			case userRoles.ajConstruccion:
			case userRoles.ajConstruccionH:
				return businessTypeEnum.construccion;
			case userRoles.ajCorporacion:
			case userRoles.ajCorporacionH:
				return businessTypeEnum.corporacion;
			case userRoles.ajFeim:
			case userRoles.ajFeimH:
				return businessTypeEnum.feim;
			case userRoles.admin:
				return 0;
		}
	}

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let calendarStrings: IDatePickerStrings = language['calendar'];
		let companies: IComboBoxOption[] = [];
		companies.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		companies.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		if(this.state.filter.TradeId <= 0){
			this.props.companies.forEach((item) => {
				companies.push({
					key: item.id,
					text: item.name,
					id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
					title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null,
				});
			});
		}else{
			this.props.tradeCompanies.forEach((item) => {
				companies.push({
					key: item.id,
					text: item.name,
					id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
					title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null,
				});
			});
		}
		
		

		let business: IComboBoxOption[] = [];
		if (this.props.business) {
						this.props.business.forEach((item) => {
				business.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
			business.sort((a, b) => a.text.localeCompare(b.text))
			business.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			business.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		}

		let countries: IComboBoxOption[] = [];
		if (this.props.countries) {			
			this.props.countries.forEach((item) => {
				countries.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
			countries.sort((a, b) => a.text.localeCompare(b.text))
			countries.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			countries.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		}

		let originals: IComboBoxOption[] = [];
		if (this.props.originals) {			
			this.props.originals.forEach((item) => {
				originals.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
			originals.sort((a, b) => a.text.localeCompare(b.text))			
			originals.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			originals.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		}

		let docTypes: IComboBoxOption[] = [];
		this.props.documentTypes.forEach((item) => {
			docTypes.push({
				key: item.id,
				text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
			});
		});
		docTypes.sort((a, b) => a.text.localeCompare(b.text))
		docTypes.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		docTypes.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });

		let docSubtypes: IComboBoxOption[] = [];
		
		let docType: DocumentTypeSubType = this.props.documentSubtypes.find((i) => i.id === this.state.filter.DocTypeId);
		docType?.listSubTypes?.forEach((item) => {
			docSubtypes.push({
				key: item.id,
				text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
			});
		});
		docSubtypes.sort((a, b) => a.text.localeCompare(b.text))
		docSubtypes.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		docSubtypes.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });

		// const onRenderOption = (option: any): JSX.Element => {
		// 	if (option.itemType == DropdownMenuItemType.Header) {
		// 		if(option.key == 0){
		// 			return (
		// 				<Toggle 
		// 				inlineLabel 
		// 				label={language['SocietiesCombo']['showDissolved']} 
		// 				checked = {this.state.showDissolved}
		// 				onChange={(event, checked)=>{this.setState({showDissolved: checked})}} />
		// 			);
		// 		}
		// 		else{
		// 			return (
		// 				<table>
		// 					<tbody>
		// 						<tr>
		// 							<td style={{ width: 15, fontSize: 14, padding: 5 }}></td>
		// 							<td style={{ width: 200, fontSize: 14, padding: 0 }}>{option.key}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: 0 }}>{option.text}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: '0px 10px' }}>{option.title}</td>
		// 						</tr>
		// 					</tbody>
		// 				</table>
		// 			);
		// 		}
		// 	} else {
		// 		return (
		// 			<table>
		// 				<tbody>
		// 					<tr>
		// 						<td style={{ width: 200, fontSize: 14, padding: 5 }}>{option.text}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.title}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.id}</td>
		// 					</tr>
		// 				</tbody>
		// 			</table>
		// 		);
		// 	}
		// };

		let sortedCompanies = companies.sort((a, b) => a.text.localeCompare(b.text));
		// sortedCompanies.unshift({
		// 	key: language['SocietiesCombo']['name'], text: language['SocietiesCombo']['settlementDate'], title:language['SocietiesCombo']['dissolutionDate'],
		// 	itemType: DropdownMenuItemType.Header,
		// });
		// sortedCompanies.unshift({ key: 0, text: "", itemType: DropdownMenuItemType.Header });	
		
		let selectedCompanies= (this.state.showDissolved) ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title === language['SocietiesCombo']['dissolutionDate'])
		let options: ISelectOption[] = selectedCompanies.map(company => {
			return {
				value:company.key.toString(),
				label:company.text, 
				disolutionDate:company.id, 
				liquidationDate: company.title
			};
		  });

		return (
			<>
				<div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
					<div className={this.state.collapsed ? 'ms-Grid-row filter_header-collapse' : 'ms-Grid-row filter_header'}>
						<div className='ms-Grid-col ms-xl6'>
							{this.state.collapsed
								? language['generic']['list']['filter']['collapsedTitle']
								: language['generic']['list']['filter']['btnTitle']}
						</div>
						<div className='ms-Grid-col ms-xl6'>
							<CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? 'none' : '' }}>
						<div className='ms-Grid-row flex-option'>
							{/* <div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['society']['title']}</p>
								<VirtualizedComboBox
									autoComplete='on'
									allowFreeform={false}
									options={this.state.showDissolved ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title == language['SocietiesCombo']['dissolutionDate'])}
									placeholder={language['documentation']['list']['filter']['options']['society']['placeholder']}
									onChange={this.handleCompanyChange}
									selectedKey={this.state.filter.CompaniesId}
									onRenderOption={onRenderOption}
									multiSelect={true}
								/>
							</div> */}
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['society']['title']}</p>
								<CustomSelect
									options={options}
									handleCustomSelectChange={this.handleReactSelectCompanyChange}
									showDissolved={this.state.showDissolved}
									handleShowDissolvedChange={(event, checked)=>{this.setState({showDissolved: checked})}}
									language={language}
									showDissolutionDate={true}
									selectedValues={this.state.filter.selectedCompanies}
									isMultiSelection={true}
									isDisabled={false}
									placeholder={language['documentation']['list']['filter']['options']['society']['placeholder']}
								/>
							</div>							
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['uniqueCode']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.UniqueCode}
									onChange={this.handleUniqueCodeChange}
									onBlur={this.handleUniqueCodeBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['trade']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={business}
									placeholder={language['documentation']['list']['filter']['options']['trade']['placeholder']}
									onChange={this.handleBusinessChange}
									selectedKey={this.getTradeIdRole() === businessTypeEnum.construccion ? businessTypeEnum.construccion : this.state.filter.TradeId}
									useComboBoxAsMenuWidth
									disabled={this.state.isBusinessDisabled}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['docType']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={docTypes}
									placeholder={language['documentation']['list']['filter']['options']['docType']['placeholder']}
									onChange={this.handleDocTypeChange}
									selectedKey={this.state.filter.DocTypeId}
									useComboBoxAsMenuWidth
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['docSubtype']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={docSubtypes}
									placeholder={language['documentation']['list']['filter']['options']['docSubtype']['placeholder']}
									onChange={this.handleDocSubtypeChange}
									selectedKey={this.state.filter.DocSubtypeId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['original']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={originals}
									placeholder={language['documentation']['list']['filter']['options']['original']['placeholder']}
									onChange={this.handleOriginalChange}
									selectedKey={this.state.filter.OriginalId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['copy']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Copy}
									onChange={this.handleCopyChange}
									onBlur={this.handleCopyBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['location']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Location}
									onChange={this.handleLocationChange}
									onBlur={this.handleLocationBlur}
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['country']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={countries}
									placeholder={language['documentation']['list']['filter']['options']['country']['placeholder']}
									onChange={this.handleCountriesChange}
									selectedKey={this.state.filter.CountryId}
									useComboBoxAsMenuWidth
									disabled={this.state.isCountryDisabled}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['project']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Project}
									onChange={this.handleProjectChange}
									onBlur={this.handleProjectBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['remark']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Remark}
									onChange={this.handleRemarkChange}
									onBlur={this.handleRemarkBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['simple']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Simple}
									onChange={this.handleSimpleChange}
									onBlur={this.handleSimpleBlur}
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['documentation']['list']['filter']['options']['date']['title']}</p>
								<div className='range-picker'>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['form']['fields']['date']['from']}
										ariaLabel={language['generic']['form']['fields']['date']['from']}
										strings={calendarStrings}
										value={this.state.filter.FromDate}
										maxDate={this.state.filter.ToDate}
										onSelectDate={this.handleFromDateChange}
									/>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['form']['fields']['date']['to']}
										ariaLabel={language['generic']['form']['fields']['date']['to']}
										strings={calendarStrings}
										value={this.state.filter.ToDate}
										minDate={this.state.filter.FromDate}
										onSelectDate={this.handleToDateChange}
									/>
								</div>
							</div>
						</div>
						<hr />
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
								<button type='button' className='filter_button-clean' onClick={this.cleanFilterDocumentation}>
									{language['generic']['list']['filter']['btnClean']}
								</button>
								<button type='button' className='filter_button-apply' onClick={this.filterDocumentation}>
									{language['generic']['list']['filter']['btnApply']}
								</button>
							</div>
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? '' : 'none' }}>
						<div className='filter_collapse'>
							<p style={{ display: this.state.filter.CompaniesId.length > 0 ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['society']['title']}</span>
								<span className='value'>{this.state.filter.CompaniesName?.join(", ")}</span>
							</p>
							<p style={{ display: this.state.filter.UniqueCode !== '' ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['uniqueCode']['title']}</span>
								<span className='value'>{this.state.filter.UniqueCode}</span>
							</p>
							<p style={{ display: this.state.filter.TradeId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['trade']['title']}</span>
								<span className='value'>{this.state.filter.TradeName}</span>
							</p>
							<p style={{ display: this.state.filter.DocTypeId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['docType']['title']}</span>
								<span className='value'>{this.state.filter.DocTypeName}</span>
							</p>
							<p style={{ display: this.state.filter.DocSubtypeId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['docSubtype']['title']}</span>
								<span className='value'>{this.state.filter.DocSubtypeName}</span>
							</p>
							<p style={{ display: this.state.filter.OriginalId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['original']['title']}</span>
								<span className='value'>{this.state.filter.OriginalName}</span>
							</p>
							<p style={{ display: this.state.filter.Copy !== '' ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['copy']['title']}</span>
								<span className='value'>{this.state.filter.Copy}</span>
							</p>
							<p style={{ display: this.state.filter.Location !== '' ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['location']['title']}</span>
								<span className='value'>{this.state.filter.Location}</span>
							</p>
							<p style={{ display: this.state.filter.CountryId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['country']['title']}</span>
								<span className='value'>{this.state.filter.CountryName}</span>
							</p>
							<p style={{ display: this.state.filter.Project !== '' ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['project']['title']}</span>
								<span className='value'>{this.state.filter.Project}</span>
							</p>
							<p style={{ display: this.state.filter.Remark !== '' ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['remark']['title']}</span>
								<span className='value'>{this.state.filter.Remark}</span>
							</p>
							<p style={{ display: this.state.filter.Simple !== '' ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['simple']['title']}</span>
								<span className='value'>{this.state.filter.Simple}</span>
							</p>
							<p style={{ display: this.state.filter.FromDate != null || this.state.filter.ToDate != null ? '' : 'none' }}>
								<span className='key'>{language['documentation']['list']['filter']['options']['date']['title']}</span>
								<span className='value'>
									{utils.dateRangeToString(this.state.filter.FromDate, this.state.filter.ToDate, this.props.language)}
								</span>
							</p>
						</div>
					</div>
				</div>
			</>
		);
	}

	componentDidMount() {
		this.setState({ filter: this.props.filter });
	}
	componentDidUpdate(prevProps) {
		if (this.props.user !== prevProps.user) {
			//this.handleDisableBusiness();
		}

		if (this.props.business !== prevProps.business) {
			let filter = { ...this.state.filter };
			filter.TradeName = this.props.language.name.includes(ActiveLanguage.English)
				? this.props.business.find((x) => x.id === filter.TradeId)?.descriptionEnglish
				: this.props.business.find((x) => x.id === filter.TradeId)?.descriptionSpanish;

			this.setState({ filter: filter });
		}

		if (this.props.countries !== prevProps.countries) {
			let isCountryDisabled = false;
			if (this.props.countries.length === 1) {
				let netherlands = this.props.countries.find((x) => x.descriptionEnglish === 'Netherlands')?.id;
				if (netherlands !== undefined) {
					let filter = { ...this.state.filter };
					filter.CountryId = netherlands;
					filter.CountryName = this.props.language.name.includes(ActiveLanguage.English)
						? this.props.countries.find((x) => x.id === filter.CountryId)?.descriptionEnglish
						: this.props.countries.find((x) => x.id === filter.CountryId)?.descriptionSpanish;
					isCountryDisabled = true;
					this.setState({ filter: filter, isCountryDisabled: isCountryDisabled });
				}
			}
		}
	}

	private readonly handleDisableBusiness = (): void => {
		let isDisabled = false;
		let defaultOption = 0;
		let filter = { ...this.state.filter };
		if (this.props.user && this.props.user.currentUser?.roles !== undefined) {
			defaultOption = utils.getBusinessNewPowerByRole(this.props.user.currentUser?.roles[0]);
			if (defaultOption !== 0) {
				isDisabled = true;
				filter.TradeId = defaultOption;
				this.props.onchangeFilter(filter);
			}
		}

		this.setState({ isBusinessDisabled: isDisabled, filter: filter });
	};

	// private readonly handleCompanyChange = (event, option) => {
	// 	let filter = { ...this.state.filter };
	// 	if (option.selected == true) {
	// 		filter.CompaniesId.push(option.key);
	// 		filter.CompaniesName.push(option.text);
	// 	} else {
	// 		filter.CompaniesId = filter.CompaniesId.filter((e) => e !== option.key);
	// 		filter.CompaniesName = filter.CompaniesName.filter((e) => e !== option.text);
	// 	}
	// 	this.setState({ filter: filter });
	// };

	private readonly handleReactSelectCompanyChange = (values) => {

		let filter = { ...this.state.filter };

		filter.CompaniesId.length = 0;
		filter.CompaniesName.length = 0;
		filter.selectedCompanies.length = 0;

		if(values!==undefined && values!==null){

			values.forEach((item) => {
				filter.selectedCompanies.push({value:item.value.toString(), label:item.label, disolutionDate:item.disolutionDate, liquidationDate: item.liquidationDate});
				filter.CompaniesId.push(item.value);
				filter.CompaniesName.push(item.label);
			})
		}

		this.setState({ filter: filter });
	};	

	private readonly handleBusinessChange = async (event, option) => {
		let filter = { ...this.state.filter };
		filter.TradeId = parseInt(option?.key);
		filter.TradeName = option?.text;
		this.setState({ filter: filter });
		let tradeId = option?.key;
		await this.props.getCompanies(tradeId);
	};
	private readonly handleDocTypeChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.DocTypeId = parseInt(option?.key);
		filter.DocTypeName = option?.text;
		filter.DocSubtypeId = 0;
		filter.DocSubtypeName = '';
		this.setState({ filter: filter });
	};

	private readonly handleDocSubtypeChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.DocSubtypeId = parseInt(option?.key);
		filter.DocSubtypeName = option?.text;
		this.setState({ filter: filter });
	};

	private readonly handleCopyChange = (value) => {
		let filter = { ...this.state.filter };
		filter.Copy = value.target.value;
		this.setState({ filter: filter });
	};
	private readonly handleCopyBlur = (value) => {
		let filter = { ...this.state.filter };
		filter.Copy = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	private readonly handleUniqueCodeChange = (value) => {
		let filter = { ...this.state.filter };
		filter.UniqueCode = value.target.value;
		this.setState({ filter: filter });
	};
	private readonly handleUniqueCodeBlur = (value) => {
		let filter = { ...this.state.filter };
		filter.UniqueCode = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	private readonly handleOriginalChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.OriginalId = parseInt(option?.key);
		filter.OriginalName = option?.text;
		this.setState({ filter: filter });
	};

	private readonly handleLocationChange = (value) => {
		let filter = { ...this.state.filter };
		filter.Location = value.target.value;
		this.setState({ filter: filter });
	};
	private readonly handleLocationBlur = (value) => {
		let filter = { ...this.state.filter };
		filter.Location = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	private readonly handleCountriesChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.CountryId = parseInt(option?.key);
		filter.CountryName = option?.text;
		this.setState({ filter: filter });
	};

	private readonly handleProjectChange = (value) => {
		let filter = { ...this.state.filter };
		filter.Project = value.target.value;
		this.setState({ filter: filter });
	};
	private readonly handleProjectBlur = (value) => {
		let filter = { ...this.state.filter };
		filter.Project = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	private readonly handleRemarkChange = (value) => {
		let filter = { ...this.state.filter };
		filter.Remark = value.target.value;
		this.setState({ filter: filter });
	};
	private readonly handleRemarkBlur = (value) => {
		let filter = { ...this.state.filter };
		filter.Remark = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};
	private readonly handleSimpleChange = (value) => {
		let filter = { ...this.state.filter };
		filter.Simple = value.target.value;
		this.setState({ filter: filter });
	};
	private readonly handleSimpleBlur = (value) => {
		let filter = { ...this.state.filter };
		filter.Simple = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};
	private readonly handleFromDateChange = (value) => {
		let filter = { ...this.state.filter };
		filter.FromDate = value;
		this.setState({ filter: filter });
	};
	private readonly handleToDateChange = (value) => {
		let filter = { ...this.state.filter };
		filter.ToDate = value;
		this.setState({ filter: filter });
	};

	private readonly filterDocumentation = () => {
		let filter = { ...this.state.filter };
		this.setState({ collapsed: true });
		this.props.onchangeFilter(filter);
		this.props.getDocumentation(filter, 1, this.props.itemsPage);
	};

	private readonly cleanFilterDocumentation = () => {
		let resetFilter = createEmptyDocumentationFilter();
		if (this.props.countries.length === 1) {
			resetFilter.CountryId = this.state.filter.CountryId;
			resetFilter.CountryName = this.state.filter.CountryName;
		}

		if(utils.isInRoles(isAj)){
			let defaultOption = utils.getBusinessByRole(this.props.user.currentUser.roles[0]);
			if (defaultOption !== 0) {
				var item = this.props.business.find(x => x.id === defaultOption)
				resetFilter.TradeId = defaultOption;
				resetFilter.TradeName = this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish;
			}
		}	

		this.setState({ filter: resetFilter });
		this.props.onchangeFilter(resetFilter);
		this.props.getDocumentation(resetFilter, 1, this.props.itemsPage);
	};

	private readonly handleFiltersCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};
}
