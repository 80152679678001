import { connect } from 'react-redux';
import { Language, NotaryPeople } from '../../../model';
import { StateReducer } from '../../../reducers';
import { saveNotaryPeopleAction, getNotaryPeopleAction } from './actions';
import { NotaryPeopleForm } from './page';

const mapStateToProps = (state: StateReducer) => ({
	notaryPeople: state.notaryPeople,
	notaryPeopleSave: state.notaryPeopleSave,
	user: state.user,
	language: state.language,
	business: state.businessNames,
	loading: state.http?.inProgress,
	nameAzureAd: state.nameAzureAd
});

const mapDispatchToProps = (dispatch: any) => ({
    getNotaryPeople: (id: number) => dispatch(getNotaryPeopleAction(id)),
	saveNotaryPeople: (notaryPeople: NotaryPeople, language: Language) => dispatch(saveNotaryPeopleAction(notaryPeople, language))
});

export const NotaryPeopleFormContainer = connect(mapStateToProps,
	mapDispatchToProps)(NotaryPeopleForm);