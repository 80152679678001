import { RecordCompaniesApi } from '../../../../api/recordCompanies';

export const exportAdminBodyToExcelAction = (companiesId: number[]) => (dispatch) => {
	RecordCompaniesApi.ExportAdminBodyToExcel(companiesId).then((content) => {});
};

export const exportActualStockholdersToExcelAction = (companiesId: number[]) => (dispatch) => {
	RecordCompaniesApi.ExportActualStockholdersToExcel(companiesId).then((content) => {});
};

export const exportHistoricalStockholdersToExcelAction = (companiesId: number[]) => (dispatch) => {
	RecordCompaniesApi.ExportHistoricalStockholdersToExcel(companiesId).then((content) => {});
};
