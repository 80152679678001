import { OrderBy } from './OrderBy';
import { ActiveRecordCompanyOrderFilter } from './ActiveRecordCompanyOrderFilter';

export interface RecordCompanyFilter {
	CompanyNameOrder: OrderBy;
	CifOrder: OrderBy;
	SocialAddressOrder: OrderBy;
	ConstitutionDateOrder: OrderBy;
	CompaniesId: number[];
	Cif: string;
	LegalFormId: number;
	CurrentFilter: ActiveRecordCompanyOrderFilter;
}

export interface RecordCompanyDetailFilter {
	CompaniesId: number[];
	PositionsPage: number;
	PositionsSizePage: number;
	StockholdersPage: number;
	StockholdersSizePage: number;
	HistoricalStockholdersPage: number;
	HistoricalStockholdersSizePage: number;
}

export const createEmptyRecordCompanyDetailFilter = (): RecordCompanyDetailFilter => {
	let result: RecordCompanyDetailFilter = {
		CompaniesId: [],
		PositionsPage: 1,
		PositionsSizePage: 10,
		StockholdersPage: 1,
		StockholdersSizePage: 10,
		HistoricalStockholdersPage: 1,
		HistoricalStockholdersSizePage: 10,
	};

	return result;
};
