import { Icon } from 'office-ui-fabric-react/lib/Icon';
import PropTypes from 'prop-types';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { languages } from '../../../common/const/resources';
import { onlyBodyTypesModule } from '../../../common/const/rolesAssignments';
import { routeUrls } from '../../../common/const/routes';
import { NoResultsComponent } from '../../../common/noResults/noResults';
import { SpinnerContainer } from '../../../common/spinner';
import { UnauthorizedView } from '../../../common/unauthorizedView/unauthorizedView';
import { BaseType, BodyTypesCollection, Language, User, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { BodyTypesFilter } from '../../../model/filters/BodyTypesFilter';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';
import BodyTypesDataGrid from './grid';
import { BodyTypesSearchComponent } from './search';

export interface IProps {
	bodyTypesCollection: BodyTypesCollection;
	business: BaseType[];
	user: User;
	currentUser: UserState;
	language: Language;
	onchangeFilter: any;
	filter: BodyTypesFilter;

	getBodyTypes: (filter: BodyTypesFilter, pageNumber: number, itemsPage: number) => Promise<BodyTypesCollection>;
	deleteBodyType: (filter: BodyTypesFilter, language: Language, id: number, itemsPage: number) => Promise<boolean>;
	getBusiness: () => Promise<BaseType[]>;
	exportToExcel: (filter: BodyTypesFilter) => Promise<any>;
}

export interface IState {
	isAuthorized: boolean;
	isLoading: boolean;
	collapsed: boolean;
	actualFilter: BodyTypesFilter;
}

export class BodyTypesPage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	static contextTypes = {
		router: PropTypes.object,
	};
	constructor(props: any) {
		super(props);

		this.state = {
			isAuthorized: false,
			isLoading: true,
			collapsed: false,
			actualFilter: this.props.filter,
		};
	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlyBodyTypesModule);
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});

		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}

		this.props.getBusiness();

		if (this.props.location.state != null) {
			this.props.getBodyTypes(this.props.location.state.BodyTypesFilter, page, this.props.filter.ItemsPage);
		} else {
			this.props.getBodyTypes(this.props.filter, page, this.props.filter.ItemsPage);
		}
	}

	public componentDidUpdate(prevProps) {
		if (this.props.currentUser !== prevProps.currentUser) {
			let isAuth = utils.isInRoles(onlyBodyTypesModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}

		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];
		if (newPage !== oldPage) {
			this.props.getBodyTypes(this.props.filter, newPage, this.props.filter.ItemsPage);
		}
	}

	public render() {
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let page: number = Number(this.props.match.params['page']);

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>{language['body-types']['list']['title']}</h1>
							{isAdmin ? (
								<Link to='/bodytypes/new' className='button-addButton'>
									<Icon iconName='Add' />
									<span>{language['body-types']['list']['new-button']}</span>
								</Link>
							) : (
								<span></span>
							)}
						</div>
						<div className='ms-Grid'>
							<BodyTypesSearchComponent
								filter={this.state.actualFilter}
								getBodyTypes={this.props.getBodyTypes}
								onchangeFilter={this.handleFilterChange}
								language={this.props.language}
								business={this.props.business}
								itemsPage={this.state.actualFilter.ItemsPage}
							/>
							{this.props.bodyTypesCollection.totalNumberOfRows > 0 ? (
								<BodyTypesDataGrid
									bodyTypesCollection={this.props.bodyTypesCollection}
									user={this.props.user}
									language={this.props.language}
									filter={this.state.actualFilter}
									deleteBodyType={this.props.deleteBodyType}
									page={page}
									getBodyTypes={this.props.getBodyTypes}
									exportToExcel={this.props.exportToExcel}
									onchangeFilter={this.handleFilterChange}
								/>
							) : (
								<NoResultsComponent language={this.props.language} list={language['body-types']['list']['title']} />
							)}
						</div>
					</>
				)}
			</>
		);
	}
	public readonly handleFilterChange = (newfilter: BodyTypesFilter): void => {
		this.setState({ actualFilter: newfilter });
		this.props.history.push(routeUrls.bodyTypesPagination + 1);
	};
}
