import { ApplicationProfile } from '../model';
import { actionTypes } from '../common/const/actionTypes';
import { Account } from 'msal';
import { MeApi } from '../api/me';
import { httpCallStartAction } from '../common/middleware/http/actions';

import { AppInsights } from "applicationinsights-js";


export const fetchIsUserAuthorized = (account: Account, token: string) => async (dispatch: any) => {

	let applicationProfile: ApplicationProfile = {
		name: '',
		token: '',
		rol: null,
		email: '',
		roles: [],
		isAuthorized: false,
		preferredLanguage: ''
	};

	try {
		const userData = account;

		if (userData) {
			dispatch(httpCallStartAction());
			var userInfo = await MeApi.GetUserInfo();
			applicationProfile = {
				name: account.name,
				isAuthorized: true,
				email: account.userName,
	    		rol: null,
				roles: userInfo?.roles,
				token: token,
				preferredLanguage: userInfo?.preferredLanguage
			};

			AppInsights.trackTrace("FRONT - fetchIsUserAuthorized - user: " + applicationProfile.name + " - applicationProfile.preferredLanguage:" + applicationProfile.preferredLanguage);
			AppInsights.trackTrace("FRONT - fetchIsUserAuthorized - user: " + applicationProfile.name + " - applicationProfile.token:" + applicationProfile.token);
			AppInsights.trackTrace("FRONT - fetchIsUserAuthorized - applicationProfile.name:" + applicationProfile.name);
			AppInsights.trackTrace("FRONT - fetchIsUserAuthorized - user: " + applicationProfile.name + " - applicationProfile.isAuthorized:" + applicationProfile.isAuthorized);

			dispatch(fetchLanguageCompleted(userInfo?.preferredLanguage));

			AppInsights.trackTrace("FRONT - fetchIsUserAuthorized - user: " + applicationProfile.name + " - fetchLanguageCompleted completed");

			dispatch(fetchUserCompleted(applicationProfile));

			AppInsights.trackTrace("FRONT - fetchIsUserAuthorized - user: " + applicationProfile.name + " - fetchUserCompleted completed");
		}
	} catch (error) {
		AppInsights.trackTrace("FRONT - fetchIsUserAuthorized- Error:" + error);
	} finally {
		dispatch(fetchUserCompleted(applicationProfile));

		AppInsights.trackTrace("FRONT - fetchIsUserAuthorized - user: " + applicationProfile.name + " - finally fetchUserCompleted completed");
	}
};

const fetchUserCompleted = (user: ApplicationProfile) => ({
	type: actionTypes.FETCH_CURRENT_USER_COMPLETED,
	payload: user,
	meta: {
		httpEnd: true,
	},
});

const fetchLanguageCompleted = (language: string) => ({
	type: actionTypes.GET_LANGUAGE,
	payload: {
		name: language
	}
})


