import { DefaultButton } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { languages } from '../../../common/const/resources';
import { routeUrls } from '../../../common/const/routes';
import { DialogComponent } from '../../../common/dialog/dialogComponent';
import { AdvancedPagination } from '../../../common/pagination/advancedPagination';
import { SpinnerContainer } from '../../../common/spinner';
import { DeedType, DeedTypeCollection, Language, OrderBy, User } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { ActiveDeedTypeOrderFilter, DeedTypeFilter } from '../../../model/filters';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';

export interface IProps {
	deedTypeCollection: DeedTypeCollection;
	deedTypePrimaries: DeedType[];
	user: User;
	filter: DeedTypeFilter;
	language: Language;
	page: number;
	onchangeFilter: any;
	getDeedTypeCollection: (filter: DeedTypeFilter, pageNumber: number) => Promise<DeedTypeCollection>;
	getDeedTypePrimaries: () => DeedType[];
	setDeedTypeFilter: (filter: DeedTypeFilter) => DeedTypeFilter;
	deleteDeedType: (filter: DeedTypeFilter, language: Language, id: number) => Promise<boolean>;
	exportToExcel: (filter: DeedTypeFilter) => Promise<any>;
}
export interface IState {
	showModal: boolean;
	id: number;
	actualFilter: DeedTypeFilter;
	orderByDescriptionSpanish: string;
	orderByDescriptionEnglish: string;
	orderByType: string;
	orderByAffectCapital: string;
}
class DeedTypeDataGridComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false, id: 0,
			orderByDescriptionSpanish: 'ms-Icon--ChevronUpSmall',
			orderByDescriptionEnglish: 'ms-Icon--ChevronUpSmall',
			orderByType: 'ms-Icon--ChevronUpSmall',
			orderByAffectCapital: 'ms-Icon--ChevronUpSmall',
			actualFilter: this.props.filter
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	componentDidUpdate(prevProps) {
		if (this.props.filter !== prevProps.filter) {
			this.setState({ actualFilter: this.props.filter });
		}
	}
	public render() {
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				<SpinnerContainer message='Cargando...' />
				<div className='ms-Grid-row info_results'>
					<div className='ms-Grid-col ms-xl6'>
						<span>
							{this.props.deedTypeCollection.totalNumberOfRows > 1 ? (
								<span>
									{this.props.deedTypeCollection.totalNumberOfRows}{' '}
									{language['generic']['list']['table']['results']}
								</span>
							) : this.props.deedTypeCollection.totalNumberOfRows === 1 ? (
								<span>
									{this.props.deedTypeCollection.totalNumberOfRows}{' '}
									{language['generic']['list']['table']['result']}
								</span>
							) : (
								<span>{language['generic']['list']['table']['noResult']}</span>
							)}
						</span>
					</div>
					<div className='ms-Grid-col ms-xl6'>
						<DefaultButton
							className={
								this.props.deedTypeCollection.totalNumberOfRows > 0
									? 'button-download' : 'button-download isDisabled'
							}
							onClick={this.handleExportClick}>
							<i className='ms-Icon ms-Icon--ExcelDocument'></i>
							<span>{language['generic']['list']['table']['excel-button']}</span>
						</DefaultButton>
					</div>
				</div>
				<div className='ms-Grid-row main-container__table'>
					<table>
						<thead>
							<tr>
								<th className=''>
									<div className='table-header'>
										<span>{language['deedType']['table']['columns']['descriptionSpanish']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveDeedTypeOrderFilter.DescriptionSpanish ===
													this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.DescriptionSpanishOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionSpanish}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveDeedTypeOrderFilter.DescriptionSpanish ===
													this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.DescriptionSpanishOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionSpanish}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['deedType']['table']['columns']['descriptionEnglish']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveDeedTypeOrderFilter.DescriptionEnglish ===
													this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.DescriptionEnglishOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionEnglish}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveDeedTypeOrderFilter.DescriptionEnglish ===
													this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.DescriptionEnglishOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByDescriptionEnglish}
											></i>
										</div>
									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['deedType']['table']['columns']['type']}</span>

										{this.props.language.name.includes(ActiveLanguage.English) ? (
											<div className='order-buttons'>
												<i
													className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveDeedTypeOrderFilter.FatherDescriptionEnglish ===
														this.state.actualFilter.CurrentFilter &&
														this.state.actualFilter.FatherDescriptionEnglishOrder === OrderBy.Asc
														? 'selected'
														: ''
														}`}
													aria-hidden='true'
													onClick={this.handleOrderByParentEnglish}
												></i>
												<i
													className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveDeedTypeOrderFilter.FatherDescriptionEnglish ===
														this.state.actualFilter.CurrentFilter &&
														this.state.actualFilter.FatherDescriptionEnglishOrder === OrderBy.Desc
														? 'selected'
														: ''
														}`}
													aria-hidden='true'
													onClick={this.handleOrderByParentEnglish}
												></i>
											</div>
											) : (
											<div className='order-buttons'>
												<i
													className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveDeedTypeOrderFilter.FatherDescriptionSpanish ===
														this.state.actualFilter.CurrentFilter &&
														this.state.actualFilter.FatherDescriptionSpanishOrder === OrderBy.Asc
														? 'selected'
														: ''
														}`}
													aria-hidden='true'
													onClick={this.handleOrderByParentSpanish}
												></i>
												<i
													className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveDeedTypeOrderFilter.FatherDescriptionSpanish ===
														this.state.actualFilter.CurrentFilter &&
														this.state.actualFilter.FatherDescriptionSpanishOrder === OrderBy.Desc
														? 'selected'
														: ''
														}`}
													aria-hidden='true'
													onClick={this.handleOrderByParentSpanish}
												></i>
											</div>
											) 
										}										

									</div>
								</th>
								<th className=''>
									<div className='table-header'>
										<span>{language['deedType']['table']['columns']['capital']}</span>
										<div className='order-buttons'>
											<i
												className={`ms-Icon ms-Icon--ChevronUpSmall ${ActiveDeedTypeOrderFilter.AffectsCapital ===
													this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.AffectsCapitalOrder === OrderBy.Asc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByAffectsCapital}
											></i>
											<i
												className={`ms-Icon ms-Icon--ChevronDownSmall ${ActiveDeedTypeOrderFilter.AffectsCapital ===
													this.state.actualFilter.CurrentFilter &&
													this.state.actualFilter.AffectsCapitalOrder === OrderBy.Desc
													? 'selected'
													: ''
													}`}
												aria-hidden='true'
												onClick={this.handleOrderByAffectsCapital}
											></i>
										</div>
									</div>
								</th>
								<th className=''></th>
							</tr>
						</thead>
						<tbody>
							{this.props.deedTypeCollection.list?.map((item: DeedType) => {
								//let type = this.props.deedTypePrimaries.find((x: DeedType) => x.id === item.fatherId);
								// let typeName = this.props.language.name.includes(ActiveLanguage.Spanish) ? type?.descriptionSpanish : type?.descriptionEnglish;
								let typeName = this.props.language.name.includes(ActiveLanguage.Spanish) ? item.fatherDescriptionSpanish : item.fatherDescriptionEnglish;
								return (
									<tr key={item.id.toString()}>
										<td className='mainColumn '>{item.descriptionSpanish}</td>
										<td className=''>{item.descriptionEnglish}</td>
										<td className=''>{typeName}</td>
										<td className=''>{
											item.affectsCapital ?
												language['generic']['yes']
												: language['generic']['no']
										}</td>
										<td className='deleteItem'>
											<div>
												<DefaultButton
													text='. . .'
													disabled={!isAdmin}
													menuProps={{
														shouldFocusOnMount: true,
														items: [
															{
																key: 'Edit',
																onClick: () => {
																	this.props.history.push('/deedTypes/edit/' + item.id);
																},
																iconProps: {
																	iconName: 'Edit',
																	className: 'context-menu__icon',
																},
																name: language['deedType']['table']['options']['edit']
															},
															{
																key: 'delete',
																iconProps: {
																	iconName: 'Delete',
																	className: 'context-menu__icon',
																},
																onClick: () => {
																	this.ShowModal(item.id);
																},
																name: language['deedType']['table']['options']['delete'],
															},
														],
													}}
													className='tdNoBorder'
												/>
												<DialogComponent
													showModal={this.state.showModal}
													TitleDialog={language['generic']['dialog']['title']}
													QuestionDialog={language['generic']['dialog']['question']}
													onSuccess={() => {
														this.Delete();
													}}
													onFail={() => {
														this.Fail();
													}}
													CancelTitle={language['generic']['dialog']['cancel']}
													SuccessTitle={language['generic']['dialog']['confirm']}
												/>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				{
					<AdvancedPagination
						currentPage={this.props.page}
						items={this.props.deedTypeCollection.totalNumberOfRows}
						link={routeUrls.deedTypesPagination}
						updateMaxPageItems={this.handleItemsPerPageChange}
						actualPageItems={this.props.filter.ItemsPage}
						language={this.props.language}
					/>
				}
			</>
		);
	}
	public handleExportClick = () => {
		let filter: DeedTypeFilter = this.props.filter;
		this.props.exportToExcel(filter);
	};

	public readonly handleOrderByDescriptionSpanish = () => {
		let filter: DeedTypeFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedTypeOrderFilter.DescriptionSpanish;
		let orderBy: string = '';

		if (filter.DescriptionSpanishOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DescriptionSpanishOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.DescriptionSpanishOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByDescriptionSpanish: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeedTypeCollection(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByDescriptionEnglish = () => {
		let filter: DeedTypeFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedTypeOrderFilter.DescriptionEnglish;
		let orderBy: string = '';

		if (filter.DescriptionEnglishOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.DescriptionEnglishOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.DescriptionEnglishOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByDescriptionEnglish: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeedTypeCollection(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByParentSpanish = () => {
		let filter: DeedTypeFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedTypeOrderFilter.FatherDescriptionSpanish;
		let orderBy: string = '';

		if (filter.FatherDescriptionSpanishOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.FatherDescriptionSpanishOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.FatherDescriptionSpanishOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByType: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeedTypeCollection(filter, 1, filter.ItemsPage);
	};		

	public readonly handleOrderByParentEnglish = () => {
		let filter: DeedTypeFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedTypeOrderFilter.FatherDescriptionEnglish;
		let orderBy: string = '';

		if (filter.FatherDescriptionEnglishOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.FatherDescriptionEnglishOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.FatherDescriptionEnglishOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByType: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeedTypeCollection(filter, 1, filter.ItemsPage);
	};	

	

	public readonly handleOrderByType = () => {
		let filter: DeedTypeFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedTypeOrderFilter.FatherId;
		let orderBy: string = '';

		if (filter.TypeOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.TypeOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.TypeOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByType: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeedTypeCollection(filter, 1, filter.ItemsPage);
	};

	public readonly handleOrderByAffectsCapital = () => {
		let filter: DeedTypeFilter = this.props.filter;
		filter.CurrentFilter = ActiveDeedTypeOrderFilter.AffectsCapital;
		let orderBy: string = '';

		if (filter.AffectsCapitalOrder === OrderBy.Desc) {
			orderBy = 'ms-Icon--ChevronUpSmall';
			filter.AffectsCapitalOrder = OrderBy.Asc;
		} else {
			orderBy = 'ms-Icon--ChevronDownSmall';
			filter.AffectsCapitalOrder = OrderBy.Desc;
		}

		this.setState({ actualFilter: filter, orderByAffectCapital: orderBy });
		this.props.onchangeFilter(filter);
		this.props.getDeedTypeCollection(filter, 1, filter.ItemsPage);
	};

	public handleItemsPerPageChange = (itemsPage: number) => {
		let filter: DeedTypeFilter = { ...this.state.actualFilter };
		filter.ItemsPage = itemsPage
		this.setState({ actualFilter: filter });
		this.props.getDeedTypeCollection(filter, 1, itemsPage);
	};
	private readonly Delete = () => {
		this.props.deleteDeedType(this.state.actualFilter, this.props.language, this.state.id);
		this.Fail();
	};
	private Fail() {
		this.setState({ showModal: false, id: 0 });
	}
	private ShowModal(id: number) {
		this.setState({ showModal: true, id: id });
	}
}
export const DeedTypeDataGrid = withRouter(DeedTypeDataGridComponent);
