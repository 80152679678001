import { AuthorizationApi } from '../../../api/authorizations';
import { actionTypes } from '../../../common/const/actionTypes';
import { Person } from '../../../model';

export const getAuhorizationUsersAction = (filter: string) => (dispatch) => {
    AuthorizationApi.GetAuthorizedUsersAsync(filter).then((content) => {
        dispatch(getAuhorizationUsersCompleted(content));
    });
};

const getAuhorizationUsersCompleted = (people: Person[]) => ({
    type: actionTypes.GET_PERSONS_AUTHORIZED,
    payload: people,
});