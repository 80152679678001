export const ApiUrls = {
	People: 'person',
	PositionTypes: 'positionType',
	BodyTypes: 'bodyType',
	PowerTypes: 'powerType',
	PowerExcercises: 'PowerExcercises',
	StateRepresentatives: 'stateRepresentatives',
	Powers: 'power',
	Company: 'company',
	LegalForm: 'legalForm',
	Currencies: 'currency',
	Countries: 'country',
	DeedTypes: 'deedType',
	RecordTypes: 'recordType',
	Representatives: 'Representative',
	Record: 'Record',
	Deed: 'Deed',
	NotaryDocuments: 'NotaryDocument',
	Positions: 'Position',
	Me: 'Auth',
	AuthorizationAccount: 'Authorized',
	Trade: 'Trade',
	AuthorizationStatus: 'AuthorizedStatus',
	Department: 'Department',
	PowerForm: 'PowerForm',
	Departments: 'Departments',
	DocumentTypes: 'DocumentType',
	Original: 'OriginalType',
	Documentation: 'Documentation',
	NotaryPeople: 'NotaryPeople'
};
