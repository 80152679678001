import { IComboBoxOption, SelectableOptionMenuItemType, TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { languages } from '../../../common/const/resources';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';
import { BaseType, DepartmentCollection, Language, UserState } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { createEmptyDepartmentFilter, DepartmentFilter } from '../../../model/filters';

export interface IProps {
    getDepartmentCollection: (filter: DepartmentFilter, pageNumbers: number, itemsPage: number) => Promise<DepartmentCollection>;
    onchangeFilter: any;
    departmentCollection: DepartmentCollection;
    business: BaseType[];
    filter: DepartmentFilter;
    language: Language;
    collapsed: boolean;
    user: UserState;
    itemsPage: number;
}

export interface IState {
    actualFilter: DepartmentFilter;
    collapsed: boolean;
    isBusinessDisabled: boolean;
}

export class DepartmentsFilterComponent extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            collapsed: false,
            actualFilter: createEmptyDepartmentFilter(),
            isBusinessDisabled: false
        };
    }

    componentDidMount() {
        this.setState({ actualFilter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
    }
    public render() {
        let language = languages.languageES;
        if (this.props.language.name.includes(ActiveLanguage.English)) {
            language = languages.languageEN;
        }
        let businessCombo: IComboBoxOption[] = [];
        businessCombo.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
        businessCombo.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
        this.props.business.forEach((item) => {
            businessCombo.push({ key: item.id, text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish });
        });
        return (
            <>
                <div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
                    <div
                        className={
                            this.state.collapsed
                                ? 'ms-Grid-row filter_header-collapse'
                                : 'ms-Grid-row filter_header'
                        }
                    >
                        <div className='ms-Grid-col ms-xl6'>
                            {this.state.collapsed
                                ? language['generic']['list']['filter']['collapsedTitle']
                                : language['generic']['list']['filter']['btnTitle']}
                        </div>
                        <div className='ms-Grid-col ms-xl6'>
                            <CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
                        </div>
                    </div>
                    <div style={{ display: this.state.collapsed ? 'none' : '' }}>
                        <div className='ms-Grid-row flex-option'>
                            <div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
                                <p> {language['departments']['filter']['options']['descriptionSpanish']['title']} </p>
                                <TextField
                                    type='text'
                                    value={this.state.actualFilter.DepartmentNameSpanish}
                                    onChange={this.handleDescriptionSpanishChange}
                                    onBlur={this.handleDescriptionSpanishBlur}
                                />
                            </div>
                            <div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
                                <p>{language['departments']['filter']['options']['descriptionEnglish']['title']} </p>
                                <TextField
                                    type='text'
                                    value={this.state.actualFilter.DepartmentNameEnglish}
                                    onChange={this.handleDescriptionEnglishChange}
                                    onBlur={this.handleDescriptionEnglishBlur}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className='ms-Grid-row flex-option'>
                            <div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
                                <button type='button' className='filter_button-clean' onClick={this.cleanFilterDepartments}>
                                    {language['generic']['list']['filter']['btnClean']}
                                </button>
                                <button type='button' className='filter_button-apply' onClick={this.filterDepartments}>
                                    {language['generic']['list']['filter']['btnApply']}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: this.state.collapsed ? '' : 'none' }}>
                        <div className='filter_collapse'>
                            <p style={{ display: this.state.actualFilter.DepartmentNameSpanish !== '' ? '' : 'none' }}>
                                <span className='key'>{language['departments']['filter']['options']['descriptionSpanish']['title']}</span>
                                <span className='value'>{this.state.actualFilter.DepartmentNameSpanish}</span>
                            </p>
                            <p style={{ display: this.state.actualFilter.DepartmentNameEnglish !== '' ? '' : 'none' }}>
                                <span className='key'>{language['departments']['filter']['options']['descriptionEnglish']['title']}</span>
                                <span className='value'>{this.state.actualFilter.DepartmentNameEnglish}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    private readonly handleFiltersCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    private readonly handleDescriptionSpanishChange = (value) => {
        let state = { ...this.state };
        state.actualFilter.DepartmentNameSpanish = value.target.value;
        this.setState(state);
    };
    private readonly handleDescriptionSpanishBlur = (value) => {
        let state = { ...this.state };
        state.actualFilter.DepartmentNameSpanish = value.target.value.toUpperCase();
        this.setState(state);
    };

    private readonly handleDescriptionEnglishChange = (value) => {
        let state = { ...this.state };
        state.actualFilter.DepartmentNameEnglish = value.target.value;
        this.setState(state);
    };
    private readonly handleDescriptionEnglishBlur = (value) => {
        let state = { ...this.state };
        state.actualFilter.DepartmentNameEnglish = value.target.value.toUpperCase();
        this.setState(state);
    };

    private readonly cleanFilterDepartments = () => {
        let resetFilter = createEmptyDepartmentFilter();

        this.setState({ actualFilter: resetFilter });
        this.props.onchangeFilter(resetFilter);
        this.props.getDepartmentCollection(resetFilter, 1, this.props.itemsPage);

    };
    private readonly filterDepartments = () => {
        let filter = { ...this.state.actualFilter };
        this.setState({ collapsed: true });
        this.props.onchangeFilter(filter);
        this.props.getDepartmentCollection(filter, 1, this.props.itemsPage);
    };

}