import { NotaryPeople } from '../../model/NotaryPeople';
import { SiteProps } from '../../model/Config';
import * as utils from '../../utils/extensions';
import * as fetch from 'isomorphic-fetch';
import { ApiUrls } from '../../common/const/api';
import { NotaryPeopleFilter } from '../../model/filters/NotaryPeopleFilter';
import { ActiveNotaryPeopleOrderFilter } from '../../model/filters/ActiveNotaryPeopleOrderFilter';
import { OrderBy } from '../../model/filters/OrderBy';
import * as msal from '../../common/security/msal';

// let pageRender: number = 0;		Clean Warning Code 

const FilterAsync = async (filter: NotaryPeopleFilter, pageNumber: number, itemsPage: number): Promise<NotaryPeople[]> => {
	let baseUrl = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.NotaryPeople}/Get`;

	const token = await msal.authProvider.getIdToken();

	// pageRender = pageNumber;		Clean Warning Code 
	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseUrl}?HasOrder=true&NumRegister=${itemsPage}&PageNumber=${pageNumber}`;
	let queryString = '';

	switch (filter.CurrentFilter) {
		case ActiveNotaryPeopleOrderFilter.Name:
			queryString += '&NameOrder=';
			if (filter.NameOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		case ActiveNotaryPeopleOrderFilter.Email:
			queryString += '&EmailOrder=';
			if (filter.EmailOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;

		default:
			queryString += '&NameOrder=';
			if (filter.NameOrder === OrderBy.Asc) {
				queryString += 'Asc';
			} else {
				queryString += 'Desc';
			}
			break;
	}

	if (filter.NotaryPeopleId != null) {
		queryString += '&NotaryPeopleId=' + filter.NotaryPeopleId;
	}

	if (!utils.isNullOrEmpty(filter.Name)) {
		queryString += '&Name=' + encodeURIComponent(filter.Name) + '';
	}

	if (!utils.isNullOrEmpty(filter.Email)) {
		queryString += '&Email=' + encodeURIComponent(filter.Email) + '';
	}

	let randomGuid: string = utils.generateRandomGuid(false);

	return fetch(url + queryString + randomGuid, obj).then((response) => response.json());
};

async function GetNotaryPeopleNamesFilteredAsync(filter: string): Promise<NotaryPeople[]> {

	let baseURL = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.NotaryPeople}/GetNotaryPeopleFiltered?filter=${filter}`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	return fetch(baseURL, obj).then((response) => response.json());
}


async function ExportToExcel(filter: NotaryPeopleFilter): Promise<any> {

	let baseURL = `${SiteProps.SiteURL}/api/Catalog/${ApiUrls.NotaryPeople}/ExportToExcel`;
	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			Pragma: 'no-cache',
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let url = `${baseURL}?`;
	let queryString = '';

	if (filter.NotaryPeopleId != null) {
		queryString += '&Id=' + filter.NotaryPeopleId;
	}

	if (!utils.isNullOrEmpty(filter.Name)) {
		queryString += '&Name=' + encodeURIComponent(filter.Name) + '';
	}

	if (!utils.isNullOrEmpty(filter.Email)) {
		queryString += '&Email=' + encodeURIComponent(filter.Email) + '';
	}

	let randomGuid: string = utils.generateRandomGuid(false);
	return fetch(url + queryString + randomGuid, obj).then((response: any) => utils.downloadBlob(response, utils.getExcelNames('notaryPeople')));
}


export const NotaryPeopleApi = {
	ExportToExcel,
	FilterAsync,
	GetNotaryPeopleNamesFilteredAsync
};
