import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { BaseType } from '../../../../model/BaseType';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { GenericApi } from '../../../../api/generic';

export const getBodyTypeNamesAction = () => (dispatch) => {
    dispatch(httpCallStartAction());

    GenericApi.GetAsync<BaseType>(ApiUrls.BodyTypes).then((result) => {
        dispatch(getBodyTypeNamesCompleted(result));
    });
};

const getBodyTypeNamesCompleted = (bodyTypeNames
    : BaseType[]) => ({
        type: actionTypes.GET_BODY_TYPE_NAMES,
        payload: bodyTypeNames,
        meta: {
            httpEnd: true,
        }
    });
