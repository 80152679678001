import * as fetch from 'isomorphic-fetch';
import { SiteProps } from '../../model/Config';
import { ApiUrls } from '../../common/const/api';
import * as utils from '../../utils/extensions';
import { UntranslatedBaseType } from '../../model/BaseType';
import * as msal from '../../common/security/msal';

async function GetAsync(): Promise<UntranslatedBaseType[]> {
	let baseURL = `${SiteProps.SiteURL}/api/${ApiUrls.NotaryDocuments}/Get`;

	const token = await msal.authProvider.getIdToken();

	let obj: RequestInit = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token.idToken.rawIdToken}`,
		},
	};

	let randomGuid: string = utils.generateRandomGuid(true);

	return fetch(baseURL + randomGuid, obj).then((response) => response.json());
}

export const NotaryDataApi = {
	GetAsync,
};
