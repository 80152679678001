import * as React from 'react';
import { Link } from 'react-router-dom';
import { languages } from '../../common/const/resources';
import { UserPageContainer } from '../../components/user';
import { Language, User, UserState } from '../../model';
import { ActiveLanguage } from '../../model/enums/activeLanguage';

export interface IProps {
	user: User;
	users: UserState;
	userName: string;
	language: Language;
}
export interface IState {
	search: string;
}

export class HeaderComponent extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			search: '',
		};
	}

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<header className='ms-Grid main-header'>
				<div className='ms-Grid-row'>
					<Link to='/' className='ms-Grid-col ms-sm12 ms-lg3 ms-xl2 ms-hiddenMdDown main-header__logo'>
						<img className='image_logo' src={require('../../assets/images/ferrovial-logo-blanco.png')} alt='image_logo'></img>
						<p className='title_logo'>{language['header']['sub-title']}</p>
					</Link>
					<div className='ms-Grid-col ms-sm12 ms-lg9 ms-xl10 main-header__bar'>
						<div className='ms-Grid-col ms-sm4 ms-lg4 ms-xl8 div_library'>
						</div>
						<div className='ms-Grid-col ms-sm4 ms-lg3 ms-xl4 div_user'>
							<UserPageContainer userName={this.props.userName} users={this.props.users} />
						</div>
					</div>
				</div>
			</header>
		);
	}
}
