import { DropdownMenuItemType, Toggle } from 'office-ui-fabric-react';
import { SelectableOptionMenuItemType, VirtualizedComboBox } from 'office-ui-fabric-react/lib/components/ComboBox';
import { ComboBox } from 'office-ui-fabric-react/lib/components/ComboBox/ComboBox';
import { IComboBoxOption } from 'office-ui-fabric-react/lib/components/ComboBox/ComboBox.types';
import { TextField } from 'office-ui-fabric-react/lib/components/TextField';
import * as React from 'react';
import { languages } from '../../../common/const/resources';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';
import { BaseName, BaseType, Country, Language, UserState } from '../../../model/';
import { CompanyBase, CompanyCollection } from '../../../model/Company';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { CompanyFilter, createEmptyCompanyFilter } from '../../../model/filters/CompanyFilter';

export interface IProps {
	getCompanies: (filter: CompanyFilter, pageNumbers: number, itemsPage: number) => Promise<CompanyCollection>;
	getTradeCompanies: (tradeId?: number) => Promise<BaseName[]>;
	companyNames: CompanyBase[];
	tradeCompanies: CompanyBase[];
	tradeNames: BaseType[];
	countryNames: Country[];
	filter: CompanyFilter;
	onchangeFilter: any;
	language: Language;
	collapsed: boolean;
	itemsPage: number;
	user: UserState;
	page: number;
}

export interface IState {
	filter: CompanyFilter;
	collapsed: boolean;
	isCountryDisabled: boolean;
	showDissolved: boolean;
}

export class CompanySearchComponent extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			collapsed: false,
			filter: createEmptyCompanyFilter(),
			isCountryDisabled: false,
			showDissolved: true,
		};
	}

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let companyCombo: IComboBoxOption[] = [];
		companyCombo.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		companyCombo.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		if(this.state.filter.TradeId <= 0){
			this.props.companyNames.forEach((item) => {
				companyCombo.push({
					key: item.id,
					text: item.name,
					id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
					title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null,
				});
			});
		}else{
			this.props.tradeCompanies.forEach((item) => {
				companyCombo.push({
					key: item.id,
					text: item.name,
					id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
					title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null,
				});
			});
		}
		
		

		let tradeCombo: IComboBoxOption[] = [];
		
		this.props.tradeNames.forEach((item) => {
			tradeCombo.push({ key: item.id, text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish });
		});
		tradeCombo.sort((a, b) => a.text.localeCompare(b.text));
		tradeCombo.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		tradeCombo.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });

		let countryCombo: IComboBoxOption[] = [];
		if (this.props.countryNames) {			
			this.props.countryNames.forEach((item) => {
				countryCombo.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
			countryCombo.sort((a, b) => a.text.localeCompare(b.text));
			countryCombo.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			countryCombo.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		}

		const onRenderOption = (option: any): JSX.Element => {
			if (option.itemType === DropdownMenuItemType.Header) {
				if(option.key === 0){
					return (
						<Toggle 
						inlineLabel 
						label={language['SocietiesCombo']['showDissolved']} 
						checked = {this.state.showDissolved}
						onChange={(event, checked)=>{this.setState({showDissolved: checked})}} />
					);
				}
				else{	
					return (
						<table>
							<tbody>
								<tr>
									<td style={{ width: 15, fontSize: 14, padding: 5 }}></td>
									<td style={{ width: 200, fontSize: 14, padding: 0 }}>{option.key}</td>
									<td style={{ width: 100, fontSize: 14, padding: 0 }}>{option.text}</td>
									<td style={{ width: 100, fontSize: 14, padding: '0px 10px' }}>{option.title}</td>
								</tr>
							</tbody>
						</table>
					);
				}
			} else {
				return (
					<table>
						<tbody>
							<tr>
								<td style={{ width: 200, fontSize: 14, padding: 5 }}>{option.text}</td>
								<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.title}</td>
								<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.id}</td>
							</tr>
						</tbody>
					</table>
				);
			}
		};

		let sortedCompanies = companyCombo.sort((a, b) => a.text.localeCompare(b.text));
		sortedCompanies.unshift({
			key: language['SocietiesCombo']['name'], text: language['SocietiesCombo']['settlementDate'], title:language['SocietiesCombo']['dissolutionDate'],
			itemType: DropdownMenuItemType.Header,
		});
		sortedCompanies.unshift({ key: 0, text: "", itemType: DropdownMenuItemType.Header });		

		return (
			<>
				<div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
					<div className={this.state.collapsed ? 'ms-Grid-row filter_header-collapse' : 'ms-Grid-row filter_header'}>
						<div className='ms-Grid-col ms-xl6'>
							{this.state.collapsed
								? language['generic']['list']['filter']['collapsedTitle']
								: language['generic']['list']['filter']['btnTitle']}
						</div>
						<div className='ms-Grid-col ms-xl6'>
							<CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? 'none' : '' }}>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['societies']['list']['filter']['options']['society']['title']}</p>
								<VirtualizedComboBox
									autoComplete='on'
									allowFreeform={false}
									options={this.state.showDissolved ? sortedCompanies : sortedCompanies.filter(x=> (x.title === null && (x.id === null || x.id === undefined))|| x.title === language['SocietiesCombo']['dissolutionDate'])}
									placeholder={language['societies']['list']['filter']['options']['society']['placeholder']}
									onChange={this.handleSocietyChange}
									selectedKey={this.state.filter.SocietiesId}
									onRenderOption={onRenderOption}
									multiSelect={true}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['societies']['list']['filter']['options']['uniqueCode']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.UniqueCode}
									onChange={this.handleUniqueCodeChange}
									onBlur={this.handleUniqueCodeBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['societies']['list']['filter']['options']['trade']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={tradeCombo}
									placeholder={language['societies']['list']['filter']['options']['trade']['placeholder']}
									onChange={this.handleTradeChange}
									selectedKey={this.state.filter.TradeId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['societies']['list']['filter']['options']['cif']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Cif}
									onChange={this.handleCifChange}
									onBlur={this.handleCifBlur}
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['societies']['list']['filter']['options']['country']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={countryCombo}
									placeholder={language['societies']['list']['filter']['options']['country']['placeholder']}
									onChange={this.handleCountryChange}
									selectedKey={this.state.filter.CountryId}
									useComboBoxAsMenuWidth
									disabled={this.state.isCountryDisabled}
								/>
							</div>
						</div>
						<hr />
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
								<button type='button' className='filter_button-clean' onClick={this.cleanFilterCompany}>
									{language['generic']['list']['filter']['btnClean']}
								</button>
								<button type='button' className='filter_button-apply' onClick={this.filterCompany}>
									{language['generic']['list']['filter']['btnApply']}
								</button>
							</div>
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? '' : 'none' }}>
						<div className='filter_collapse'>
							<p style={{ display: this.state.filter.SocietiesId.length > 0 ? '' : 'none' }}>
								<span className='key'>{language['societies']['list']['filter']['options']['society']['title']}</span>
								<span className='value'>{this.state.filter.SocietiesName?.join(", ")}</span>
							</p>
							<p style={{ display: this.state.filter.UniqueCode !== '' ? '' : 'none' }}>
								<span className='key'>{language['societies']['list']['filter']['options']['uniqueCode']['title']}</span>
								<span className='value'>{this.state.filter.UniqueCode}</span>
							</p>
							<p style={{ display: this.state.filter.TradeId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['societies']['list']['filter']['options']['trade']['title']}</span>
								<span className='value'>{this.state.filter.TradeName}</span>
							</p>
							<p style={{ display: this.state.filter.Cif !== '' ? '' : 'none' }}>
								<span className='key'>{language['societies']['list']['filter']['options']['cif']['title']}</span>
								<span className='value'>{this.state.filter.Cif}</span>
							</p>
							<p style={{ display: this.state.filter.CountryId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['societies']['list']['filter']['options']['country']['title']}</span>
								<span className='value'>{this.state.filter.CountryName}</span>
							</p>
						</div>
					</div>
				</div>
			</>
		);
	}

	componentDidMount() {
		this.setState({ filter: this.props.filter });
	}

	componentDidUpdate(prevProps) {
		if (this.props.tradeNames !== prevProps.tradeNames) {
			let filter = { ...this.state.filter };
			filter.TradeName = this.props.language.name.includes(ActiveLanguage.English)
				? this.props.tradeNames.find((x) => x.id === filter.TradeId)?.descriptionEnglish
				: this.props.tradeNames.find((x) => x.id === filter.TradeId)?.descriptionSpanish;

			this.setState({ filter: filter });
		}

		if (this.props.countryNames !== prevProps.countryNames) {
			let isCountryDisabled = false;
			if (this.props.countryNames.length === 1) {
				let netherlands = this.props.countryNames.find((x) => x.descriptionEnglish === 'Netherlands')?.id;
				if (netherlands !== undefined) {
					let filter = { ...this.state.filter };
					filter.CountryId = netherlands;
					filter.CountryName = this.props.language.name.includes(ActiveLanguage.English)
						? this.props.countryNames.find((x) => x.id === filter.CountryId)?.descriptionEnglish
						: this.props.countryNames.find((x) => x.id === filter.CountryId)?.descriptionSpanish;
					isCountryDisabled = true;
					this.setState({ filter: filter, isCountryDisabled: isCountryDisabled });
				}
			}
		}
	}

	private readonly handleFiltersCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	private readonly handleSocietyChange = (event, option) => {
		let state = { ...this.state };
		if (option.selected === true) {
			state.filter.SocietiesId.push(option.key);
			state.filter.SocietiesName.push(option.text);
		} else {
			state.filter.SocietiesId = state.filter.SocietiesId.filter((e) => e !== option.key);
			state.filter.SocietiesName = state.filter.SocietiesName.filter((e) => e !== option.text);
		}
		this.setState(state);
	};

	private readonly handleTradeChange = async (event, option) => {
		let state = { ...this.state };
		state.filter.TradeId = parseInt(option?.key);
		state.filter.TradeName = option?.text;
		this.setState(state);
		let tradeId = option?.key;
		await this.props.getTradeCompanies(tradeId);
	};

	private readonly handleCifChange = (value) => {
		let state = { ...this.state };
		state.filter.Cif = value.target.value;
		this.setState(state);
	};
	private readonly handleCifBlur = (value) => {
		let state = { ...this.state };
		state.filter.Cif = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly handleUniqueCodeChange = (value) => {
		let state = { ...this.state };
		state.filter.UniqueCode = value.target.value;
		this.setState(state);
	};
	private readonly handleUniqueCodeBlur = (value) => {
		let state = { ...this.state };
		state.filter.UniqueCode = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly handleCountryChange = (event, option) => {
		let state = { ...this.state };
		state.filter.CountryId = parseInt(option?.key);
		state.filter.CountryName = option?.text;
		this.setState(state);
	};

	private readonly filterCompany = () => {
		let filter = { ...this.state.filter };
		this.setState({ collapsed: true });
		this.props.onchangeFilter(filter);
		this.props.getCompanies(filter, 1, this.props.itemsPage);
	};

	private readonly cleanFilterCompany = () => {
		let resetFilter = createEmptyCompanyFilter();
		if (this.props.countryNames.length === 1) {
			resetFilter.CountryId = this.state.filter.CountryId;
			resetFilter.CountryName = this.state.filter.CountryName;
		}
		this.setState({ filter: resetFilter });
		this.props.onchangeFilter(resetFilter);
		this.props.getCompanies(resetFilter, 1, this.props.itemsPage);
	};
}
