import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { languages } from '../../../common/const/resources';
import { onlyPositionsSheetModule } from '../../../common/const/rolesAssignments';
import { routeUrls } from '../../../common/const/routes';
import { NoResultsComponent } from '../../../common/noResults/noResults';
import { SpinnerContainer } from '../../../common/spinner';
import { UnauthorizedView } from '../../../common/unauthorizedView/unauthorizedView';
import { BaseName, BaseType, Language, Person, PositionCollection, User, UserState } from '../../../model/';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { PositionDetailFilter, PositionFilter } from '../../../model/filters/';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';
import { PositionsDataGrid } from './grid';
import { PositionSearchComponent } from './search';

export interface IProps {
	positionCollection: PositionCollection;
	peopleNames: Person[];
	companies: BaseName[];
	positionTypeNames: BaseName[];
	positionTypes: BaseType[];
	user: User;
	currentUser: UserState;
	language: Language;
	filter: PositionFilter;

	getPeopleNames: (filter: string) => Promise<Person[]>;
	getPositionTypeNames: () => Promise<BaseType[]>;
	getCompanyNames: () => Promise<BaseName[]>;
	getPositions: (filter: PositionFilter, pageNumbers: number, itemsPage: number) => Promise<PositionCollection>;
	setPositionFilter: (filter: PositionFilter) => PositionFilter;
	setPositionDetailFilter: (filter: PositionDetailFilter) => PositionDetailFilter;
	exportToExcel: (filter: PositionFilter) => Promise<any>;
}

export interface IState {
	isAuthorized: boolean;
	isLoading: boolean;
	isAdmin: boolean;
	filter: PositionFilter;
}

export class PositionsListPage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			isAuthorized: false,
			isLoading: true,
			isAdmin: false,
			filter: this.props.filter,
		};
	}
	public componentDidMount() {
		window.scrollTo(0, 0);
		let isAuth = utils.isInRoles(onlyPositionsSheetModule);
		this.setState({
			isAuthorized: isAuth,
			isLoading: false,
		});

		let isAdmin = utils.isInRole(userRoles.admin);
		this.setState({ isAdmin: isAdmin });
		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}
		if (this.props.location.state != null) {
			this.props.getPositions(this.props.location.state.positionfilter, page, this.props.filter.ItemsPage);
		} else {
			this.props.getPositions(this.props.filter, page, this.props.filter.ItemsPage);
		}
		this.props.getPositionTypes();
		this.props.getCompanyNames();
		this.props.getPositionTypeNames();
	}

	public componentDidUpdate(prevProps) {
		let currentUserOld = prevProps.currentUser;
		if (currentUserOld.currentUser === null) {
			let isAuth = utils.isInRoles(onlyPositionsSheetModule);

			if (isAuth) {
				this.setState({
					isAuthorized: isAuth,
					isLoading: false,
				});
			}
		}

		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];
		if (newPage !== oldPage) {
			this.props.getPositions(this.props.filter, newPage, this.props.filter.ItemsPage);
		}
	}

	public render() {
		let page: number = Number(this.props.match.params['page']);

		let positions =
			this.props.positionCollection && this.props.positionCollection.list?.length > 0
				? this.props.positionCollection.list.slice()
				: [];

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		return (
			<>
				{!this.state.isLoading && !this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{!this.state.isLoading && this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<div className='ms-Grid-row'>
								<h1>{language['positions']['list']['title']}</h1>
							</div>
							<hr />
						</div>
						<div className='ms-Grid'>
							<PositionSearchComponent
								filter={this.state.filter}
								user={this.props.currentUser}
								getPositions={this.props.getPositions}
								companies={this.props.companies}
								positionTypes={this.props.positionTypes}
								language={this.props.language}
								onChangeFilter={this.handleFilterChange}
								peopleNames={this.props.peopleNames}
								positionTypeNames={this.props.positionTypeNames}
								getPeopleNames={this.props.getPeopleNames}
								itemsPage={this.state.filter.ItemsPage}
							/>
							{this.props.positionCollection.list?.length > 0 ? (
								<PositionsDataGrid
									positions={positions}
									user={this.props.user}
									language={this.props.language}
									setPositionFilter={this.props.setPositionFilter}
									filter={this.state.filter}
									page={page}
									positionCollection={this.props.positionCollection}
									onchangeFilter={this.handleFilterChange}
									exportToExcel={this.props.exportToExcel}
									getPositions={this.props.getPositions}
									setPositionDetailFilter={this.props.setPositionDetailFilter}
								/>
							) : (
								<NoResultsComponent
									language={this.props.language}
									list={language['positions']['list']['no-result-title']}
								/>
							)}
						</div>
					</>
				)}
			</>
		);
	}
	public readonly handleFilterChange = (newfilter: PositionFilter): void => {
		this.setState({ filter: newfilter });
		this.props.history.push(routeUrls.positionsPagination + 1);
	};
}
