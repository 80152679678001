import { actionsDef } from '../actions/actionsDef';

export interface HomeState {
  home: string;
}

const newHome = (): HomeState => ({
  home: ""
});

export const homeReducer = (state = newHome(), action) => {
  switch (action.type) {
      case actionsDef.home.LOAD_HOME:
      return handleHome(state, action.payload);
  }

  return state;
}

const handleHome = (state: HomeState, payload:string): HomeState => {
    return {
		...state,
		home: payload,
	};
};

