import { actionTypes } from '../common/const/actionTypes';
import { AuthorizationAccount, AuthorizationAccountCollection, createEmptyAuthorization, createEmptyAuthorizationList } from '../model';
import { AuthorizationFilter, createEmptyAuthorizationFilter } from '../model/filters/AuthorizationFilter';



export const authorizationListReducer = (state = createEmptyAuthorizationList(), action) => {
	switch (action.type) {
		case actionTypes.GET_AUTHORIZATIONS:
			return handleGetAuthorizationListCompleted(state, action.payload);
	}

	return state;
};

export const authorizationReducer = (state = createEmptyAuthorization(), action) => {
	switch (action.type) {
		case actionTypes.GET_AUTHORIZATIONS:
			return handleGetAuthorizationCompleted(state, action.payload);
	}

	return state;
};
export const authorizationFilterReducer = (state = createEmptyAuthorizationFilter(), action) => {
	switch (action.type) {
		case actionTypes.SET_AUTHORIZATION_FILTER:
			return handleSetAuthorizationFilterCompleted(state, action.payload);
	}

	return state;
};
export const authorizationFormReducer = (state, action) => {
	switch (action.type) {
		case actionTypes.SAVE_AUTHORIZATION_COMPLETED:
			return handleSaveAuthorizationCompleted(state, action.payload);
		case actionTypes.DELETE_AUTHORIZATION:
			return handleDeleteAuthorizationCompleted(state, action.payload);
	}

	return state;
};

const handleSaveAuthorizationCompleted = (state: number, payload: number) => {
	return payload;
};

const handleDeleteAuthorizationCompleted = (state: number, payload: number) => {
	return payload;
};

const handleGetAuthorizationListCompleted = (state: AuthorizationAccountCollection, payload: AuthorizationAccountCollection) => {
	return payload;
};

const handleGetAuthorizationCompleted = (state: AuthorizationAccount, payload: AuthorizationAccount) => {
	return payload;
};
const handleSetAuthorizationFilterCompleted = (state: AuthorizationFilter, payload: AuthorizationFilter) => {
	return payload;
};
