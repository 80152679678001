import { connect } from 'react-redux';
import { DocumentType, Language } from '../../model';
import { DocumentTypeFilter } from '../../model/filters';
import { StateReducer } from '../../reducers';
import {
	deleteDocumentTypeAction,
	exportToExcelAction,
	getDocumentTypeAction,
	getDocumentTypeCollectionAction,
	getDocumentTypePrimariesAction,
	getDocumentTypeSubTypeAction,
	saveDocumentTypeAction,
	setDocumentTypeFilterAction,
} from './actions';
import { DocumentTypesHome } from './documentTypesHome';

const mapStateToProps = (state: StateReducer) => ({
	language: state.language,
	user: state.user,
	documentType: state.documentType,
	documentTypeCollection: state.documentTypeCollection,
	documentTypeFilter: state.documentTypeFilter,
	documentTypeResponse: state.documentTypeResponse,
	currentUser: state.users,
	documentTypeSubTypes: state.documentTypeSubTypes,
	documentTypePrimaries: state.documentTypePrimaries,
	filter: state.documentTypeFilter,
});

const mapDispatchToProps = (dispatch: any) => ({
	deleteDocumentType: (filter: DocumentTypeFilter, language: Language, id: number) =>
		dispatch(deleteDocumentTypeAction(filter, language, id)),
	exportToExcel: (filter: DocumentTypeFilter) => dispatch(exportToExcelAction(filter)),
	getDocumentType: (id: number) => dispatch(getDocumentTypeAction(id)),
	getDocumentTypeCollection: (filter: DocumentTypeFilter, pageNumber: number, itemsPage: number) =>
		dispatch(getDocumentTypeCollectionAction(filter, pageNumber, itemsPage)),
	setDocumentTypeFilter: (filter: DocumentTypeFilter) => dispatch(setDocumentTypeFilterAction(filter)),
	saveDocumentType: (documentType: DocumentType, language: Language) => dispatch(saveDocumentTypeAction(documentType, language)),
	getDocumentTypePrimaries: () => dispatch(getDocumentTypePrimariesAction()),
	getDocumentTypeSubTypes: () => dispatch(getDocumentTypeSubTypeAction()),
});

export const DocumentTypesContainer = connect(mapStateToProps, mapDispatchToProps)(DocumentTypesHome);
