import { IComboBoxOption, TextField } from 'office-ui-fabric-react';
import { MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { languages } from '../../../common/const/resources';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import { BaseType, createEmptyDepartment, Department, Language } from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';

export interface IProps {
    saveDepartment: (department: Department, language: Language) => Promise<number>;
    getDepartment: (id: number) => Department;
    department: Department;
    departmentSave: number | boolean;
    language: Language;
    business: BaseType[];
    getBusiness: () => Promise<BaseType[]>;
    loading: boolean;
}
export interface IState {
    errorMessages: string[];
    newDepartment: Department;
}
export class DepartmentComponent extends React.Component<RouteComponentProps<{}> & IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            errorMessages: [],
            newDepartment: createEmptyDepartment(),
        };
    }
    public componentDidMount() {
        if (this.props.match.params['id'] != null) {
            this.props.getDepartment(this.props.match.params['id']);
        }
    }

    public componentWillReceiveProps(props) {
        if (props.department !== this.props.department) {
            this.setState({ newDepartment: { ...props.department } });
        }
    }

    public render() {
        let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
        let language = languages.languageES;
        if (this.props.language.name.includes(ActiveLanguage.English)) {
            language = languages.languageEN;
        }
        let validationErrors = this.state.errorMessages?.map((item: string) => {
            return (
                <MessageBarComponent
                    Message={item}
                    Type={MessageBarType.error}
                    MessageVisible={true}
                    close={null} />);
        });

        let businessCombo: IComboBoxOption[] = [];
        this.props.business.forEach((item) => {
            businessCombo.push({ key: item.id, text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish });
        });
        return (
            <>
                <div className='ms-Grid-row'>
                    <h1>{this.props.match.params['id'] == null
                        ? language['departments']['new']
                        : language['departments']['edit']}
                    </h1>
                </div>
                <div className='ms-Grid-col ms-sm12 ms-lg9 ms-xl10'>
                    <div className='ms-Grid-row'>
                        <div className='ms-Grid-col ms-sm12 ms-xl12'>
                            <div className='main-container__content_edit ms-Grid-col ms-sm12 ms-xl3'>
                                <p>{language['departments']['fields']['descriptionSpanish']['title']}</p>
                                <TextField
                                    type='text'
                                    value={this.state.newDepartment.descriptionSpanish}
                                    onChange={this.handleDescriptionSpanishChange}
                                    onBlur={this.handleDescriptionSpanishBlur}
                                    maxLength={100}
                                />
                                <p>{language['departments']['fields']['descriptionEnglish']['title']}</p>
                                <TextField
                                    type='text'
                                    value={this.state.newDepartment.descriptionEnglish}
                                    onChange={this.handleDescriptionEnglishChange}
                                    onBlur={this.handleDescriptionEnglishBlur}
                                    maxLength={100}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ms-Grid-row'>
                    <hr />
                    {validationErrors}
                    {isAdmin &&
                        <button type='button' className={`button-primary ${!this.props.loading ? '' : 'isDisabled'} `} name='saveDepartment' onClick={this.saveDepartment}>
                            {language['departments']['save-button']}
                        </button>
                    }
                    <button onClick={utils.navigateBack} type='button' name='back' className='button-cancel'>
                        {language['departments']['cancel-button']}
                    </button>
                </div>
            </>
        );
    }

    private readonly handleDescriptionSpanishChange = (value) => {
        let state = { ...this.state };
        state.newDepartment.descriptionSpanish = value.target.value;
        this.setState(state);
    };
    private readonly handleDescriptionSpanishBlur = (value) => {
        let state = { ...this.state };
        state.newDepartment.descriptionSpanish = value.target.value.toUpperCase();
        this.setState(state);
    };

    private readonly handleDescriptionEnglishChange = (value) => {
        let state = { ...this.state };
        state.newDepartment.descriptionEnglish = value.target.value;
        this.setState(state);
    };
    private readonly handleDescriptionEnglishBlur = (value) => {
        let state = { ...this.state };
        state.newDepartment.descriptionEnglish = value.target.value.toUpperCase();
        this.setState(state);
    };

    private readonly saveDepartment = (): number => {
        let department = { ...this.state.newDepartment };
        if (this.validateDepartment(department)) {
            return this.props.saveDepartment(department, this.props.language);
        }
        return 0;
    };
    private readonly validateDepartment = (department: Department) => {
        let errorMessages: string[] = [];
        let language = languages.languageES;
        if (this.props.language.name.includes(ActiveLanguage.English)) {
            language = languages.languageEN;
        }
        let hasDescriptionSpanish = !utils.isNullOrEmpty(department.descriptionSpanish);
        let hasDescriptionEnglish = !utils.isNullOrEmpty(department.descriptionEnglish);
        let isDepartmentValid = hasDescriptionSpanish && hasDescriptionEnglish;
        if (!hasDescriptionSpanish) {
            errorMessages.push(language['generic']['messages']['required-spanish-description']);
        }
        if (!hasDescriptionEnglish) {
            errorMessages.push(language['generic']['messages']['required-english-description']);
        }

        if (!isDepartmentValid) {
            this.setState({ errorMessages });
        }

        return isDepartmentValid;
    }
}
