import { actionTypes } from '../../../../common/const/actionTypes';
import { PeopleApi } from '../../../../api/people';
import { Person } from '../../../../model/Person';
import { PersonFilter } from '../../../../model/filters/PersonFilter';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';

export const getPeopleAction = (filter: PersonFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
    dispatch(httpCallStartAction());
    dispatch(setPersonFilterCompleted(filter));
    PeopleApi.FilterAsync(filter, pageNumber, itemsPage).then((content) => {
        dispatch(getPeopleCompleted(content));
    });
};

const getPeopleCompleted = (peopleCollection: Person[]) => ({
    type: actionTypes.GET_PEOPLE,
    payload: peopleCollection,
    meta: {
        httpEnd: true,
    }
});

const setPersonFilterCompleted = (filter: PersonFilter) => ({
    type: actionTypes.SET_PERSON_FILTER,
    payload: filter,
    meta: {
        httpEnd: true,
    },
});
    