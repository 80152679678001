import { actionTypes } from '../../../../common/const/actionTypes';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import { DeedApi } from '../../../../api/deed';
import { DeedFilter } from '../../../../model/filters';
import { DeedCollection } from '../../../../model';

export const getDeedsAction = (filter: DeedFilter, pageNumber: number, itemsPage:number) => (dispatch) => {
	dispatch(httpCallStartAction());
	dispatch(setDeedFilterCompleted(filter));
	DeedApi.FilterAsync(filter, pageNumber, itemsPage).then((content) => {
		dispatch(getDeedsCompleted(content));
	});
};
const getDeedsCompleted = (deedCollection: DeedCollection) => ({
	type: actionTypes.GET_DEEDS,
	payload: deedCollection,
	meta: {
		httpEnd: true,
	},
});

const setDeedFilterCompleted = (filter: DeedFilter) => ({
	type: actionTypes.SET_DEED_FILTER,
	payload: filter,
	meta: {
		httpEnd: true,
	},
});
